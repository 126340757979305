import React from 'react';
import cx from 'classnames';
import { I18nextProvider } from 'react-i18next';
import { primaryInput } from 'detect-it';
import { Provider } from 'react-redux';
import i18n from '../shared/i18n/index';
import { ThemeProvider } from 'styled-components';
import store from '../store/reducers/redux';
import { IngTheme } from '../shared/constants/theme';
import Index from '../pages/Index/Index';
import { useIphoneLayoutFix } from '../shared/hooks';
import { GlobalStyles } from './GlobalStyles';
import { AppMode } from '../types/models';

const notouch = primaryInput === 'mouse';
const isWeb = window.atmSearch?.appMode !== AppMode.App;

const App = () => {
    useIphoneLayoutFix(window.atmSearch?.isEmbedded);
    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <ThemeProvider theme={IngTheme}>
                    <GlobalStyles className={cx({ notouch, isWeb })}>
                        <Index />
                    </GlobalStyles>
                </ThemeProvider>
            </Provider>
        </I18nextProvider>
    );
};

export default App;
