import styled from 'styled-components';
import { baseFontColor, ingBlack15 } from '../shared/constants/theme';

export const GlobalStyles = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    color: ${baseFontColor};
    z-index: 1;

    &.isWeb {
        border: 1px solid ${ingBlack15};

        overflow: hidden;
        border-radius: 6px;
    }

    &,
    * {
        text-size-adjust: 100%;
        font-style: normal;
        font-variant-caps: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-family: INGMe, INGMeWebRegular, Helvetica, 'sans-serif';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: none;
    }

    .popup-overlay {
        position: absolute !important;
    }

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    .ym-map-container .leaflet-marker-icon.ing-cluster {
        color: white;
        background: ${(props) => props.theme.ingOrange};
        border-radius: 50%;

        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: x-large;
        font-weight: bold;
        font-family: INGMe, INGMeWebRegular, Helvetica, 'sans-serif';

        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    }
    .leaflet-control-container {
        .leaflet-control-zoom-in,
        .leaflet-control-zoom-out {
            text-decoration: none;
        }
    }
`;
