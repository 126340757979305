import React, { useEffect, useState } from 'react';
import { AtmElement, BranchElement } from '../../types/models';
import { connect } from 'react-redux';
import {
    formatAddress,
    getAtmType,
    normalizeDistance,
    normalizeText,
} from '../../helpers/utilities';
import { Dispatch } from 'redux';
import { setMobileView, setPanelContent } from '../../store/actions/common';
import { IStore } from '../../store/reducers/store';
import { useTranslation } from 'react-i18next';
import {
    DistanceIcon,
    ResultEntryName,
} from '../SearchResult/SearchResultElement';
import * as SCF from '../FeedbackForm/Styles';
import * as SC from './Styles';
import {
    AtmType,
    FILTER,
    MobileView,
    PanelContent,
} from '../../shared/constants/enum';
import { BaseBox, BaseFont } from '../../shared/constants/theme';
import { ROUTE_TO_YELLOW_MAP } from '../../shared/constants/default';
import FeedbackForm from '../FeedbackForm/FeedbackForm';

import AtmCard from '../../shared/img/atmCard.svg';
import { DEPOSIT_ATM_WORKING_CONDITION } from '../../shared/constants/enum';
import { atmUtils } from '../../helpers/atm-utils';
import { PanelHeader } from '../PanelHeader';
import Button from '../Button';
import { useMobileDetection } from '../../shared/hooks';
import { PreviewMap } from '../PreviewMap';
import { DepositErrorMessage } from '../DepositErrorMessage/DepositErrorMessage';
import { useIsDefectiveAtm } from '../../shared/hooks/useIsDefectiveAtm';
import { reverseGeocode } from '../../api/ym_listing';

type AtmProps = {
    atm?: AtmElement;
    showPreviewMap?: boolean;
    userLocation?: any;
    userPermissionError?: any;
    mobileReturnType?: MobileView;
    showSearch: () => void;
    showMap: () => void;
    showFeedbackForm: () => void;
    showAtmDetails: () => void;
};

const AtmComplaintLink = ({
    onFeedbackClick,
    branchCodes,
}: {
    onFeedbackClick: () => void;
    branchCodes: string[];
}) => {
    const { t } = useTranslation();
    return branchCodes.find(
        (code: string) =>
            !FILTER.CASHBACK_ATMS.includes(code) &&
            !FILTER.VISA_CASHBACK.includes(code)
    ) ? (
        <>
            <SC.FeedbackFormForMobile>
                <FeedbackForm data-testid="atm-complaint-link" />
            </SC.FeedbackFormForMobile>
            <SCF.AskFeedbackContainer data-testid="ask-feedback-form">
                <BaseFont as="p" size={300}>
                    {t('atmIssueQuestion')}
                    <br />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                        onClick={onFeedbackClick}
                        data-testid="ask-feedback-ques"
                    >
                        {t('atmIssueSendComplaint')}
                    </a>
                </BaseFont>
            </SCF.AskFeedbackContainer>
        </>
    ) : null;
};

const getNameAddon = (atm: AtmElement) => {
    const addressData = atm.BasicData.Address;
    const name = addressData.CompanyName || '';

    if (
        addressData.CompanyNameAddon1 &&
        !name.includes(addressData.CompanyNameAddon1)
    ) {
        return addressData.CompanyNameAddon1;
    }
    return '';
};

const BankInfo = ({ atm }: { atm: AtmElement }) => {
    const { t } = useTranslation();
    const addressData = atm.BasicData.Address;
    const geoData = atm.BasicData.Geo;

    let nameAddon = getNameAddon(atm);
    return (
        <BaseFont as="p" size={300} data-testid="bank-info-block">
            <ResultEntryName>
                {normalizeText(addressData.CompanyName)}
                <BaseFont as="span" size={100}>
                    <DistanceIcon data-testid="result-element-icon">
                        {`${t('distance')}: ${normalizeDistance(
                            geoData.Distance
                        )}`}
                    </DistanceIcon>
                </BaseFont>
            </ResultEntryName>
            {nameAddon && (
                <>
                    <br />
                    {nameAddon}
                </>
            )}
            <br />
            {addressData.Street} {addressData.HouseNo}
            <br />
            {addressData.Zip} {addressData.City}
        </BaseFont>
    );
};

const AtmInfoProps = ({ atm }: { atm: AtmElement }) => {
    const { t } = useTranslation();
    return (
        <>
            {atm.BasicData.BranchListElements.map(
                (key: BranchElement, index) =>
                    (key.BranchCode ===
                        DEPOSIT_ATM_WORKING_CONDITION.AVAILABLE ||
                        key.BranchCode ===
                            DEPOSIT_ATM_WORKING_CONDITION.UNKNOWN) && (
                        <BaseFont
                            as="div"
                            size={300}
                            data-testid="atm-props-block"
                            key={index}
                        >
                            <SC.PropContainer>
                                <img src={AtmCard} alt="deposit Option" />
                                <BaseBox as="span" left={3}>
                                    {t('depositOption')}
                                </BaseBox>
                            </SC.PropContainer>
                        </BaseFont>
                    )
            )}
        </>
    );
};

const WithdrawalInfo = ({ atm }: { atm: AtmElement }) => {
    const { t } = useTranslation();
    const getInfoText = () => {
        switch (getAtmType(atm)) {
            case AtmType.PARTNER:
                return t('withdrawalMoneyInfoPartners');

            case AtmType.VISA:
                return t('withdrawalMoneyInfoVisa');

            case AtmType.CASHBACK:
                return t('withdrawalMoneyInfoCashbackPartner');
            case AtmType.ING:
            case AtmType.DEPOSIT:
            case AtmType.VISA_CASHBACK:
            default:
                return t('withdrawalMoneyInfo');
        }
    };
    const getInfoTitle = () => {
        switch (getAtmType(atm)) {
            case AtmType.CASHBACK:
                return t('withdrawalMoneyCashbackPartner');
            default:
                return t('withdrawalMoney');
        }
    };
    return (
        <BaseFont as="p" size={300} data-testid="money-withdrawal-block">
            <b>{getInfoTitle()}</b>
            <br />
            {getInfoText()}
        </BaseFont>
    );
};

const DepositInfo = ({ atm }: { atm: AtmElement }) => {
    const { t } = useTranslation();
    if (!atmUtils.isDeposit(atm)) {
        return null;
    }
    return (
        <BaseFont as="p" size={300} data-testid="money-deposit-block">
            <b>{t('depositMoney')}</b>
            <br />
            {t('depositMoneyInfo')}
        </BaseFont>
    );
};

const Atm = ({
    atm,
    showFeedbackForm,
    showMap,
    showSearch,
    userLocation,
    userPermissionError,
    mobileReturnType,
    showPreviewMap,
}: AtmProps) => {
    const { XCoord, YCoord } = atm?.BasicData?.Geo || {};
    const { latitude, longitude } = userLocation || {};
    const [endAddressRouteToMap, setEndAddressRouteToMap] = useState('');
    const [startAddressRouteToMap, setStartAddressRouteToMap] = useState('');

    const { t } = useTranslation();
    const { isMobile } = useMobileDetection();
    const isDefective = useIsDefectiveAtm(atm);

    useEffect(() => {
        (async function () {
            setStartAddressRouteToMap(
                formatAddress(await reverseGeocode(latitude, longitude))
            );
            if (XCoord && YCoord) {
                setEndAddressRouteToMap(
                    formatAddress(
                        await reverseGeocode(
                            parseFloat(YCoord),
                            parseFloat(XCoord)
                        )
                    )
                );
            }
        })();
    }, [XCoord, YCoord, latitude, longitude]);

    const calculateRouteUrl = React.useMemo(() => {
        if (XCoord && YCoord) {
            if (latitude && longitude && userPermissionError === 0) {
                return `${ROUTE_TO_YELLOW_MAP}start=${startAddressRouteToMap}&end=${endAddressRouteToMap}`;
            } else {
                return `${ROUTE_TO_YELLOW_MAP}end=${endAddressRouteToMap}`;
            }
        }
    }, [
        XCoord,
        YCoord,
        endAddressRouteToMap,
        latitude,
        longitude,
        startAddressRouteToMap,
        userPermissionError,
    ]);

    const onFeedbackClick = React.useCallback(() => {
        showFeedbackForm();
    }, [showFeedbackForm]);

    if (!atm?.BasicData?.Address?.CompanyName) {
        return null;
    }

    const isBackButtonVisible = // on tablet and desktop, always show the back button
        isMobile === false ||
        // on mobile, only show it when coming from the list view
        mobileReturnType === MobileView.CONTENT;

    // only show the close button on mobile - tablet and desktop have the "back" button
    const isCloseButtonVisible = isMobile === true;

    return (
        <SC.DetailViewWrapper data-testid="detail-atm-view-wrapper">
            <PanelHeader
                backButtonVisible={isBackButtonVisible}
                onBackClick={showSearch}
                closeButtonVisible={isCloseButtonVisible}
                onCloseClick={showMap}
            />

            <div className="detailedElementContainer">
                {showPreviewMap && (
                    <SC.InfoBlock>
                        <PreviewMap atm={atm} />
                    </SC.InfoBlock>
                )}

                <BankInfo atm={atm} />

                <AtmInfoProps atm={atm} />

                {!isDefective.withdrawal && <WithdrawalInfo atm={atm} />}

                {!isDefective.deposit && <DepositInfo atm={atm} />}

                <DepositErrorMessage atm={atm} size={300} />

                <BaseBox>
                    <Button
                        as="a"
                        href={calculateRouteUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('calculateRouteToAtm')}
                    </Button>
                </BaseBox>

                {getAtmType(atm) !== AtmType.CASHBACK && (
                    <AtmComplaintLink
                        branchCodes={atm.BasicData.BranchListElements.map(
                            (elem: BranchElement) => elem.BranchCode
                        )}
                        onFeedbackClick={onFeedbackClick}
                    />
                )}
            </div>
        </SC.DetailViewWrapper>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showFeedbackForm: () => {
        dispatch(setPanelContent(PanelContent.FEEDBACK));
    },
    showAtmDetails: () => {
        dispatch(setPanelContent(PanelContent.DETAILS));
    },
    showMap: () => {
        dispatch(setMobileView(MobileView.MAP));
    },
    showSearch: () => {
        dispatch(setPanelContent(PanelContent.SEARCH));
        dispatch(setMobileView(MobileView.CONTENT));
    },
});

const mapStoreToProps = (store: IStore) => {
    return {
        atm: store.detailedAtm,
        userLocation: store.location.userLocation?.coords,
        userPermissionError: store.location.userPermissionError?.code,
        mobileReturnType: store.common.mobileReturnType,
    };
};

export default connect(mapStoreToProps, mapDispatchToProps)(Atm);
