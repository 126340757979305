import React from 'react';
import * as SC from './Styles';
import { BaseBox, BaseFont } from '../../shared/constants/theme';
import { useTranslation } from 'react-i18next';

const ErrorScreen = (props: any) => {
    const { t } = useTranslation();

    return (
        <BaseBox as="span" top={16}>
            <SC.MainContainer data-testid="error-msg-container">
                <img
                    src={`${process.env.PUBLIC_URL}/img/sadSmiley.svg`}
                    alt="no Atm List Error Icon"
                />
                <SC.MsgContainer>
                    <BaseBox as="div" top={6}>
                        <SC.Msg>
                            {props.currentAddress ? (
                                <BaseFont size={300}>
                                    {t('noAtmListErrorMsgFor')}
                                    <b>
                                        {' '}
                                        {props.currentAddress ||
                                            'Frankfurt'}{' '}
                                    </b>
                                </BaseFont>
                            ) : (
                                <BaseFont size={300}>
                                    {t('noAtmListErrorMsg')}
                                </BaseFont>
                            )}
                        </SC.Msg>
                    </BaseBox>
                </SC.MsgContainer>
            </SC.MainContainer>
        </BaseBox>
    );
};

export default ErrorScreen;
