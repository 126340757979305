import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store';
import { Dispatch } from 'redux';
import { setMobileView } from '../../store/actions/common';
import { MobileView } from '../../shared/constants/enum';
import { PanelHeader } from '../PanelHeader';
import {
    BaseFont,
    BaseTitle,
    BaseBox,
    smartphoneUpperBound,
} from '../../shared/constants/theme';

const PopupContentWrapper = styled.div`
    padding: 18px;
`;

interface IMobileSearchResultPopup {
    children: any;
    initiallyOpen: boolean;
    mobileViewType?: MobileView;
    setMobileView: (value: MobileView) => void;
}

const ResultListWrapper = styled.div<{ searchBoxVisibility: boolean }>`
    display: ${(props) => (props.searchBoxVisibility ? 'block' : 'none')};
    background: white;
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
    z-index: ${1 << 22};
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        width: 100%;
        overflow: auto;
        position: fixed;
    }
`;

const MobileSearchResultPopup = ({
    children,
    mobileViewType,
    setMobileView,
}: IMobileSearchResultPopup) => {
    const { t } = useTranslation();

    const onCloseButtonClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setMobileView(MobileView.MAP);
    };
    return (
        <ResultListWrapper
            data-testid="search-bar-popup"
            searchBoxVisibility={mobileViewType === MobileView.CONTENT}
        >
            <PanelHeader onCloseClick={onCloseButtonClick} />

            <PopupContentWrapper>
                {/* TODO deduplicate headlines */}
                <BaseBox as="div" bottom={3} data-testid="search-bar-headline">
                    <BaseFont as="span" size={400}>
                        <BaseTitle>
                            {t('searchBarSearchResultTextMobile')}
                        </BaseTitle>
                    </BaseFont>
                </BaseBox>
                {children}
            </PopupContentWrapper>
        </ResultListWrapper>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        mobileViewType: state.common.mobileViewType,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setMobileView: (value: MobileView) => dispatch(setMobileView(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileSearchResultPopup);
