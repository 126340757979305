import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.ingBlack80};

    img {
        height: 64px;
        width: 64px;
    }
`;

export const MsgContainer = styled.div`
    width: 100%;
`;

export const Msg = styled.div`
    background: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    border-radius: 6px;
    @media screen and (min-width: ${(props) => props.theme.tabletSLowerBound}) {
        min-height: 54px;
    }
    @media screen and (min-width: ${(props) => props.theme.desktopLowerBound}) {
        min-height: 66px;
    }
    p {
        margin: 0;
    }
`;
