import React from 'react';
import { AtmElement, AtmHashMap, MarkerRefs } from '../../../types/models';

import { createMarker } from '../MapFunctions';

import { getAtmIdentifier } from '../../../helpers/utilities';

import { atmUtils } from '../../../helpers/atm-utils';
import { getGlobalMarkers } from '../mapInstance';

/**
 * Displays map markers with proper icons for all given search result ATMs
 *
 * @param options
 */
export function useResultMarkers(options: {
    ymReady: boolean;
    onMarkerClick: (addressItemId: string) => void;
    atms?: AtmHashMap;
    markersById: MarkerRefs;
}) {
    const { ymReady, atms, onMarkerClick, markersById } = options;
    React.useEffect(
        function drawResultMarkers() {
            if (!ymReady) {
                return;
            }

            window.ym.ready(function () {
                if (atms) {
                    const atmsArray = Object.values(atms);
                    atmsArray.forEach((elem: AtmElement) => {
                        if (atmUtils.isDefective(elem)) {
                            return;
                        }
                        const id = getAtmIdentifier(elem);
                        const currentMarker = markersById.current![id];
                        if (!currentMarker) {
                            const marker = createMarker({
                                atm: elem,
                                onClick: onMarkerClick,
                                target: getGlobalMarkers(),
                            });
                            if (marker) {
                                markersById.current[id] = marker;
                            }
                        }
                    });

                    const nextKeys = Object.keys(atms);
                    const prevKeys = Object.keys(markersById.current);
                    const obsoleteKeys = prevKeys.filter(
                        (key) => !nextKeys.includes(key)
                    );
                    obsoleteKeys.forEach((id) => {
                        const marker = markersById.current[id];
                        const layer = getGlobalMarkers();
                        if (marker && layer) {
                            marker.removeFrom(layer);
                        }
                        delete markersById.current[id];
                    });
                }
            });
        },
        [atms, onMarkerClick, ymReady, markersById]
    );
}
