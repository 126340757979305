import {
    SPLASH_SCREEN_MESSAGE,
    TOGGLE_SHOW_SPLASH_SCREEN,
} from './actionTypes';

export const toggleSplashScreenVisibility = (showSplashScreen: boolean) => ({
    type: TOGGLE_SHOW_SPLASH_SCREEN,
    payload: showSplashScreen,
});

export const setSplashScreenMessage = (message: string) => ({
    type: SPLASH_SCREEN_MESSAGE,
    payload: message,
});
