import React from 'react';
import { MapMarker } from '../../../types/models';

import { renderMarker } from '../MapFunctions';

import { getUserLocationIcon } from '../../../helpers/icon-utils';

import { getGlobalMap } from '../mapInstance';
import { MARKER_ZINDEX_USER } from '../MapVariables';

/**
 * Displays a custom map marker and icon at the user's current location.
 *
 * @param options
 */
export function useHomeMarker(options: {
    ymReady: boolean;
    userLat?: number;
    userLng?: number;
}) {
    const { ymReady, userLat, userLng } = options;
    React.useEffect(() => {
        if (!ymReady) return;
        //
        let userLocationMarker: MapMarker | null = null;
        window.ym.ready(function (modules: any) {
            if (userLat && userLng) {
                userLocationMarker = renderMarker(
                    userLat,
                    userLng,
                    getGlobalMap(),
                    modules,
                    getUserLocationIcon()
                );
                userLocationMarker?.setZIndexOffset(MARKER_ZINDEX_USER);
            }
        });
        return () => {
            const map = getGlobalMap();
            if (map) {
                userLocationMarker?.removeFrom(map);
            }
        };
    }, [ymReady, userLat, userLng]);
}
