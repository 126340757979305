import styled from 'styled-components';
import {
    desktopLowerBound,
    ingBlack15,
    ingOrange,
    smartphoneUpperBound,
    tabletLUpperBound,
    tabletSLowerBound,
} from '../../shared/constants/theme';

export const StyledButton = styled.button`
    cursor: pointer;

    // .ingde-button
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    white-space: nowrap;

    /* .ingde-button-filled   */
    background: ${ingOrange};
    color: #fff;
    border: 1px solid ${ingOrange};
    transition: box-shadow 0.15s cubic-bezier(0.4, 0, 1, 1);
    box-shadow: 0 6px 10px 0 transparent, 0 1px 18px 0 transparent,
        0 3px 5px -1px transparent;

    @media screen and (max-width: ${smartphoneUpperBound}) {
        font-size: 1.1875rem;
        line-height: 1.75rem;
        padding: 0 1rem;
        height: 2.5rem;
        line-height: 2.5rem;
        border-radius: 0.5rem;
    }
    @media screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletLUpperBound}) {
        font-size: 1.33594rem;
        line-height: 1.96875rem;
        padding: 0 1.125rem;
        height: 2.8125rem;
        line-height: 2.8125rem;
        border-radius: 0.5625rem;
    }
    @media screen and (min-width: ${desktopLowerBound}) {
        /* @media (min-width: 75.0625rem) .ingde-button { */
        font-size: 1.63281rem;
        line-height: 2.40625rem;
        padding: 0 1.375rem;
        height: 3.4375rem;
        line-height: 3.4375rem;
        border-radius: 0.6875rem;
    }

    &:active {
        opacity: 0.8;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    }

    .notouch & {
        &:focus {
            outline: none;
            border: 1px solid #559bd1;
            box-shadow: 0 0 8px 0 #559bd1;
        }
        &:hover {
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                0 1px 18px 0 rgba(0, 0, 0, 0.12),
                0 3px 5px -1px rgba(0, 0, 0, 0.2);
        }
    }

    &[disabled] {
        color: ${ingBlack15};
        border: 1px solid #a8a8a8;
        background-color: transparent;

        box-shadow: none;
        pointer-events: none;
        cursor: default;
    }
`;
