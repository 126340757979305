import React from 'react';
import escapeRegExp from 'lodash.escaperegexp';

// based on https://stackoverflow.com/a/47803998/368254

export function Highlighted({ children: text = '', highlight = '' }) {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }
    const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
    const parts = text.split(regex);
    return (
        <span>
            {parts
                .filter((part) => part)
                .map((part, i) =>
                    regex.test(part) ? (
                        <b key={i}>{part}</b>
                    ) : (
                        <span key={i}>{part}</span>
                    )
                )}
        </span>
    );
}
