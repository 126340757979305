import styled from 'styled-components';

import { CurrentLocationContainer } from './UserLocation';
import Button from '../Button';

import {
    baseFont,
    desktopLowerBound,
    ingBlack15,
    ingBlack34,
    smartphoneUpperBound,
    tabletLUpperBound,
    tabletSLowerBound,
} from '../../shared/constants/theme';
import { SquareIconButton } from '../Button/SquareIconButton.styled';

export const SearchElementsWrapper = styled.form`
    display: flex;
    min-height: 40px;
    position: relative;
    @media only screen and (min-width: ${tabletSLowerBound}) {
        width: 100%;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 10px 18px;
        border: 1px solid ${ingBlack34};
        border-right: none;

        font: ${baseFont};
        @media screen and (max-width: ${smartphoneUpperBound}) {
            height: 40px;
            padding: calc(0.5rem - 1px) 1.75rem calc(0.5rem - 1px) 0.5rem;

            font-size: 1rem;
            line-height: 1.5rem;
        }
        @media screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletLUpperBound}) {
            height: 47px;
            padding: 0.5625rem;
            padding-right: 0;
            font-size: 1.125rem;
            line-height: 1.6875rem;
        }
        @media screen and (min-width: ${desktopLowerBound}) {
            height: 56px;
            padding: 0.6875rem;
            padding-right: 0;
            font-size: 1.375rem;
            line-height: 2.0625rem;
        }

        ::placeholder {
            color: #ccc;
            font: ${baseFont};
            @media screen and (max-width: ${smartphoneUpperBound}) {
                font-size: 1rem !important;
                line-height: 1.5rem !important;
            }
            @media screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletLUpperBound}) {
                font-size: 1.125rem !important;
                line-height: 1.6875rem !important;
            }
            @media screen and (min-width: ${desktopLowerBound}) {
                font-size: 1.375rem !important;
                line-height: 2.0625rem !important;
            }
        }

        border-radius: 6px 0 0 6px;
        box-shadow: inset 0 1px 2px ${ingBlack15};
        box-sizing: border-box;
        outline-offset: -2px;
    }

    &:focus-within input,
    input:focus,
    input:focus:hover {
        border-color: #559bd1;
        box-shadow: 0 0 8px #9fcaea;
        outline: none;
    }
    // user location icon
    ${CurrentLocationContainer} {
        position: absolute;
        top: 0;
        z-index: 10;

        @media screen and (max-width: ${smartphoneUpperBound}) {
            right: 40px;
            height: 40px;
        }

        @media screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletLUpperBound}) {
            right: 47px;
            height: 47px;
        }
        @media screen and (min-width: ${desktopLowerBound}) {
            right: 56px;
            height: 56px;
        }
    }

    &.hasExtraButton input {
        @media screen and (max-width: ${smartphoneUpperBound}) {
            padding-right: 2.1rem;
        }
        @media screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletLUpperBound}) {
            padding-right: 2.2rem;
        }
        @media screen and (min-width: ${desktopLowerBound}) {
            padding-right: 2.5rem;
        }
    }
`;

export const SearchBoxButton = styled(SquareIconButton)`
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
`;

export const SearchClearButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    appearance: none;
    background: white;
    border: 0;
    outline: 0;
    padding: 0;

    &:active {
        background: white !important;
        border: 0 !important;
        outline: 0 !important;
        padding: 0 !important;
    }
    img {
        opacity: 0.6;
        transition: all 0.3s;
    }
    &:hover {
        img {
            opacity: 1;
        }
        box-shadow: none !important;
    }

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    img {
        max-height: none;
        max-width: none;
    }
    @media screen and (max-width: ${smartphoneUpperBound}) {
        right: 42px;
        width: 2rem;
        height: 2rem;
        img {
            height: 14px;
            width: 14px;
        }
    }
    @media screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletLUpperBound}) {
        right: 51px;
        width: 2rem;
        height: 2rem;
        img {
            height: 16px;
            width: 16px;
        }
    }
    @media screen and (min-width: ${desktopLowerBound}) {
        right: 60px;
        width: 2.5rem;
        height: 2.5rem;
        img {
            height: 20px;
            width: 20px;
        }
    }
`;
