import {
    ATM_SEARCH_STRING,
    DEPOSIT_ATM_WORKING_CONDITION,
    WITHDRAWAL_ATM_WORKING_CONDITION,
} from '../shared/constants/enum';
import { AtmElement, BranchElement } from '../types/models';

/**
 * Checks whether an ATM should be reported to the user as defective.
 *
 * This is the case when
 * - a regular withdrawal ATM has withdrawal status UNKNOWN or NOT_WORKING
 * - a deposit ATM has withdrawal AND deposit status UNKNOWN or NOT_WORKING
 *
 * This is NOT the case when
 * - only one functionality (withdrawal or deposit) is defective while the other is still working
 *
 * Note that this function will never return `true` for non-ING ATMs - it cannot detect whether there are defects or not.
 *
 * See https://jira.etecture.de/browse/INGGA-228
 *
 * @param atmOrBranches either an `AtmElement` object or a `BranchElement[]` array
 * @returns Boolean, whether the given ATM should be reported as "defective"
 */
function isDefective(atmOrBranches: AtmElement | BranchElement[]) {
    const atmBranches = Array.isArray(atmOrBranches)
        ? (atmOrBranches as BranchElement[])
        : atmOrBranches.BasicData.BranchListElements;

    const Withdrawal = WITHDRAWAL_ATM_WORKING_CONDITION;
    const Deposit = DEPOSIT_ATM_WORKING_CONDITION;

    const defectiveCombinations = [
        [Withdrawal.NOT_WORKING, Deposit.UNAVAILABLE],
        [Withdrawal.UNKNOWN, Deposit.UNAVAILABLE],
        [Withdrawal.NOT_WORKING, Deposit.NOT_WORKING],
        [Withdrawal.UNKNOWN, Deposit.UNKNOWN],
    ];

    return defectiveCombinations.some(
        ([withdrawal, deposit]) =>
            atmBranches.find((branch) => branch.BranchCode === withdrawal) &&
            atmBranches.find((branch) => branch.BranchCode === deposit)
    );
}

/**
 * Returns an object that describes what kind of defects a given atm has.
 *
 * - `everything`: No functionality at all is available
 * - `deposit`: Withdrawal is available, but deposit is broken
 * - `withdrawal`: Deposit is available, but withdrawal is broken
 * - `anything`: At least one functionality is defective
 *
 * @param atm
 * @returns An object with boolean flags
 */
export function getDepositDefects(
    atm: AtmElement | BranchElement[] | undefined
) {
    return {
        everything: atm ? atmUtils.isDefective(atm) : false,
        deposit: atm ? depositAtmUtils.isDefectiveDeposit(atm) : false,
        withdrawal: atm ? depositAtmUtils.isDefectiveWithdrawal(atm) : false,
        anything: atm
            ? depositAtmUtils.isDefectiveDeposit(atm) ||
              depositAtmUtils.isDefectiveWithdrawal(atm)
            : false,
    };
}

export const atmUtils = {
    isDefective,

    isFullyOperational(atm: AtmElement | BranchElement[]) {
        return !isDefective(atm) && !getDepositDefects(atm).anything;
    },

    isIng(atm: AtmElement | BranchElement[]) {
        return hasBranchCode(atm, ATM_SEARCH_STRING.ING);
    },
    isVisa(atm: AtmElement | BranchElement[]) {
        return hasBranchCode(atm, ATM_SEARCH_STRING.VISA);
    },
    isPartner(atm: AtmElement | BranchElement[]) {
        return hasBranchCode(atm, ATM_SEARCH_STRING.CASHBACK_ATMS);
    },
    isDeposit(atm: AtmElement | BranchElement[]) {
        return hasBranchCode(atm, DEPOSIT_ATM_WORKING_CONDITION.AVAILABLE);
    },
    isCashbackPartner(atm: AtmElement | BranchElement[]) {
        return hasBranchCode(atm, ATM_SEARCH_STRING.CASH_PARTNERS);
    },
};

export const depositAtmUtils = {
    /** Returns true for ATMs where both withdrawal and deposit is basically supported,
     * but where deposit is currently defective
     */
    isDefectiveDeposit(atm: AtmElement | BranchElement[]) {
        return (
            hasBranchCode(atm, DEPOSIT_ATM_WORKING_CONDITION.NOT_WORKING) &&
            hasBranchCode(atm, WITHDRAWAL_ATM_WORKING_CONDITION.AVAILABLE)
        );
    },
    /** Returns true for ATMs where both withdrawal and deposit is basically supported,
     * but where withdrawal is currently defective
     */
    isDefectiveWithdrawal(atm: AtmElement | BranchElement[]) {
        return (
            hasBranchCode(atm, DEPOSIT_ATM_WORKING_CONDITION.AVAILABLE) &&
            hasBranchCode(atm, WITHDRAWAL_ATM_WORKING_CONDITION.NOT_WORKING)
        );
    },
};

export function hasBranchCode(
    atm: AtmElement | BranchElement[],
    branchCode: string
) {
    const elements = getBranchListElements(atm);
    return elements.some((el) => el.BranchCode === branchCode);
}

export function getBranchListElements(atm: AtmElement | BranchElement[]) {
    return Array.isArray(atm)
        ? (atm as BranchElement[])
        : atm.BasicData.BranchListElements;
}
