/* Old Api Layout */

export type AppSettings = {
    /**
     * Whether the app is being embedded inside another website or application. Default `false` (running standalone).
     * This is automatically set to `true` by the embed script, see `embed/embed.template.js`
     */
    isEmbedded: boolean;

    /**
     * The mode of operation for our app.
     * Either "web" for usage in web browser or "app" for usage within the mobile app.
     * The main difference is that in "web", the user may choose to not use google maps.
     * In the "app" mode, the user is not asked and google maps are used automatically.
     */
    appMode?: AppMode;

    /**
     * The ID of the element into which to mount the app. May be adjusted before embedding.
     */
    elementId: string;
};

export enum AppMode {
    Web = 'web',
    App = 'app',
}

export enum MapMode {
    NoMaps,
    Maps,
    Disclaimer,
}

export interface LatLng {
    lat: number;
    lng: number;
}
export interface LatLngRect {
    northWest: LatLng;
    southEast: LatLng;
    getNorthWest(): LatLng;
    getSouthEast(): LatLng;
}

// not that Point uses an o in long
// TODO: Refactor away Point, replace by LatLng, but check data

export interface Point {
    lat: number;
    long: number;
}

export interface GeoPosition {
    point: Point;
}

export interface Address {
    isoCountryCode: string;
    zip: string;
    city: string;
    street: string;
    geoPosition: GeoPosition;
}

export interface Atm {
    partnerId: string;
    name: string;
    companyName: string;
    companyAddon1: string;
    companyAddon2: string;
    openingHoursText: string;
    address: Address;
}

/* New Api Layout */

/**
 * Definition on a null object regarding an atm element.
 */
export const atmElementNullObj = () => {
    return {
        BasicData: {
            BranchListElements: [],
            Address: {
                CompanyName: '',
                CompanyNameAddon1: '',
                CompanyNameAddon2: '',
                CompanyNameAddon3: '',
                Country: '',
                Zip: '',
                City: '',
                CityPart: '',
                Street: '',
                HouseNo: '',
            },
            Geo: {
                XCoord: '',
                YCoord: '',
                Distance: '',
            },
            Identifiers: {
                YMID: '',
                YMIDDecoded: '',
                ProviderID: '',
                ProviderForeignKey: '',
            },
        },
    };
};

export interface MemoItem {
    Title: string;
    Text: string;
}

export interface Identifiers {
    YMID: string;
    ProviderID: string;
    ProviderForeignKey: string;
    YMIDDecoded: string;
}

export interface Paging {
    Page: number;
    MaxPage: number;
    Count: number;
    MaxCount: number;
}

export interface SearchResult {
    AddressItems: AtmElement[];
    GeoItems: GeoItem[];
    Paging: Paging;
    SearchData: {
        Branches: string;
        Location: { XCoord: string; YCoord: string };
    };
}
export interface GeoItem {
    City: string;
    LocX: string;
    LocY: string;
}

export interface AtmElement {
    BasicData: BasicData;
    isRecommended?: boolean;
}

export interface Geo {
    XCoord: string;
    YCoord: string;
    Distance: string;
}

export interface AddressElement {
    CompanyName: string;
    CompanyNameAddon1: string;
    CompanyNameAddon2: string;
    CompanyNameAddon3: string;
    Country: string;
    Zip: string;
    City: string;
    CityPart: string;
    Street: string;
    HouseNo: string;
}

export interface BasicData {
    BranchListElements: BranchElement[];
    Address: AddressElement;
    Geo: Geo;
    Identifiers: Identifiers;
    MemoItems?: MemoItem[];
}

export interface BranchElement {
    BranchCode: string;
}

export interface AutocompleteAddress {
    AdditionalInfo: string;
    City: string;
    CityId: number;
    CityPart: string;
    CitySize: number;
    Country: string;
    DisplayValue: string;
    Distance?: number;
    District: string;
    Id: number;
    InternalId: number;
    Rank?: number;
    State: string;
    Street: string;
    XCoord?: number;
    YCoord?: number;
    Zip?: string;
}

export type Map = {
    getZoom(): number;
    setZoom(zoom: number): Map;
    panTo(center: LatLng): Map;
    setView(center: LatLng, zoom?: number, options?: any): Map;
    remove(): void;
    removeLayer(layer: any): void;
    original: {
        addEventListener(
            type: string,
            listener: EventListenerOrEventListenerObject
        ): void;
        removeEventListener(
            type: string,
            listener: EventListenerOrEventListenerObject
        ): void;
    };
    getBounds(): LatLngRect;
};
export type MapIcon = {};
export type MapMarker = {
    on(type: string, callback: (event: any) => void): void;
    addTo(target: Map | MarkerClusterGroup): void;
    removeFrom(target: Map | MarkerClusterGroup): void;
    setIcon(icon: MapIcon): void;
    setZIndexOffset(z: number): void;
};

export type MarkerClusterGroup = {
    remove(): void;
    getAllChildMarkers(): unknown[];
};

export type AtmHashMap = { [id: string]: AtmElement };
export type MarkerHashMap = { [id: string]: MapMarker };
export type MarkerRefs = React.MutableRefObject<MarkerHashMap>;
