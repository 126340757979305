import { SET_LATITUDE_LONGITUDE, SET_USER_PERMISSION } from './actionTypes';

export const setLatitudeLongitude = (data: any) => ({
    type: SET_LATITUDE_LONGITUDE,
    payload: data,
});

export const setUserPermission = (data: any) => ({
    type: SET_USER_PERMISSION,
    payload: data,
});
