import { YELLOW_MAP_MAX_RESULTS } from '../shared/constants/configs';
import store from '../store/reducers/redux';
import { LatLngRect, SearchResult } from '../types/models';
import { ensureRecommendations } from './recommendations';
import { Query } from './YellowMapQuery';

export const fetchApi = async (query: Query, options: any = {}) => {
    const fetchOptions = {
        ...options,
        headers: {
            Authorization: `Basic ${process.env.REACT_APP_NEW_API_KEY}`,
            ...options.headers,
        },
    };
    const response = await fetch(query.build(), fetchOptions);
    if (response.ok) {
        const result = response.json() as unknown as SearchResult;
        result.AddressItems = result.AddressItems || [];
        return result;
    } else {
        throw new Error(`fetchApi failed: ${response.url}`);
    }
};

async function fetchByCity({
    city,
    page = 1,
}: {
    city: string;
    page?: number;
    recommendations?: boolean;
}): Promise<SearchResult> {
    const state = store.getState();
    const query = new Query()
        .setLimit(page * YELLOW_MAP_MAX_RESULTS)
        .byBranchesAndAddress()
        .usingBranches(state.filters.activeFilter)
        .usingCity(city);
    const result = await fetchApi(query);

    await ensureRecommendations(result);

    return result;
}

async function fetchByGeo({
    lat,
    long,
    page = 1,
}: {
    lat: number;
    long: number;
    page?: number;
    recommendations?: boolean;
}): Promise<SearchResult> {
    const state = store.getState();
    const query = new Query()
        .setLimit(page * YELLOW_MAP_MAX_RESULTS)
        .byBranchesAndPosition()
        .usingBranches(state.filters.activeFilter)
        .usingLatLong(lat, long);

    const result = await fetchApi(query);

    await ensureRecommendations(result);

    return result;
}

let rectAbortController: AbortController;
let rectAbortSignal: AbortController['signal'];

async function fetchByRect({
    rect,
    page = 1,
}: {
    rect: LatLngRect;
    page?: number;
}): Promise<SearchResult> {
    rectAbortController?.abort();

    rectAbortController = new AbortController();
    rectAbortSignal = rectAbortController.signal;

    const state = store.getState();
    const query = new Query()
        .setLimit(page * YELLOW_MAP_MAX_RESULTS)
        .byBranchesAndRectangle()
        .usingBranches(state.filters.activeFilter)
        .usingRectangle(rect);

    const result = await fetchApi(query, { signal: rectAbortSignal });

    const recommendedRes = await ensureRecommendations(result);

    return recommendedRes;
}

async function nullFetcher() {
    console.log('NullFetcher invoked! Something must have went wrong!');
}

export enum FetchingMode {
    ByAddress,
    ByGeo,
    ByRect,
}

// overload for FetchingMode.ByAddress
export function getFetchingStrategy(
    mode: FetchingMode.ByAddress
): typeof fetchByCity;

// overload for FetchingMode.ByGeo
export function getFetchingStrategy(
    mode: FetchingMode.ByGeo
): typeof fetchByGeo;

// overload for FetchingMode.ByRect
export function getFetchingStrategy(
    mode: FetchingMode.ByRect
): typeof fetchByRect;

/**
 * getFetchingStrategy returns the strategy for fetching data from the yellow map api
 * corresponding to the given fetching mode.
 * @param mode the mode used to fetch the data
 */
export function getFetchingStrategy(mode: FetchingMode): any {
    const registry = new Map<FetchingMode, any>([
        [FetchingMode.ByAddress, fetchByCity],
        [FetchingMode.ByGeo, fetchByGeo],
        [FetchingMode.ByRect, fetchByRect],
    ]);
    return registry.has(mode) ? registry.get(mode) : nullFetcher;
}
