import { SET_FEEDBACK } from '../actions/actionTypes/index';

export default function feedback(
    state = { feedbackResponse: {} },
    action: any
) {
    switch (action.type) {
        case SET_FEEDBACK: {
            const newState = { ...state };
            newState.feedbackResponse = action.payload;
            return newState;
        }

        default:
            return state;
    }
}
