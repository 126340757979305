import React from 'react';
import styled from 'styled-components';
import LeftPanel from '../Atm/LeftPanel';
import * as SC from './MapAtmSearch.styled';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store';
import { fetchAtmsByGeo } from '../../store/actions/atms';
import NavigateToMapView from './NavigateToMapView';
import SearchBox from '../../components/Input/SearchBox';
import SearchResultList from '../../components/SearchResult/SearchResult';
import Atm from '../../components/Atm/Atm';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import { PanelContent } from '../../shared/constants/enum';
import { PanelHeader } from '../../components/PanelHeader';
import { useMobileDetection } from '../../shared/hooks';
import { useSlicedAtms } from './hooks/useSlicedAtms';

const NoMapContainer = styled.div`
    background: white;
    height: 100%;
    ${SC.LeftPanelContainer} {
        height: 100%;
    }
    ${SC.NoMapMobileContainer} {
        padding-top: 12px;
    }
    header {
        padding-bottom: 12px;
    }
    header .btn-close {
        display: none;
    }
    ${SC.LeftPanelContainer} > header .btn-close {
        display: block;
    }
`;

const addLoadedClass = (e: { target: any }) => e.target.classList.add('loaded');

const NoMapAtmSearch = (props: any) => {
    const {
        // fetchAtmsByGeo,
        // coords,
        isDetailedAtm,
        isFeedbackForm,
        onClose,
    } = props;

    // React.useEffect(() => {
    //     const { latitude, longitude } = coords || {};
    //     if (fetchAtmsByGeo && latitude && longitude) {
    //         fetchAtmsByGeo({ lat: latitude, long: longitude });
    //     }
    // }, [coords, fetchAtmsByGeo]);

    const { isMobile } = useMobileDetection();

    const { currentAtms, hasMoreAtms, showMoreAtms } = useSlicedAtms({
        atms: props.atms,
    });

    return (
        <NoMapContainer>
            <SC.NoMapDesktopContainer>
                <SC.MainContainer>
                    <LeftPanel
                        currentAtms={currentAtms}
                        showLoadMore={hasMoreAtms}
                        onMoreClick={showMoreAtms}
                    />
                    {!isMobile && (
                        <SC.MapContainer>
                            <SC.ImgItem
                                src={`${process.env.PUBLIC_URL}/img/NoMap_background_low.jpg`}
                                onLoad={addLoadedClass}
                                fade
                            />
                            <SC.ImgItem
                                src={`${process.env.PUBLIC_URL}/img/NoMap_background.jpg`}
                                onLoad={addLoadedClass}
                                fade
                            />
                        </SC.MapContainer>
                    )}
                </SC.MainContainer>
            </SC.NoMapDesktopContainer>
            {isMobile && (
                <SC.LeftPanelContainer>
                    {!isFeedbackForm && !isDetailedAtm ? (
                        <>
                            <PanelHeader onCloseClick={onClose} />
                            <SC.NoMapMobileContainer>
                                <SearchBox />
                                <NavigateToMapView />
                                <SearchResultList
                                    currentAtms={currentAtms}
                                    showLoadMore={hasMoreAtms}
                                    onMoreClick={showMoreAtms}
                                />
                            </SC.NoMapMobileContainer>
                        </>
                    ) : (
                        <SC.Views>
                            {isFeedbackForm && (
                                <FeedbackForm data-testid="atm-complaint-link" />
                            )}
                            {isDetailedAtm && <Atm />}
                        </SC.Views>
                    )}
                </SC.LeftPanelContainer>
            )}
        </NoMapContainer>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAtmsByGeo: async (lat: number, long: number) =>
        dispatch(await fetchAtmsByGeo({ lat, long })),
});

const mapStoreToProps = (store: IStore) => {
    return {
        atms: store.atms.currentAtms,
        coords:
            store.location.userLocation && store.location.userLocation.coords,
        isDetailedAtm: store.common.panelContent === PanelContent.DETAILS,
        isFeedbackForm: store.common.panelContent === PanelContent.FEEDBACK,
    };
};

export default connect(mapStoreToProps, mapDispatchToProps)(NoMapAtmSearch);
