import React from 'react';
import styled from 'styled-components';

type LinkProps = {
    text: string;
    action?: any;
    hrefProp?: string;
    newTab?: boolean;
};

const LinkElem = styled.a``;

const Link = ({ text, action, hrefProp, newTab }: LinkProps) => {
    const renderLinkElementWithAction = (action: any) => {
        return (
            <LinkElem onClick={action} data-testid="link-elem">
                {text}
            </LinkElem>
        );
    };

    const renderLinkElementWithHref = (href: string) => {
        return (
            <LinkElem
                target={newTab ? '_blank' : ''}
                href={href}
                data-testid="link-elem"
            >
                {text}
            </LinkElem>
        );
    };

    return hrefProp === undefined
        ? renderLinkElementWithAction(action)
        : renderLinkElementWithHref(hrefProp);
};

export default Link;
