import { combineReducers } from 'redux';
import location from './location.reducer';
import filters from './filter.reducer';
import feedback from './feedback.reducer';
import hideMobileSearchControls from './hideMobileSearchControls.reducer';
import atms, { detailedAtm } from './atms.reducer';
import splashScreen from './splashScreen.reducer';
import common from './common.reducer';

const rootReducer = combineReducers({
    location,
    filters,
    hideMobileSearchControls,
    atms,
    detailedAtm,
    feedback,
    splashScreen,
    common,
});

export default rootReducer;
