import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEng from './locales/en.json';
import translationGer from './locales/de.json';
import { LANGUAGES } from '../constants/enum';
import { DEFAULT_LANGUAGE } from '../constants/default';

i18n.use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false,
        lng: LANGUAGES.GERMAN,
        fallbackLng: DEFAULT_LANGUAGE, // use de if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        resources: {
            [LANGUAGES.ENGLISH]: {
                translations: translationEng,
            },
            [LANGUAGES.GERMAN]: {
                translations: translationGer,
            },
        },
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
    });

export default i18n;
