import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, LinkSpaceWrapper } from './Disclaimer.styled';
import { MapMode } from '../../types/models';
import Button from '../Button';
import Link from '../Link/Link';
import { BaseBox, BaseFont } from '../../shared/constants/theme';

export function Disclaimer(props: { onProceed: (mode: MapMode) => void }) {
    const [openMoreInfoBtn, setOpenMoreInfoBtn] = React.useState(false);
    const { t } = useTranslation();

    const handleOpenMoreInfo = () => {
        setOpenMoreInfoBtn(!openMoreInfoBtn);
    };

    return (
        <Container
            as="div"
            top={6}
            bottom={6}
            left={4}
            right={4}
            data-testid="popup"
        >
            <BaseBox as="div" bottom={3}>
                <h2>{t('privacyHintHeadline')}</h2>
            </BaseBox>

            <BaseFont as="div" size={300}>
                <p style={{ whiteSpace: 'pre-wrap' }}>{t('privacyHintText')}</p>

                <LinkSpaceWrapper>
                    <div
                        className="moreInfoContainer"
                        onClick={handleOpenMoreInfo}
                    >
                        <Link newTab text={t('privacyHintFurtherInfo')} />
                        <span
                            className={`accordion-label__toggle ${
                                openMoreInfoBtn && 'minusIcon'
                            }`}
                        />
                    </div>
                    {openMoreInfoBtn && (
                        <div className="moreInfo">{t('privacyMoreInfo')}</div>
                    )}
                </LinkSpaceWrapper>

                <BaseBox as="div" top={6} bottom={4}>
                    <Button onClick={() => props.onProceed(MapMode.Maps)}>
                        {t('privacyHintContinueButton')}
                    </Button>
                </BaseBox>

                <Link
                    text={t('privacyHintContinueWithoutLink')}
                    action={() => props.onProceed(MapMode.NoMaps)}
                />
            </BaseFont>
        </Container>
    );
}
