import { atmUtils } from '../helpers/atm-utils';
import { sortAtms } from '../helpers/sortAtms';
import { YELLOW_MAP_RECOMMENDATION_RADIUS } from '../shared/constants/default';
import { FILTER } from '../shared/constants/enum';
import store from '../store/reducers/redux';
import { AtmElement, SearchResult } from '../types/models';
import { fetchApi } from './YellowMapClient';
import { Query } from './YellowMapQuery';

/**
 * Ensures that a given `SearchResult` contains available recommendations for ING and cashback partners.
 * See https://jira.etecture.de/browse/INGGA-241
 *
 * - If the result already has both recommendations, nothing happens
 * - If the result has zero or one recommendation, additional searches are performed to get one result for each (ING,cashback)
 *
 * In the end, if a recommendation is possible for the current search (coordinates!), then we show it - either we already had it, or we fetched and prepended it to the results array.
 *
 * Note: This is a side-effects function - we mutate the `result.AddressItems` array.
 *
 * @param result
 * @returns result with recommendations where available
 */
export async function ensureRecommendations(result: SearchResult) {
    if (result && result.AddressItems.length < 2) return result;
    const lat = Number(result.SearchData.Location.YCoord);
    const lng = Number(result.SearchData.Location.XCoord);

    const { hasIngRecommendation, hasPartnerRecommendation } =
        getCurrentRecommendations(result.AddressItems);
    //only recommend partner atms, when filters set to default or it is deposit or recycler atm

    const filters = store.getState().filters.chipState;
    if (
        (!filters?.atms &&
            !filters?.ingAtms &&
            !filters?.depositAtms &&
            !filters?.ingCashWithCode &&
            !filters?.cashBackPurchase) ||
        (!filters?.atms &&
            !filters?.ingAtms &&
            !filters?.ingCashWithCode &&
            !filters?.cashBackPurchase &&
            filters?.depositAtms)
    ) {
        if (!hasPartnerRecommendation) {
            const partnerRecommendation = await fetchRecommendation(
                FILTER.CASH_PARTNERS,
                lat,
                lng
            );
            if (partnerRecommendation) {
                result.AddressItems.unshift(partnerRecommendation);
            }
        }
        if (!hasIngRecommendation) {
            const ingRecommendation = await fetchRecommendation(
                `${FILTER.ING_ATMS}|${FILTER.ING_RECYCLER_ATMS}`,
                lat,
                lng
            );
            if (ingRecommendation) {
                result.AddressItems.unshift(ingRecommendation);
            }
        }
    }

    return result;
}

function getCurrentRecommendations(atms: AtmElement[]) {
    const sortedAtms = sortAtms([...atms]);

    const hasIngRecommendation =
        atmUtils.isIng(sortedAtms[0]) && sortedAtms[0]?.isRecommended;

    const partnerCandidate = hasIngRecommendation
        ? sortedAtms[1]
        : sortedAtms[0];

    const hasPartnerRecommendation =
        atmUtils.isCashbackPartner(partnerCandidate) &&
        partnerCandidate?.isRecommended;

    return {
        hasIngRecommendation,
        hasPartnerRecommendation,
    };
}

async function fetchRecommendation(
    branches: string,
    lat: number,
    long: number
) {
    const query = new Query()
        .byBranchesAndPosition()
        .usingBranches(branches)
        .usingLatLong(lat, long)
        .usingMaxRadius(YELLOW_MAP_RECOMMENDATION_RADIUS)
        .setLimit(1);

    const result = await fetchApi(query);
    return result.AddressItems[0] as AtmElement | undefined;
}
