import { HIDE_MOBILE_SEARCH_CONTROLS } from '../actions/actionTypes';

interface VisibilityActionType {
    type?: typeof HIDE_MOBILE_SEARCH_CONTROLS;
    payload: boolean;
}

export default function hideMobileSearchControls(
    state = false,
    action: VisibilityActionType
) {
    switch (action.type) {
        case HIDE_MOBILE_SEARCH_CONTROLS: {
            return !action;
        }
        default:
            return state;
    }
}
