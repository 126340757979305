import React, { useCallback, useEffect, useState } from 'react';
import MapAtmSearch from './MapAtmSearch';
import { IErrorInLocation } from './MapAtmSearch.d';
import {
    geoSettings,
    SPLASH_SCREEN_DURATION,
} from '../../shared/constants/configs';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    setLatitudeLongitude,
    setUserPermission,
} from '../../store/actions/location';
import {
    setSplashScreenMessage,
    toggleSplashScreenVisibility,
} from '../../store/actions/splashScreen';
import { useTranslation } from 'react-i18next';
import {
    ING_FRANKFURT_LATITUDE,
    ING_FRANKFURT_LONGITUDE,
} from '../../shared/constants/default';

interface ILoadLocation {
    setLatitudeLongitude: (data: any) => void;
    setUserPermission: (data: any) => void;
    toggleSplashScreenVisibility: (show: boolean) => void;
    setSplashScreenMessage: (message: string) => void;
}

const error: IErrorInLocation = {
    code: 0,
    message: '',
    userMessage: 'Leider konnte Ihr Standort nicht ermittelt werden‚',
};

function LoadingLocation(props: ILoadLocation): JSX.Element {
    const { t } = useTranslation();
    const [, setLocationError] = useState(error);
    const [, setReceivedResponse] = useState(false);

    const {
        setLatitudeLongitude,
        setUserPermission,
        toggleSplashScreenVisibility,
        setSplashScreenMessage,
    } = props;

    const successHandler = useCallback(
        (data: any) => {
            setLocationError(error);
            setReceivedResponse(true);
            setLatitudeLongitude(data);
            setUserPermission(error);
            setTimeout(() => {
                toggleSplashScreenVisibility(false);
                setSplashScreenMessage(t('loadingMessage'));
            }, SPLASH_SCREEN_DURATION);
        },
        [
            setLatitudeLongitude,
            setUserPermission,
            toggleSplashScreenVisibility,
            setSplashScreenMessage,
            t,
        ]
    );

    const errorHandler = useCallback(
        (error: any) => {
            setLocationError(error);
            setReceivedResponse(true);
            let message = '';
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    message = 'User denied the request for Geolocation.';
                    break;
                case error.POSITION_UNAVAILABLE:
                    message = 'Location information is unavailable.';
                    break;
                case error.TIMEOUT:
                    message = 'The request to get user location timed out.';
                    break;
                case error.UNKNOWN_ERROR:
                    message = 'An unknown geoLocation error occurred.';
                    break;
            }
            setSplashScreenMessage(t('loadingMessageDeny'));

            setTimeout(() => {
                toggleSplashScreenVisibility(false);
            }, SPLASH_SCREEN_DURATION);

            error.userMessage = message;
            setUserPermission(error);
            setLatitudeLongitude({
                coords: {
                    latitude: ING_FRANKFURT_LATITUDE,
                    longitude: ING_FRANKFURT_LONGITUDE,
                },
            });
        },
        [
            setLatitudeLongitude,
            setUserPermission,
            setSplashScreenMessage,
            toggleSplashScreenVisibility,
            t,
        ]
    );

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                successHandler,
                errorHandler,
                geoSettings
            );
        } else {
            console.log('Geolocation is not supported by this browser.'); // set error node
            setLocationError({
                code: 500,
                message: 'Geolocation is not supported by this browser.',
                userMessage: error.userMessage,
            });
            setUserPermission({
                code: 500,
                message: 'Geolocation is not supported by this browser.',
                userMessage: error.userMessage,
            });
            setSplashScreenMessage(t('loadingMessageDeny'));
        }
    }, [
        successHandler,
        errorHandler,
        setUserPermission,
        setSplashScreenMessage,
        t,
    ]);

    return (
        <>
            <MapAtmSearch />
        </>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLatitudeLongitude: (data: any) => dispatch(setLatitudeLongitude(data)),
    setUserPermission: (data: any) => dispatch(setUserPermission(data)),
    toggleSplashScreenVisibility: (show: boolean) =>
        dispatch(toggleSplashScreenVisibility(show)),
    setSplashScreenMessage: (message: string) =>
        dispatch(setSplashScreenMessage(message)),
});

export default connect(null, mapDispatchToProps)(LoadingLocation);
