import { AtmElement, MarkerRefs } from '../../../types/models';
import { useMarkerVariant } from './useMarkerVariant';

export function useRecommendationMarkers(options: {
    ymReady: boolean;
    markersById: MarkerRefs;
    currentAtms?: AtmElement[];
}) {
    const { ymReady, markersById, currentAtms } = options;

    const recommendations = [
        currentAtms?.[0]?.isRecommended ? currentAtms[0] : undefined,
        currentAtms?.[1]?.isRecommended ? currentAtms[1] : undefined,
    ];

    useMarkerVariant({
        ymReady,
        atm: recommendations[0],
        markersById,
        variant: 'recommended',
    });

    useMarkerVariant({
        ymReady,
        atm: recommendations[1],
        markersById,
        variant: 'recommended',
    });
}
