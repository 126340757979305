import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store';
import Crosshair from '../../shared/img/crosshair';

import styled from 'styled-components';
import { moveToLocation } from '../../pages/Atm/MapFunctions';
import { reverseGeocode } from '../../api/ym_listing';

interface IUserLocation {
    userLocation?: any;
    userPermissionError?: any;
    isCustomSearch?: boolean;
    setCurrentAddInInput: (data: any) => void;
}

export const CurrentLocationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    &:empty {
        display: none;
    }
`;

const SVGWrapper = styled.div`
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: white;
`;

function UserLocation(props: IUserLocation): JSX.Element {
    const { setCurrentAddInInput, userPermissionError, userLocation } = props;

    const getCurrentLocation = async () => {
        if (userPermissionError && userPermissionError.code === 0) {
            if (userLocation.coords) {
                const { latitude, longitude } = userLocation.coords;
                moveToLocation(latitude, longitude);
                const address = await reverseGeocode(latitude, longitude);
                setCurrentAddInInput(address);
            }
        } else {
            console.log('userPermission error', userPermissionError);
        }
    };

    if (userPermissionError?.code > 0) {
        return <CurrentLocationContainer />;
    }
    return (
        <CurrentLocationContainer>
            <SVGWrapper
                onClick={getCurrentLocation}
                data-testid="user-location-button"
            >
                <Crosshair />
            </SVGWrapper>
        </CurrentLocationContainer>
    );
}

const mapStateToProps = (state: IStore) => {
    return {
        userLocation: state.location.userLocation,
        userPermissionError: state.location.userPermissionError,
    };
};

export default connect(mapStateToProps, null)(UserLocation);
