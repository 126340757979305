import {
    SET_FETCH_STATUS,
    SET_FETCH_RESULTS,
    SET_IS_CUSTOM_SEARCH,
    SET_DETAIL_ATM,
    SET_CURRENT_ADDRESS,
    SET_FETCH_STRATEGY,
    CLEAR_CURRENT_ATMS,
} from './actionTypes';
import { AtmElement, atmElementNullObj, LatLngRect } from '../../types/models';
import { FetchingMode, getFetchingStrategy } from '../../api/YellowMapClient';
import store from '../reducers/redux';
import { ATM_LIST_FETCHING_STRATEGY } from '../../shared/constants/enum';
import { FetchingStrategy } from '../../types/service';
import { getBounds } from '../../pages/Atm/MapFunctions';

export async function fetchAtmsByGeo({
    lat,
    long,
    page = 1,
    silent = false,
    keepCurrentAddress = false,
}: {
    lat: number;
    long: number;
    page?: number;
    silent?: boolean;
    keepCurrentAddress?: boolean;
}) {
    store.dispatch(
        lastAccessedFetchingStrategy({
            fetchType: ATM_LIST_FETCHING_STRATEGY.GEO,
            value: { lat, long },
        })
    );

    if (!silent) store.dispatch(setIsFetching(true));

    const strategy = getFetchingStrategy(FetchingMode.ByGeo);
    const { AddressItems: atms, Paging } = await strategy({
        lat,
        long,
        page,
    });

    if (!silent) store.dispatch(setIsFetching(false));

    return {
        type: SET_FETCH_RESULTS,
        payload: {
            page,
            Paging,
            atms: atms,
            city: keepCurrentAddress
                ? store.getState().atms.currentAddress
                : null,
        },
    };
}

export async function fetchAtmsByCity({
    city,
    page = 1,
    silent = false,
}: {
    city: string;
    page?: number;
    silent?: boolean;
}) {
    store.dispatch(
        lastAccessedFetchingStrategy({
            fetchType: ATM_LIST_FETCHING_STRATEGY.CITY,
            value: { city },
        })
    );

    if (!silent) store.dispatch(setIsFetching(true));

    const strategy = getFetchingStrategy(FetchingMode.ByAddress);
    try {
        const result = await strategy({ city, page });

        if (!silent) store.dispatch(setIsFetching(false));

        if (!result?.GeoItems) {
            return {
                type: SET_FETCH_RESULTS,
                payload: [],
            };
        }

        return {
            type: SET_FETCH_RESULTS,
            payload: {
                page,
                Paging: result.Paging,
                atms: result.AddressItems,
                lat: parseFloat(result.GeoItems[0].LocY),
                long: parseFloat(result.GeoItems[0].LocX),
                city: city,
            },
        };
    } catch (error) {
        return {
            type: SET_FETCH_RESULTS,
            payload: {
                page: 1,
                Paging: {},
                atms: [],
                city: city,
            },
        };
    }
}

export async function fetchAtmsByRect({
    rect = getBounds(),
    silent,
    page = 1,
}: // keepCurrentAddress = false,
{
    rect?: LatLngRect | null;
    silent?: boolean;
    page?: number;
    // keepCurrentAddress?: boolean;
}) {
    if (!rect) {
        return;
    }

    store.dispatch(
        lastAccessedFetchingStrategy({
            fetchType: ATM_LIST_FETCHING_STRATEGY.RECT,
            value: {
                rect,
            },
        })
    );

    let resultAction: any;

    if (!silent) store.dispatch(setIsFetching(true));

    const currentState = store.getState().atms;
    try {
        const strategy = getFetchingStrategy(FetchingMode.ByRect);
        const result = await strategy({ rect, page });
        resultAction = {
            type: SET_FETCH_RESULTS,
            payload: {
                page,
                Paging: result.Paging,
                atms: result.AddressItems,
                lat: currentState.currentLat,
                long: currentState.currentLong,
                city: currentState.currentAddress,
            },
        };
    } catch (error) {
        if ((error as Error).name === 'AbortError') {
            resultAction = {
                type: 'NOOP_ABORTED',
            };
        } else {
            resultAction = {
                type: SET_FETCH_RESULTS,
                payload: {
                    page: 1,
                    Paging: {},
                    atms: [],
                    city: currentState.currentAddress,
                },
            };
        }
    }

    if (!silent) store.dispatch(setIsFetching(false));

    return resultAction;
}

export const lastAccessedFetchingStrategy = (data: FetchingStrategy) => ({
    type: SET_FETCH_STRATEGY,
    payload: data,
});

export const setIsFetching = (data: boolean) => ({
    type: SET_FETCH_STATUS,
    payload: data,
});

export const setIsCustomSearch = (isCustom: boolean) => ({
    type: SET_IS_CUSTOM_SEARCH,
    payload: isCustom,
});

export const setDetailViewAtm = (atm: AtmElement) => ({
    type: SET_DETAIL_ATM,
    payload: atm,
});

export const clearDetailViewAtm = () => ({
    type: SET_DETAIL_ATM,
    payload: atmElementNullObj(),
});

export const setCurrentAddress = (
    address: string,
    lat?: number,
    long?: number
) => ({
    type: SET_CURRENT_ADDRESS,
    payload: { address, lat, long },
});

export const clearCurrentAtms = () => ({
    type: CLEAR_CURRENT_ATMS,
});
