import {
    config,
    GET_FEEDBACK_OPTIONS_PATH,
    POST_FEEDBACK_PATH,
} from '../shared/constants/configs';
import { DEFAULT_LANGUAGE } from '../shared/constants/default';
import { IPostRequestPayload } from '../components/FeedbackForm/FeedbackForm.d';

export const fetchFeedback = async (lang: string, atmType: string) => {
    return await fetch(
        `${config.url.FEEDBACK_API_URL}${GET_FEEDBACK_OPTIONS_PATH}?lang=${
            lang || DEFAULT_LANGUAGE
        }&atm_type=${atmType}`
    )
        .then((response) => response.json())
        .then((response) => {
            return response;
        })
        .catch((error) =>
            console.log('loading of feedback file failed: ', error)
        );
};

export const postFeedback = async (
    lang: string,
    requestObj: IPostRequestPayload
) => {
    const req = {
        method: 'POST',
        body: JSON.stringify(requestObj),
    };

    return await fetch(
        `${config.url.FEEDBACK_API_URL}${POST_FEEDBACK_PATH}?lang=${
            lang || DEFAULT_LANGUAGE
        }`,
        req
    )
        .then((response) => response.json())
        .then((response) => {
            return response;
        })
        .catch((error) =>
            console.log('loading of feedback post req failed: ', error)
        );
};
