import React from 'react';
import { SMARTPHONE_UPPER_BOUND } from '../constants/default';

export function useWindowSize() {
    const [size, setSize] = React.useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    React.useLayoutEffect(() => {
        function handleResize() {
            if (
                window.innerWidth !== size[0] ||
                window.innerHeight !== size[1]
            ) {
                setSize([window.innerWidth, window.innerHeight]);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [size]);

    return size;
}

export function useIphoneLayoutFix(disabled?: boolean) {
    React.useLayoutEffect(() => {
        function handleResize() {
            if (disabled) {
                return;
            }
            // Iphone address bar appearance after switching from lanbscape to portrait scenario fix.
            if (window.matchMedia('(orientation: portrait)').matches) {
                document.getElementsByTagName('html')[0].style.height = '100vh';
                setTimeout(() => {
                    document.getElementsByTagName('html')[0].style.height =
                        '100%';
                }, 360);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [disabled]);
}

export function useMobileDetection() {
    const [width] = useWindowSize();

    return {
        isMobile: width <= SMARTPHONE_UPPER_BOUND,
    };
}

export function usePrevious<T = any>(value: any): T | undefined {
    const ref = React.useRef<T>();

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
