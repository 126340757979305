import styled from 'styled-components';
import { BaseBox, smartphoneUpperBound } from '../../shared/constants/theme';

export const Container = styled(BaseBox)`
    @media screen and (max-width: 320px) {
        margin-left: 12px !important;
        margin-right: 12px !important;
    }
    button {
        white-space: normal;
        height: auto;
        padding: 0.75rem !important;
        line-height: 1;
    }
`;

export const LinkSpaceWrapper = styled.span`
    display: block;

    a {
        color: ${(props) => props.theme.ingLinkColor};
    }

    &:before {
        content: ' ';
    }
    .moreInfoContainer {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
    }
    .moreInfo {
        white-space: pre-line;
        max-height: 280px;
        overflow: scroll;
        margin-top: 16px;
        @media only screen and (max-width: ${smartphoneUpperBound}) {
            max-height: 190px;
        }
    }
    .accordion-label__toggle {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 50%;
        right: 0;
        margin: 0;
        padding: 0;
        border-radius: 50%;
        background-color: ${(props) => props.theme.baseColor};
        transform: translateY(-50%) scale(1);
        transform-origin: center center;
        transition: width 0.25s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .accordion-label__toggle:before {
        height: 1.125rem;
        width: 0.125rem;
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        left: 50%;
        border-radius: 0.125rem;
        background-color: ${(props) => props.theme.baseFontColorInv};
        transform: translate3d(-50%, -50%, 0);
        transform-origin: center center;
        transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .accordion-label__toggle:after {
        width: 1.125rem;
        height: 0.125rem;
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        left: 50%;
        border-radius: 0.125rem;
        background-color: ${(props) => props.theme.baseFontColorInv};
        transform: translate3d(-50%, -50%, 0);
        transform-origin: center center;
        transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .minusIcon:before {
        width: 0;
    }
`;
