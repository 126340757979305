import {
    SET_FETCH_STATUS,
    SET_FETCH_RESULTS,
    SET_IS_CUSTOM_SEARCH,
    SET_DETAIL_ATM,
    SET_FETCH_STRATEGY,
    SET_CURRENT_ADDRESS,
    CLEAR_CURRENT_ATMS,
} from '../actions/actionTypes';
import { AtmElement, atmElementNullObj, Paging } from '../../types/models';
import { FetchingStrategy } from '../../types/service';
import { ATM_LIST_FETCHING_STRATEGY } from '../../shared/constants/enum';
import {
    ING_FRANKFURT_LATITUDE,
    ING_FRANKFURT_LONGITUDE,
} from '../../shared/constants/default';

import set from 'lodash/fp/set';
import { getAtmIdentifier } from '../../helpers/utilities';
import { sortAtms } from '../../helpers/sortAtms';

interface AtmInfoContainer {
    isFetchingAtm?: boolean;
    // we're using a map for two reasons: 1) fast access via ID and 2) stable sorting
    currentAtms?: Record<string, AtmElement>;
    isCustomSearch?: false;
    currentAddress?: '';
    currentLat?: 0;
    currentLong?: 0;

    lastAccessedFetchingStrategy?: FetchingStrategy;
    currentPage: number;
    Paging: Paging;
}

// TODO once yellowmaps API fixes their bugs in the Paging system, we need to
// refactor and get rid of the separate currentPage handling and use Paging.Page instead
// NOTE: pagination bug not fixed as of 2021-04-19

const initialState = {
    // ? currentAtms is initially undefined on purpose
    // ? we distinguish between "nothing loaded yet" and "loaded but no results"
    currentAtms: undefined,
    lastAccessedFetchingStrategy: {
        fetchType: ATM_LIST_FETCHING_STRATEGY.GEO,
        value: {
            city: '',
            lat: ING_FRANKFURT_LATITUDE,
            long: ING_FRANKFURT_LONGITUDE,
        },
    },
    currentPage: 0,
    Paging: {
        Count: 0,
        MaxCount: 0,
        MaxPage: 1,
        Page: 1,
    },
};

export default function atms(
    state: AtmInfoContainer = initialState,
    action: any
) {
    switch (action.type) {
        case SET_FETCH_STATUS: {
            const newState = { ...state };
            newState.isFetchingAtm = action.payload;
            return newState;
        }
        case CLEAR_CURRENT_ATMS: {
            return { ...state, currentAtms: {} };
        }

        case SET_FETCH_RESULTS: {
            let newState = { ...state };

            newState.isFetchingAtm = false;
            newState.Paging = action.payload.Paging;
            newState.currentPage = action.payload.page;
            newState.currentAddress = action.payload.city;
            newState.currentLat = action.payload.lat || state.currentLat;
            newState.currentLong = action.payload.long || state.currentLong;
            newState = reduceCurrentAtms(newState, action.payload.atms);
            newState = updateAtmDistances(newState);
            newState = sortCurrentAtms(newState);

            return newState;
        }
        case SET_FETCH_STRATEGY: {
            const newState = { ...state };
            newState.lastAccessedFetchingStrategy = action.payload;
            return newState;
        }
        case SET_IS_CUSTOM_SEARCH: {
            const newState = { ...state };
            newState.isCustomSearch = action.payload;
            return newState;
        }
        case SET_CURRENT_ADDRESS: {
            let newState = { ...state };

            newState.currentAddress = action.payload.address;

            if (action.payload.lat && action.payload.long) {
                newState.currentLat = action.payload.lat;
                newState.currentLong = action.payload.long;
                newState = updateAtmDistances(newState);
                newState = sortCurrentAtms(newState);
            }

            return newState;
        }
        default:
            return state;
    }
}

/**
 * Adds new atms, deletes obsolete atms, but does not change existing atms
 * @param state
 * @param receivedAtms
 * @returns
 */
function reduceCurrentAtms(
    state: AtmInfoContainer,
    receivedAtms: AtmElement[]
): AtmInfoContainer {
    const nextAtms = { ...state.currentAtms };
    const receivedIds: string[] = [];
    receivedAtms.forEach((atm) => {
        const uid = getAtmIdentifier(atm);
        receivedIds.push(uid);

        // add new ATMs
        if (!nextAtms[uid]) {
            nextAtms[uid] = atm;
        }
    });

    // delete obsolete ATMs

    Object.keys(nextAtms).forEach((key) => {
        if (!receivedIds.includes(key)) {
            delete nextAtms[key];
        }
    });

    return {
        ...state,
        currentAtms: nextAtms,
    };
}

/**
 * Takes the current state and returns a new state where each atm has a manually calculated distance
 * to the currently "searched" lat/long (instead of to the center of the map).
 * The ATMs are then sorted by distance.
 * @param state
 */
function updateAtmDistances(state: AtmInfoContainer): AtmInfoContainer {
    if (typeof window.ym?.latLng !== 'function') {
        return state;
    }

    const currentAtms =
        state.currentAtms &&
        Object.values(state.currentAtms).reduce((result, atm) => {
            const uid = getAtmIdentifier(atm);
            const { XCoord, YCoord } = atm.BasicData.Geo;

            const atmCoords = window.ym.latLng(Number(YCoord), Number(XCoord));
            const targetCoords = window.ym.latLng(
                state.currentLat,
                state.currentLong
            );
            if (!targetCoords || !atmCoords) {
                return result;
            }

            const computedDistance = atmCoords.distanceTo(targetCoords);
            const currentDistance = atm.BasicData.Geo.Distance;

            if (computedDistance !== currentDistance) {
                return {
                    ...result,
                    [uid]: set(
                        'BasicData.Geo.Distance',
                        computedDistance.toString(),
                        atm
                    ),
                };
            }

            return result;
        }, state.currentAtms);

    return {
        ...state,
        currentAtms,
    };
}

/**
 * Takes the current state and returns a new state where the currentAtms are sorted by distance.
 * @param state
 */
function sortCurrentAtms(state: AtmInfoContainer): AtmInfoContainer {
    let atms = state.currentAtms && Object.values(state.currentAtms);
    if (atms) {
        atms = sortAtms(atms);
    }

    return {
        ...state,
        currentAtms: atms?.reduce(
            (result, atm) =>
                Object.assign(result, { [getAtmIdentifier(atm)]: atm }),
            {}
        ),
    };
}

export function detailedAtm(
    state: AtmElement = atmElementNullObj(),
    action: any
) {
    switch (action.type) {
        case SET_DETAIL_ATM:
            return action.payload;
        default:
            return state;
    }
}
