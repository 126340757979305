import React from 'react';
import { getDepositDefects } from '../../helpers/atm-utils';
import { AtmElement, BranchElement } from '../../types/models';

export function useIsDefectiveAtm(atm?: AtmElement | BranchElement[]) {
    const isDefective = React.useMemo(() => {
        return getDepositDefects(atm);
    }, [atm]);

    return isDefective;
}
