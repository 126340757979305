import styled from 'styled-components';
import { Device, utp } from '../../helpers/utilities';
import {
    BaseBox,
    smartphoneUpperBound,
    tabletSLowerBound,
    tabletSUpperBound,
    tabletLLowerBound,
    tabletLUpperBound,
} from '../../shared/constants/theme';

export const AtmElem = styled.div`
    width: 100%;
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        display: none;
    }
`;

export const SvgWrapper = styled.img`
    width: 20px;
    cursor: pointer;
`;

export const FeedbackFormForMobile = styled.div`
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        display: initial;
    }
    display: none;
`;

export const InfoBlockHeadlineContainer = styled.div`
    overflow-wrap: break-word;
`;

export const InfoBlock = styled(BaseBox)`
    & > span {
        display: block;
    }
`;

export const AtmProps = styled.div`
    color: ${(props) => props.theme.baseColor};
    & > div {
        margin-top: ${utp(Device.Desktop, 3)};

        @media only screen and (max-width: ${smartphoneUpperBound}) {
            margin-top: ${utp(Device.Smartphone, 3)};
        }
        @media only screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletSUpperBound}) {
            margin-top: ${utp(Device.TabletS, 3)};
        }
        @media only screen and (min-width: ${tabletLLowerBound}) and (max-width: ${tabletLUpperBound}) {
            margin-top: ${utp(Device.TabletL, 3)};
        }
    }
    & > div > svg {
        vertical-align: middle;
    }
    & > div > span {
        color: black;
    }
`;

export const PropContainer = styled.div`
    display: flex;
    align-items: center;
    img {
        @media screen and (max-width: ${tabletLUpperBound}) {
            height: 24px;
        }
        height: 32px;
    }
`;

export const DetailViewHeader = styled.div`
    position: sticky;
    top: 0;
`;

export const MiniMapImage = styled.img`
    width: 50%;
    display: block;
    margin: auto;
`;

export const DetailViewWrapper = styled.div`
    overflow-y: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        position: fixed;
        top: 0;
        left: 0;
        .detailedElementContainer {
            padding: 18px;
        }
    }

    background: rgba(0, 0, 0, 0.5);

    z-index: 999;
    background: white;
    a {
        line-height: 1.4;
    }
`;
