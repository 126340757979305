import { IStateFilters } from '../../components/Filters/types';
import { SET_ACTIVE_FILTER, UPDATE_ACTIVE_CHIP_FILTER } from './actionTypes';

export const setActiveFilter = (newFilter: string) => ({
    type: SET_ACTIVE_FILTER,
    payload: newFilter,
});
export const updateActiveChipFilter = (chipState: IStateFilters) => ({
    type: UPDATE_ACTIVE_CHIP_FILTER,
    payload: chipState,
});
