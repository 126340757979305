import RightTick from '../../shared/img/rightTick';

type ChipProps = {
    title: string;
    atmType: string;
    atmTypeValue?: boolean;
    handleClick: any;
};

export default function Chip({
    title,
    atmType,
    atmTypeValue,
    handleClick,
}: ChipProps) {
    return (
        <button
            className={`chips ${atmTypeValue && 'active-chip'}`}
            onClick={(e) => handleClick(e, atmType)}
            data-testid={atmType}
        >
            {atmTypeValue && <RightTick />}
            {title}
        </button>
    );
}
