import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AtmElement,
    BranchElement,
    Geo,
    Identifiers,
    MapMode,
    MemoItem,
} from '../../types/models';
import { Dispatch } from 'redux';
import { IStore } from '../../store/reducers/store';
import { setDetailViewAtm } from '../../store/actions/atms';
import { connect } from 'react-redux';
import { normalizeDistance, normalizeText } from '../../helpers/utilities';
import { BaseBox, BaseFont, ingBlack80 } from '../../shared/constants/theme';
import {
    ATM_SEARCH_STRING,
    DEPOSIT_ATM_WORKING_CONDITION,
    MobileView,
    PanelContent,
} from '../../shared/constants/enum';

import { animateToLocation } from '../../pages/Atm/MapFunctions';

import {
    setMobileView,
    setPanelContent,
    setMobileReturn,
} from '../../store/actions/common';
import { useIsDefectiveAtm } from '../../shared/hooks/useIsDefectiveAtm';
import { DepositErrorMessage } from '../DepositErrorMessage/DepositErrorMessage';
import Link from '../Link/Link';

type SearchResultProps = {
    name: string;
    companyNameAddon1?: string;
    street: string;
    zip: string;
    city: string;
    distance: string;
    memoItems?: MemoItem[];
    branchListElements: BranchElement[];
    showAtmDetails?: (atm: AtmElement) => void;
    identifiers?: Identifiers;
    geo?: Geo;
    mapMode?: MapMode;
    iconUrl?: string;
    isRecommendation?: boolean;
};
interface IATMInformation {
    isAtmDefective: boolean;
}
const SearchResultElementDiv = styled.div`
    clear: both;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    animation: ${(props) =>
        props.theme.enableSearchBarFade ? 'fadein 1.5s' : 'none'};

    .notouch & {
        &:hover {
            background: ${(props) => props.theme.itemHoverBackgroundColor};
        }
        cursor: pointer;
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    & > div:first-child {
        display: flex;
    }

    padding: 0 22px;
`;

// todo place exported somewhere else for reuse
export const ResultEntryName = styled.div`
    font-weight: bold;
    &,
    * {
        display: flex;
        align-items: baseline;
        flex-direction: column;
        gap: 5px;
    }
    > span {
        position: relative;
        top: -2px;
    }
`;

const RecommendationLabel = styled(BaseFont)`
    opacity: 0.7;
`;

const MarkerImg = styled.img`
    width: 34px;
    height: 40px;
    margin: 0 3px;
    @media screen and (min-width: ${(props) => props.theme.tabletLLowerBound}) {
        width: 38px;
        height: 45px;
        margin: 0 4px;
    }
    @media screen and (min-width: ${(props) => props.theme.desktopLowerBound}) {
        width: 46px;
        height: 55px;
        margin: 0 5px;
    }
`;

const EntryContentWrapper = styled.div`
    display: flex;
    float: left;
    font-size: 10pt;
`;

// todo place exported somewhere else for reuse
export const DistanceIcon = styled.span`
    font-weight: normal;
    color: ${ingBlack80};
    border-radius: 3px;
    white-space: nowrap;
`;
export const DipositFunction = styled.span`
    font-weight: bold;
    p {
        margin: 0;
    }
`;
export const DefectiveAtm = styled.span`
    font-weight: 700;
    p {
        margin: 0;
    }
`;
export const ATMInformation = styled.div<IATMInformation>`
    opacity: ${(props: any) => (props.isAtmDefective ? '0.3' : '1')};
    p {
        margin-bottom: 0;
    }
`;

const SearchResultElement = ({
    name = '',
    companyNameAddon1 = '',
    street,
    zip,
    city,
    distance,
    memoItems,
    branchListElements,

    identifiers,
    showAtmDetails,
    geo,
    mapMode,
    iconUrl,
    isRecommendation,
}: SearchResultProps) => {
    const { t } = useTranslation();
    const isDefective = useIsDefectiveAtm(branchListElements);

    const clickHandler = React.useCallback(() => {
        if (isDefective.everything) {
            return;
        }

        if (showAtmDetails && identifiers && geo) {
            showAtmDetails({
                BasicData: {
                    BranchListElements: branchListElements,
                    Address: {
                        CompanyName: name,
                        CompanyNameAddon1: companyNameAddon1,
                        CompanyNameAddon2: '',
                        CompanyNameAddon3: '',
                        Country: '',
                        Zip: zip,
                        City: city,
                        CityPart: '',
                        Street: street,
                        HouseNo: '',
                    },
                    MemoItems: memoItems,
                    Geo: {
                        XCoord: geo.XCoord,
                        YCoord: geo.YCoord,
                        Distance: distance,
                    },
                    Identifiers: {
                        YMID: identifiers.YMID,
                        YMIDDecoded: identifiers.YMIDDecoded,
                        ProviderID: identifiers.ProviderID,
                        ProviderForeignKey: identifiers.ProviderForeignKey,
                    },
                },
            });
            if (mapMode === MapMode.Maps) {
                animateToLocation(
                    parseFloat(geo.YCoord),
                    parseFloat(geo.XCoord),
                    17
                );
            }
        }
    }, [
        branchListElements,
        city,
        companyNameAddon1,
        distance,
        geo,
        identifiers,
        isDefective.everything,
        mapMode,
        memoItems,
        name,
        showAtmDetails,
        street,
        zip,
    ]);

    let nameAddon = '';
    if (companyNameAddon1 && !name.includes(companyNameAddon1)) {
        nameAddon = companyNameAddon1;
    }

    return (
        <SearchResultElementDiv
            data-testid="result-element"
            onClick={clickHandler}
        >
            <BaseBox as="div" top={3} bottom={3}>
                <BaseBox as="span" right={3}>
                    <MarkerImg src={iconUrl} alt="icon" />
                </BaseBox>
                <EntryContentWrapper>
                    <BaseFont as="div" size={300}>
                        <ATMInformation isAtmDefective={isDefective.everything}>
                            <ResultEntryName data-testid="result-element-name">
                                {normalizeText(name)}
                                {mapMode !== MapMode.NoMaps &&
                                    !isDefective.everything && (
                                        <BaseFont as="span">
                                            <DistanceIcon data-testid="result-element-icon">
                                                {`${t(
                                                    'distance'
                                                )}: ${normalizeDistance(
                                                    distance
                                                )}`}
                                            </DistanceIcon>
                                        </BaseFont>
                                    )}
                            </ResultEntryName>
                            {branchListElements.map(
                                (key: BranchElement, index) => (
                                    <DipositFunction
                                        key={key.BranchCode + index}
                                    >
                                        <BaseBox
                                            as="div"
                                            top={1}
                                            data-testid="result-element-deposit"
                                        >
                                            <BaseFont>
                                                {(key.BranchCode ===
                                                    DEPOSIT_ATM_WORKING_CONDITION.AVAILABLE ||
                                                    key.BranchCode ===
                                                        DEPOSIT_ATM_WORKING_CONDITION.UNKNOWN) &&
                                                    t('withDepositFunction')}
                                                {key.BranchCode ===
                                                    DEPOSIT_ATM_WORKING_CONDITION.UNAVAILABLE &&
                                                    t('withWithdrawalFunction')}
                                                {key.BranchCode ===
                                                    ATM_SEARCH_STRING.CASH_PARTNERS &&
                                                    t('cashPartnerCode')}
                                                {key.BranchCode ===
                                                    ATM_SEARCH_STRING.CASHBACK_ATMS &&
                                                    t('cashWithShoppingGiro')}
                                                {key.BranchCode ===
                                                    ATM_SEARCH_STRING.VISA_CASHBACK &&
                                                    t('cashWithShoppingVisa')}
                                            </BaseFont>
                                        </BaseBox>
                                    </DipositFunction>
                                )
                            )}
                            {nameAddon && (
                                <div data-testid="result-element-addon">
                                    {normalizeText(nameAddon)}
                                </div>
                            )}
                            <div data-testid="result-element-street">
                                {normalizeText(street)}
                            </div>
                            <span data-testid="result-element-zip">
                                {normalizeText(zip)} {normalizeText(city)}
                            </span>
                            <div>
                                <Link text={t('atmMoreDetails')} />
                            </div>

                            {isRecommendation && (
                                <RecommendationLabel>
                                    {t('recommendation')}
                                </RecommendationLabel>
                            )}
                        </ATMInformation>
                        {isDefective.everything && (
                            <BaseBox as="div" top={1}>
                                <DefectiveAtm>
                                    <BaseFont>{t('defectiveATM')}</BaseFont>
                                </DefectiveAtm>
                            </BaseBox>
                        )}
                        <DepositErrorMessage atm={branchListElements} />
                    </BaseFont>
                </EntryContentWrapper>
            </BaseBox>
        </SearchResultElementDiv>
    );
};

const mapStateToProps = (store: IStore) => {
    return {
        mapMode: store.common.mapMode,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    showAtmDetails: (atm: AtmElement) => {
        dispatch(setDetailViewAtm(atm));
        dispatch(setPanelContent(PanelContent.DETAILS));
        dispatch(setMobileReturn(MobileView.CONTENT));
        dispatch(setMobileView(MobileView.CONTENT));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchResultElement);
