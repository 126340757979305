import React from 'react';
import { supportsPassiveEvents } from 'detect-it';
import { SMARTPHONE_UPPER_BOUND } from '../../../shared/constants/default';

declare global {
    interface Document {
        mozCancelFullScreen?: () => Promise<void>;
        msExitFullscreen?: () => Promise<void>;
        webkitExitFullscreen?: () => Promise<void>;
        mozFullScreenElement?: Element;
        msFullscreenElement?: Element;
        webkitFullscreenElement?: Element;
        onwebkitfullscreenchange?: any;
        onmsfullscreenchange?: any;
        onmozfullscreenchange?: any;
    }

    interface HTMLElement {
        msRequestFullScreen?: () => Promise<void>;
        mozRequestFullScreen?: () => Promise<void>;
        webkitRequestFullScreen?: () => Promise<void>;
    }
}

function requestFullscreen(element: HTMLElement) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen();
    }
}

function exitFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}

function isFullscreen(element: HTMLElement) {
    return (
        (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) === element
    );
}

const eventOptions: any = supportsPassiveEvents
    ? ({ passive: true, capture: false } as any)
    : false;

export function useFullscreen(
    containerRef: React.MutableRefObject<HTMLDivElement | null>
) {
    const toggleFullscreen = React.useCallback(() => {
        if (!containerRef.current) return;

        isFullscreen(containerRef.current)
            ? exitFullscreen()
            : requestFullscreen(containerRef.current);
    }, [containerRef]);

    const handleResize = React.useCallback(() => {
        if (!containerRef.current) return;

        if (
            isFullscreen(containerRef.current) &&
            window.innerWidth <= SMARTPHONE_UPPER_BOUND
        ) {
            // ? there is no fullscreen button/feature on mobile screens
            // ? Devices that have landscape medium size and portrait mobile size
            // ? will offer a fullscreen button in landscape, and the user may use it
            // ? once rotated into portrait, the user would be stuck in fullscreen without a button to leave
            exitFullscreen();
        }
    }, [containerRef]);

    React.useEffect(() => {
        window.addEventListener('resize', handleResize, eventOptions);
        return () => {
            window.removeEventListener('resize', handleResize, eventOptions);
        };
    }, [handleResize]);

    return {
        toggleFullscreen,
    };
}
