import {
    SPLASH_SCREEN_MESSAGE,
    TOGGLE_SHOW_SPLASH_SCREEN,
} from '../actions/actionTypes/index';

interface SplashScreen {
    show: boolean;
    message: string;
}

export default function splashScreen(
    state: SplashScreen = {
        show: true,
        message: 'Ihr Standort wird ermittelt',
    },
    action: any
) {
    switch (action.type) {
        case TOGGLE_SHOW_SPLASH_SCREEN: {
            const newState = { ...state };
            newState.show = action.payload;
            return newState;
        }
        case SPLASH_SCREEN_MESSAGE: {
            const newState = { ...state };
            newState.message = action.payload;
            return newState;
        }
        default:
            return state;
    }
}
