export interface IStateFilters {
    atms: boolean;
    ingAtms: boolean;
    depositAtms: boolean;
    ingCashWithCode: boolean;
    cashBackPurchase: boolean;
}

export interface IFilters {
    chipState?: IStateFilters;
    userLocation?: any;
    updateActiveChipFilter?: (chipState: IStateFilters) => void;
    setActiveFilter?: (searchStr: string) => void;
    clearDetailViewAtm?: any;
}

export enum atmType {
    atms = 'atms',
    ingAtms = 'ingAtms',
    depositAtms = 'depositAtms',
    ingCashWithCode = 'ingCashWithCode',
    cashBackPurchase = 'cashBackPurchase',
}
