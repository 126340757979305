import React from 'react';
import Autosuggest, { SuggestionSelectedEventData } from 'react-autosuggest';
import { supportsPassiveEvents } from 'detect-it';
import { fetchSuggestions } from '../../api/AutocompleteAPI';
import { AutocompleteAddress } from '../../types/models';
import cx from 'classnames';

import { StyledContainer } from './Autosuggest.styled';
import { YELLOW_MAP_AUTOSUGGEST_MIN_CHARS } from '../../shared/constants/configs';
import { Highlighted } from '../Highlighted';
import debounce from 'lodash.debounce';
import { Branding } from './Branding';
// based on https://github.com/moroshko/react-autosuggest#basic-usage

type Props = {
    onSuggestionSelected?: (
        event: React.FormEvent<HTMLInputElement>,
        data: SuggestionSelectedEventData<AutocompleteAddress>
    ) => void;
} & React.HTMLProps<HTMLInputElement>;

type State = {
    suggestions: AutocompleteAddress[];
    dropdownMaxHeight: number;
};

const eventOptions: any = supportsPassiveEvents
    ? ({ passive: true } as any)
    : false;

export class AutosuggestInput extends React.Component<Props, State> {
    state = {
        suggestions: [],
        dropdownMaxHeight: 0,
    };

    containerRef = React.createRef();

    componentDidMount() {
        window.addEventListener('resize', this.measure, eventOptions);
        this.measure();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measure, eventOptions);
    }

    render() {
        const { suggestions } = this.state;
        const { className, onSuggestionSelected, ...inputProps } = this.props;

        return (
            <StyledContainer
                ref={this.containerRef as any}
                className={cx('AutocompleteSearchBox', className)}
                onFocusCapture={this.measure}
            >
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.fetchSuggestions}
                    onSuggestionsClearRequested={this.clearSuggestions}
                    onSuggestionSelected={onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    // @ts-ignore: ref type mismatch
                    inputProps={inputProps}
                />
            </StyledContainer>
        );
    }

    clearSuggestions = () => {
        this.setState({ suggestions: [] });
    };

    fetchSuggestions = async (args: { value: string }) => {
        const inputValue = args.value.trim().toLowerCase();
        if (inputValue.length < YELLOW_MAP_AUTOSUGGEST_MIN_CHARS) {
            this.clearSuggestions();
            return;
        }
        const suggestions = await fetchSuggestions(inputValue);
        this.setState({ suggestions });
    };

    getSuggestionValue = (suggestion: AutocompleteAddress) => {
        return suggestion.DisplayValue;
    };

    getDisplayValue = (suggestion: AutocompleteAddress) => {
        const join = (delim: string, ...values: (string | undefined)[]) => {
            return values.filter(Boolean).join(delim).trim();
        };

        return (
            join(
                ', ',
                suggestion.Street,
                suggestion.CityPart,
                suggestion.District,
                join(' ', suggestion.Zip, suggestion.City)
            ) || suggestion.DisplayValue
        );
    };

    renderSuggestion = (suggestion: AutocompleteAddress) => {
        return (
            <Highlighted highlight={String(this.props.value)}>
                {this.getDisplayValue(suggestion)}
            </Highlighted>
        );
    };

    renderSuggestionsContainer = ({ containerProps, children }: any) => {
        return (
            <div
                {...containerProps}
                style={{
                    maxHeight: this.state.dropdownMaxHeight,
                    overflow: 'auto',
                }}
            >
                {children}
                <Branding />
            </div>
        );
    };

    updateMaxHeight = () => {
        if (!this.containerRef.current) {
            return;
        }
        const el = this.containerRef.current as unknown as HTMLDivElement;
        const rect = el.getBoundingClientRect();

        const bottomMargin = 48;

        const dropdownMaxHeight =
            window.innerHeight - rect.bottom - bottomMargin;

        this.setState({ dropdownMaxHeight });
    };

    updateMaxHeightDebounced = debounce(() => {
        this.updateMaxHeight();
    }, 500);

    measure = () => {
        this.updateMaxHeight();
        this.updateMaxHeightDebounced();
    };
}
