import React from 'react';

import { moveToLocation } from '../MapFunctions';

import { useDispatch } from 'react-redux';
import { fetchAtmsByGeo, setCurrentAddress } from '../../../store/actions/atms';

import { reverseGeocode } from '../../../api/ym_listing';
import { formatAddress } from '../../../helpers/utilities';

/**
 * Applies the user's current location once.
 * - Scrolls the map to the user's location coordinates.
 * - Populates the search input field with the actual address, reverse-geocoded from the coordinates.
 *
 * @param options
 */
export function useHomeAddress(options: {
    ymReady: boolean;
    userLat?: number;
    userLng?: number;
}) {
    const { ymReady, userLat, userLng } = options;
    const [isUserLocationInitialized, setUserLocationInitialized] =
        React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!ymReady) {
            return;
        }

        if (isUserLocationInitialized) {
            return;
        }

        if (!(userLat && userLng)) {
            return;
        }

        window.ym.ready(async () => {
            moveToLocation(userLat, userLng);

            dispatch(
                await fetchAtmsByGeo({
                    lat: userLat,
                    long: userLng,
                    page: 1,
                    silent: false,
                    keepCurrentAddress: true,
                })
            );

            // prefill the user's address into the input field initially
            const address = formatAddress(
                await reverseGeocode(userLat, userLng)
            );

            dispatch(setCurrentAddress(address, userLat, userLng));
            setUserLocationInitialized(true);
        });
    }, [dispatch, isUserLocationInitialized, ymReady, userLat, userLng]);
}
