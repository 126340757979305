// This file is for constant enum values

export enum LANGUAGES {
    ENGLISH = 'en',
    GERMAN = 'de',
}

export enum FILTER {
    ING_ATMS = 'GADB',
    ING_RECYCLER_ATMS = 'GADBxExIB|GADBxExAB|GADBxExUB|GADBxExUD',
    VISA_ATMS = 'GAUTxVISA|GASK|GAVB|GACP',
    VISA_CASHBACK = 'EHSBxVISA',
    ALL_ATMS = 'GAUT',
    CASHBACK_ATMS = 'EHSBxNMD|EHSBxRW|EHSBxMK|EHSBxTB|EHSBxED|EHSBxALD|EHSBxPM|EHSBxMUE|EHSBxDM|EHSB|EHSBxNOR|EHSBxRMN|EHSBxxLIDL|EHSBxMRK|EHSBxNDE|EHSBxTGT|EHSBxKH',
    CASH_PARTNERS = 'EHSBxBAR',
}

// dot icon- penny markt- EHSBxPM- CASHBACK_ATMS
// note icon- penny - EHSBxBAR- CASH_PARTNERS

export const FILTER_CHIP_FUNCTIONALITY = {
    NO_FILTER: `${FILTER.ING_ATMS}|${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_ATMS}|${FILTER.CASHBACK_ATMS}|${FILTER.CASH_PARTNERS}|${FILTER.VISA_CASHBACK}`,
    //one Filter set
    ATMS: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.VISA_ATMS}`,
    ING_ATMS: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}`,
    WITH_DEPOSIT: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}`,
    CASHBACK_PURCHASE: `${FILTER.VISA_CASHBACK}|${FILTER.CASHBACK_ATMS}`,
    ING_CASH_CODE: FILTER.CASH_PARTNERS,
    //two filters set
    ATMS_x_ING_ATMS: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}`,
    ATMS_x_WITH_DEPOSIT: FILTER.ING_RECYCLER_ATMS,
    ATMS_x_CASHBACK_PURCHASE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.VISA_ATMS}|${FILTER.VISA_CASHBACK}`,
    ATMS_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.VISA_ATMS}|${FILTER.CASH_PARTNERS}`,

    ING_ATMS_x_WITH_DEPOSIT: FILTER.ING_RECYCLER_ATMS,
    ING_ATMS_x_CASHBACK_PURCHASE: `${FILTER.ING_ATMS}|${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_CASHBACK}`,
    ING_ATMS_x_ING_CASH_CODE: `${FILTER.ING_ATMS}|${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}`,

    WITH_DEPOSIT_x_CASHBACK_PURCHASE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}|${FILTER.VISA_CASHBACK}`,
    WITH_DEPOSIT_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}`,

    CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.VISA_CASHBACK}|${FILTER.CASH_PARTNERS}`,
    //three filters set
    ATMS_x_ING_ATMS_x_WITH_DEPOSIT: FILTER.ING_RECYCLER_ATMS,
    ATMS_x_ING_ATMS_x_CASHBACK_PURCHASE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.VISA_CASHBACK}`,
    ATMS_x_ING_ATMS_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.CASH_PARTNERS}`,
    ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_CASHBACK}`,
    ATMS_x_WITH_DEPOSIT_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}`,
    ATMS_x_CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.VISA_ATMS}|${FILTER.VISA_CASHBACK}|${FILTER.CASH_PARTNERS}`,

    ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_CASHBACK}`,
    ING_ATMS_x_WITH_DEPOSIT_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}`,
    ING_ATMS_x_CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.VISA_CASHBACK}|${FILTER.CASH_PARTNERS}`,

    WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}|${FILTER.VISA_CASHBACK}`,
    //four filters set
    ATMS_x_ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_CASHBACK}`,
    ATMS_x_ING_ATMS_x_WITH_DEPOSIT_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}`,
    ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_CASHBACK}|${FILTER.CASH_PARTNERS}`,
    ATMS_x_ING_ATMS_x_CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.ING_ATMS}|${FILTER.VISA_CASHBACK}|${FILTER.CASH_PARTNERS}`,
    ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_CASHBACK}|${FILTER.CASH_PARTNERS}`,
    //All filters set
    ATMS_x_ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE: `${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_CASHBACK}|${FILTER.CASH_PARTNERS}`,
};

export enum FEEDBACK_ATM_TYPES {
    ING = 'GADB',
    VISA = 'GAUTxVISA',
}

export enum ATM_SEARCH_STRING {
    VISA = 'GAUTxVISA',
    CASHBACK_ATMS = 'EHSB',
    DEPOSIT = 'GADBxEx',
    ING = 'GADB',
    CASH_PARTNERS = 'EHSBxBAR',
    VISA_CASHBACK = 'EHSBxVISA',
}

export enum ATM_LIST_FETCHING_STRATEGY {
    GEO = 'geo',
    CITY = 'city',
    RECT = 'rect',
}

/** BranchCodes for regular withdrawal ATMs ("Auszahler") status */
export enum WITHDRAWAL_ATM_WORKING_CONDITION {
    AVAILABLE = 'GADBxAxIB',
    NOT_WORKING = 'GADBxAxAB',
    UNKNOWN = 'GADBxAxUB',
}

/** BranchCodes for deposit ATMs status ("Einzahler") */
export enum DEPOSIT_ATM_WORKING_CONDITION {
    AVAILABLE = 'GADBxExIB',
    UNKNOWN = 'GADBxExUB',

    NOT_WORKING = 'GADBxExAB',
    UNAVAILABLE = 'GADBxExNV',
}

/** whether to show the map or the content panel on mobile device */
export enum MobileView {
    MAP = 'MAP',
    CONTENT = 'CONTENT',
}

/** the kind of view displayed in the content panel  */
export enum PanelContent {
    DETAILS = 'DETAILS',
    FEEDBACK = 'FEEDBACK',
    SEARCH = 'SEARCH',
}

export enum AtmType {
    ING = 'ING',
    DEPOSIT = 'DEPOSIT',
    VISA = 'VISA',
    PARTNER = 'PARTNER',
    CASHBACK = 'CASHBACK',
    VISA_CASHBACK = 'VISA_CASHBACK',
}
