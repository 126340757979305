// ing theme for generalizing styles

import {
    DESKTOP_LOWER_BOUND,
    DESKTOP_UPPER_BOUND,
    SMARTPHONE_UPPER_BOUND,
    TABLET_L_LOWER_BOUND,
    TABLET_L_UPPER_BOUND,
    TABLET_S_LOWER_BOUND,
    TABLET_S_UPPER_BOUND,
} from './default';
import styled from 'styled-components';

enum TypographyKind {
    smartphoneFZ,
    tabletFZ,
    desktopFZ,
    smartphoneLH,
    tabletLH,
    desktopLH,
}

export type Theme = typeof IngTheme;

export const IngTheme = {
    ingOrange: '#ff6200',
    ingIndigo: '#525199',
    ingBlue: '#559bd1',
    ingBlack80: '#333',
    ingBlack34: '#a8a8a8',
    ingBlack15: '#d9d9d9',
    ingLinkColor: '#525199',
    ingLinkHoverBgColor: '#e6e5f0',
    ingRed: '#D70000',

    baseFontColor: '#1a1a1a',
    baseFontColorInv: '#fff',
    baseColor: '#ff6200',
    baseColorHover: '#ff8900',
    baseFont: '16px/1.3 INGMe, INGMeWebRegular, Helvetica, sans-serif',
    enableSearchBarFade: false,
    smartphoneUpperBound: SMARTPHONE_UPPER_BOUND + 'px',
    tabletSLowerBound: TABLET_S_LOWER_BOUND + 'px',
    tabletSUpperBound: TABLET_S_UPPER_BOUND + 'px',
    tabletLLowerBound: TABLET_L_LOWER_BOUND + 'px',
    tabletLUpperBound: TABLET_L_UPPER_BOUND + 'px',
    desktopLowerBound: DESKTOP_LOWER_BOUND + 'px',
    desktopUpperBound: DESKTOP_UPPER_BOUND + 'px',
    itemHoverBackgroundColor: '#eff2fa',
    sideBarWidth: '350px',

    /* Unit D */

    // smartphone px
    smartphone1D: 4,
    smartphone2D: 8,
    smartphone3D: 12,
    smartphone4D: 16,
    smartphone5D: 20,
    smartphone6D: 24,
    smartphone7D: 28,
    smartphone8D: 32,
    smartphone9D: 36,
    smartphone10D: 40,
    smartphone11D: 44,
    smartphone12D: 48,
    smartphone13D: 52,
    smartphone14D: 56,
    smartphone15D: 60,
    smartphone16D: 64,

    // tablet px
    tablet1D: 4.5,
    tablet2D: 9,
    tablet3D: 13.5,
    tablet4D: 18,
    tablet5D: 22.5,
    tablet6D: 27,
    tablet7D: 31.5,
    tablet8D: 36,
    tablet9D: 40.5,
    tablet10D: 45,
    tablet11D: 49.5,
    tablet12D: 54,
    tablet13D: 58.5,
    tablet14D: 63,
    tablet15D: 67.5,
    tablet16D: 72,

    // desktop px
    desktop1D: 5.5,
    desktop2D: 11,
    desktop3D: 16.5,
    desktop4D: 22,
    desktop5D: 27.5,
    desktop6D: 33,
    desktop7D: 38.5,
    desktop8D: 44,
    desktop9D: 49.5,
    desktop10D: 55,
    desktop11D: 60.5,
    desktop12D: 66,
    desktop13D: 71.5,
    desktop14D: 77,
    desktop15D: 82.5,
    desktop16D: 88,

    typography: new Map([
        [
            700,
            new Map([
                [TypographyKind.smartphoneFZ, '36px'],
                [TypographyKind.tabletFZ, '40.5px'],
                [TypographyKind.desktopFZ, '49.5px'],
                [TypographyKind.smartphoneLH, '48px'],
                [TypographyKind.tabletLH, '54px'],
                [TypographyKind.desktopLH, '66px'],
            ]),
        ],
        [
            600,
            new Map([
                [TypographyKind.smartphoneFZ, '28px'],
                [TypographyKind.tabletFZ, '31.5px'],
                [TypographyKind.desktopFZ, '38.5px'],
                [TypographyKind.smartphoneLH, '36px'],
                [TypographyKind.tabletLH, '40.5px'],
                [TypographyKind.desktopLH, '49.5px'],
            ]),
        ],
        [
            500,
            new Map([
                [TypographyKind.smartphoneFZ, '24px'],
                [TypographyKind.tabletFZ, '27px'],
                [TypographyKind.desktopFZ, '33px'],
                [TypographyKind.smartphoneLH, '32px'],
                [TypographyKind.tabletLH, '36px'],
                [TypographyKind.desktopLH, '44px'],
            ]),
        ],
        [
            400,
            new Map([
                [TypographyKind.smartphoneFZ, '19px'],
                [TypographyKind.tabletFZ, '21.38px'],
                [TypographyKind.desktopFZ, '26.13px'],
                [TypographyKind.smartphoneLH, '28px'],
                [TypographyKind.tabletLH, '31.5px'],
                [TypographyKind.desktopLH, '38.5px'],
            ]),
        ],
        [
            300,
            new Map([
                [TypographyKind.smartphoneFZ, '16px'],
                [TypographyKind.tabletFZ, '18px'],
                [TypographyKind.desktopFZ, '22px'],
                [TypographyKind.smartphoneLH, '24px'],
                [TypographyKind.tabletLH, '27px'],
                [TypographyKind.desktopLH, '33px'],
            ]),
        ],
        [
            200,
            new Map([
                [TypographyKind.smartphoneFZ, '14px'],
                [TypographyKind.tabletFZ, '15.75px'],
                [TypographyKind.desktopFZ, '19.25px'],
                [TypographyKind.smartphoneLH, '20px'],
                [TypographyKind.tabletLH, '22.5px'],
                [TypographyKind.desktopLH, '27.5px'],
            ]),
        ],
        [
            100,
            new Map([
                [TypographyKind.smartphoneFZ, '12px'],
                [TypographyKind.tabletFZ, '13.5px'],
                [TypographyKind.desktopFZ, '16.5px'],
                [TypographyKind.smartphoneLH, '16px'],
                [TypographyKind.tabletLH, '18px'],
                [TypographyKind.desktopLH, '22px'],
            ]),
        ],
    ]),
};

export const BaseBox = styled.div<{
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}>`
    &:not(.use-padding) {
        margin: ${(props) => (props.top ? props.top * 5.5 + 'px' : 0)}
            ${(props) => (props.right ? props.right * 5.5 + 'px' : 0)}
            ${(props) => (props.bottom ? props.bottom * 5.5 + 'px' : 0)}
            ${(props) => (props.left ? props.left * 5.5 + 'px' : 0)};
        @media only screen and (max-width: ${smartphoneUpperBound}) {
            margin: ${(props) => (props.top ? props.top * 4 + 'px' : 0)}
                ${(props) => (props.right ? props.right * 4 + 'px' : 0)}
                ${(props) => (props.bottom ? props.bottom * 4 + 'px' : 0)}
                ${(props) => (props.left ? props.left * 4 + 'px' : 0)};
        }
        @media only screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletSUpperBound}) {
            margin: ${(props) => (props.top ? props.top * 4.5 + 'px' : 0)}
                ${(props) => (props.right ? props.right * 4.5 + 'px' : 0)}
                ${(props) => (props.bottom ? props.bottom * 4.5 + 'px' : 0)}
                ${(props) => (props.left ? props.left * 4.5 + 'px' : 0)};
        }
        @media only screen and (min-width: ${tabletLLowerBound}) and (max-width: ${tabletLUpperBound}) {
            margin: ${(props) => (props.top ? props.top * 4.5 + 'px' : 0)}
                ${(props) => (props.right ? props.right * 4.5 + 'px' : 0)}
                ${(props) => (props.bottom ? props.bottom * 4.5 + 'px' : 0)}
                ${(props) => (props.left ? props.left * 4.5 + 'px' : 0)};
        }
    }
    &.use-padding {
        padding: ${(props) => (props.top ? props.top * 5.5 + 'px' : 0)}
            ${(props) => (props.right ? props.right * 5.5 + 'px' : 0)}
            ${(props) => (props.bottom ? props.bottom * 5.5 + 'px' : 0)}
            ${(props) => (props.left ? props.left * 5.5 + 'px' : 0)};
        @media only screen and (max-width: ${smartphoneUpperBound}) {
            padding: ${(props) => (props.top ? props.top * 4 + 'px' : 0)}
                ${(props) => (props.right ? props.right * 4 + 'px' : 0)}
                ${(props) => (props.bottom ? props.bottom * 4 + 'px' : 0)}
                ${(props) => (props.left ? props.left * 4 + 'px' : 0)};
        }
        @media only screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletSUpperBound}) {
            padding: ${(props) => (props.top ? props.top * 4.5 + 'px' : 0)}
                ${(props) => (props.right ? props.right * 4.5 + 'px' : 0)}
                ${(props) => (props.bottom ? props.bottom * 4.5 + 'px' : 0)}
                ${(props) => (props.left ? props.left * 4.5 + 'px' : 0)};
        }
        @media only screen and (min-width: ${tabletLLowerBound}) and (max-width: ${tabletLUpperBound}) {
            padding: ${(props) => (props.top ? props.top * 4.5 + 'px' : 0)}
                ${(props) => (props.right ? props.right * 4.5 + 'px' : 0)}
                ${(props) => (props.bottom ? props.bottom * 4.5 + 'px' : 0)}
                ${(props) => (props.left ? props.left * 4.5 + 'px' : 0)};
        }
    }
`;

export const BaseFont = styled.p<{ size?: number }>`
    font-size: ${(props) =>
        props.theme.typography.get(props.size).get(TypographyKind.desktopFZ)};
    line-height: ${(props) =>
        props.theme.typography.get(props.size).get(TypographyKind.desktopLH)};
    @media only screen and (max-width: ${(props) =>
            props.theme.smartphoneUpperBound}) {
        font-size: ${(props) =>
            props.theme.typography
                .get(props.size)
                .get(TypographyKind.smartphoneFZ)};
        line-height: ${(props) =>
            props.theme.typography
                .get(props.size)
                .get(TypographyKind.smartphoneLH)};
    }
    @media only screen and (min-width: ${(props) =>
            props.theme.tabletSLowerBound}) and (max-width: ${(props) =>
            props.theme.tabletSUpperBound}) {
        font-size: ${(props) =>
            props.theme.typography
                .get(props.size)
                .get(TypographyKind.tabletFZ)};
        line-height: ${(props) =>
            props.theme.typography
                .get(props.size)
                .get(TypographyKind.tabletLH)};
    }
    @media only screen and (min-width: ${(props) =>
            props.theme.tabletLLowerBound}) and (max-width: ${(props) =>
            props.theme.tabletLUpperBound}) {
        font-size: ${(props) =>
            props.theme.typography
                .get(props.size)
                .get(TypographyKind.tabletFZ)};
        line-height: ${(props) =>
            props.theme.typography
                .get(props.size)
                .get(TypographyKind.tabletLH)};
    }
`;
BaseFont.defaultProps = { size: 100 };

// TODO: deduplicate headlines and titles styles
export const BaseTitle = styled.div`
    font-weight: 700;
    color: ${ingBlack80};
`;
// helper getters for common theme props

export function ingOrange(props: { theme: Theme }) {
    return props.theme.ingOrange;
}
export function ingIndigo(props: { theme: Theme }) {
    return props.theme.ingIndigo;
}
export function ingBlue(props: { theme: Theme }) {
    return props.theme.ingBlue;
}
export function ingBlack80(props: { theme: Theme }) {
    return props.theme.ingBlack80;
}
export function ingBlack34(props: { theme: Theme }) {
    return props.theme.ingBlack34;
}
export function ingBlack15(props: { theme: Theme }) {
    return props.theme.ingBlack15;
}
export function ingLinkColor(props: { theme: Theme }) {
    return props.theme.ingLinkColor;
}
export function ingLinkHoverBgColor(props: { theme: Theme }) {
    return props.theme.ingLinkHoverBgColor;
}
export function baseFontColor(props: { theme: Theme }) {
    return props.theme.baseFontColor;
}
export function baseFontColorInv(props: { theme: Theme }) {
    return props.theme.baseFontColorInv;
}
export function baseColor(props: { theme: Theme }) {
    return props.theme.baseColor;
}
export function baseColorHover(props: { theme: Theme }) {
    return props.theme.baseColorHover;
}
export function baseFont(props: { theme: Theme }) {
    return props.theme.baseFont;
}
export function enableSearchBarFade(props: { theme: Theme }) {
    return props.theme.enableSearchBarFade;
}
export function smartphoneUpperBound(props: { theme: Theme }) {
    return props.theme.smartphoneUpperBound;
}
export function tabletSLowerBound(props: { theme: Theme }) {
    return props.theme.tabletSLowerBound;
}
export function tabletSUpperBound(props: { theme: Theme }) {
    return props.theme.tabletSUpperBound;
}
export function tabletLLowerBound(props: { theme: Theme }) {
    return props.theme.tabletLLowerBound;
}
export function tabletLUpperBound(props: { theme: Theme }) {
    return props.theme.tabletLUpperBound;
}
export function desktopLowerBound(props: { theme: Theme }) {
    return props.theme.desktopLowerBound;
}
export function desktopUpperBound(props: { theme: Theme }) {
    return props.theme.desktopUpperBound;
}
export function itemHoverBackgroundColor(props: { theme: Theme }) {
    return props.theme.itemHoverBackgroundColor;
}
export function sideBarWidth(props: { theme: Theme }) {
    return props.theme.sideBarWidth;
}
