import React from 'react';
import cx from 'classnames';
import MagSVG from '../../shared/img/searchIcon.svg';
import UserLocation /* ,{CurrentLocationContainer} */ from './UserLocation';
import { formatAddress } from '../../helpers/utilities';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store';
import { DEFAULT_PLACEHOLDER_VALUE } from '../../shared/constants/default';
import { Dispatch } from 'redux';
import {
    fetchAtmsByCity,
    setIsCustomSearch,
    clearDetailViewAtm,
} from '../../store/actions/atms';

import { AutosuggestInput } from './AutosuggestInput';
import { SuggestionSelectedEventData } from 'react-autosuggest';
import { AutocompleteAddress, MapMode } from '../../types/models';
import {
    SearchBoxButton,
    SearchElementsWrapper,
    SearchClearButton,
} from './SearchBox.styled';
import CloseIcon from '../../shared/img/close-icon.svg';
import { YELLOW_MAP_INITIAL_ZOOM } from '../../shared/constants/configs';
import { getGlobalMap } from '../../pages/Atm/mapInstance';

type SearchBoxProps = {
    mapMode?: MapMode;
    placeholder?: string;
    alternativeButtonAction?: any;
    fetchAtmsByCity?: (address: string) => Promise<any>;
    setIsCustomSearch?: (isCustomSearch: boolean) => void;
    userPermissionError?: any;
    currentAddress?: string;
};

const SearchBox = ({
    placeholder = DEFAULT_PLACEHOLDER_VALUE,
    fetchAtmsByCity,
    setIsCustomSearch,
    currentAddress = '',
}: SearchBoxProps) => {
    const [inputValue, setInputValue] = React.useState(currentAddress);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        setInputValue(currentAddress);
    }, [currentAddress]);

    const handleChange = React.useCallback((e) => {
        setInputValue(e.target.value);
    }, []);

    const handleClear = React.useCallback(() => {
        setInputValue('');
        const form = containerRef.current as unknown as HTMLFormElement;
        const input = form?.querySelector('input');
        input?.focus();
    }, [containerRef]);

    const performSearch = React.useCallback(
        async (searchTerm: string) => {
            if (!searchTerm) return;
            setIsCustomSearch?.(true);
            window.ignoreZoomChange = true;
            const searchTermWithoutComma = searchTerm.replaceAll(',', ' ');

            try {
                await fetchAtmsByCity?.(searchTermWithoutComma);
                const map = getGlobalMap();
                map?.setZoom(YELLOW_MAP_INITIAL_ZOOM);
            } catch (error) {
                console.warn({ error });
            }
            window.ignoreZoomChange = false;
        },
        [setIsCustomSearch, fetchAtmsByCity]
    );

    const handleSubmit = React.useCallback(
        (e) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            performSearch(inputValue);
        },
        [performSearch, inputValue]
    );

    const handleSuggestionSelected = React.useCallback(
        (
            event: any,
            data: SuggestionSelectedEventData<AutocompleteAddress>
        ) => {
            setInputValue(data.suggestionValue);
            performSearch(data.suggestionValue);
        },
        [performSearch]
    );

    const setCurrentAddInInput = React.useCallback(
        (addressObject: any) => {
            const address = formatAddress(addressObject);
            setInputValue(address);
            performSearch(address);
        },
        [setInputValue, performSearch]
    );

    // hide user location icon for now, see https://jira.etecture.de/browse/INGGA-213
    const showUserLocationButton = false;
    // const showUserLocationButton = mapMode !== MapMode.NoMaps && userPermissionError?.code === 0 ;

    const showClearButton = inputValue?.length > 0;

    return (
        <SearchElementsWrapper
            ref={containerRef}
            onSubmit={handleSubmit}
            className={cx({
                hasExtraButton: showUserLocationButton || showClearButton,
            })}
        >
            <AutosuggestInput
                data-testid="search-box-input"
                placeholder={placeholder}
                value={inputValue || ''}
                title={inputValue?.length > 30 ? inputValue : ''}
                onChange={handleChange}
                onSuggestionSelected={handleSuggestionSelected}
            />

            {showUserLocationButton && (
                <UserLocation setCurrentAddInInput={setCurrentAddInInput} />
            )}
            {showClearButton && (
                <SearchClearButton
                    onClick={handleClear}
                    aria-label="Eingabe löschen"
                    type="button"
                >
                    <img src={CloseIcon} alt="clear" />
                </SearchClearButton>
            )}
            <SearchBoxButton type="submit">
                <img src={MagSVG} alt="search-icon" />
            </SearchBoxButton>
        </SearchElementsWrapper>
    );
};

const mapStoreToProps = (state: IStore) => {
    return {
        userPermissionError: state.location.userPermissionError,
        mapMode: state.common.mapMode,
        currentAddress: state.atms.currentAddress,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAtmsByCity: async (address: string) => {
        dispatch(clearDetailViewAtm());
        return dispatch(await fetchAtmsByCity({ city: address }));
    },
    setIsCustomSearch: (isCustomSearch: boolean) =>
        dispatch(setIsCustomSearch(isCustomSearch)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(SearchBox);
