import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store.d';
import i18next from 'i18next';
import FormLayout from './FormLayout';
import { fetchFeedback } from '../../api/fetchCalls';
import { setFeedback } from '../../store/actions/feedback';
import { Dispatch } from 'redux';
import { IFeedbackForm, IGetRequestResponse } from './FeedbackForm.d';
import {
    FEEDBACK_ATM_TYPES,
    FILTER,
    PanelContent,
} from '../../shared/constants/enum';

const FeedbackForm = (props: IFeedbackForm) => {
    const { setFeedback, atmBranchListElements, isVisible } = props;

    useEffect(() => {
        (async function () {
            let atmType = FEEDBACK_ATM_TYPES.VISA;
            for (const element of atmBranchListElements) {
                if (element.BranchCode.includes(FILTER.ING_ATMS)) {
                    atmType = FEEDBACK_ATM_TYPES.ING;
                    break;
                }
            }

            const feedbackResponse = await fetchFeedback(
                i18next.language,
                atmType
            );
            if (setFeedback) {
                setFeedback(feedbackResponse);
            }
        })();
    }, [atmBranchListElements, setFeedback]);

    if (!isVisible) {
        return null;
    }

    return <FormLayout />;
};

const mapStateToProps = (state: IStore) => {
    return {
        atmBranchListElements: state.detailedAtm.BasicData.BranchListElements,
        isVisible: state.common.panelContent === PanelContent.FEEDBACK,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    setFeedback: (response: IGetRequestResponse) =>
        dispatch(setFeedback(response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
