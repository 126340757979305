import { Map, MarkerClusterGroup } from '../../types/models';

const ns: {
    map: Map | null;
    markers: MarkerClusterGroup | null;
} = {
    map: null,
    markers: null,
};

// Oh, how it smells !!!
//
// While this is an improvement to using window.map all over the place, it still is a major code smell:
// We use global-ish instances of map and marker cluster group, and access then from various callsites. Terrible.
// A context-based solution might be a somewhat good refactoring.

export function setGlobalMap(instance: Map | null) {
    ns.map = instance;
}

export function getGlobalMap() {
    return ns.map;
}

export function setGlobalMarkers(instance: MarkerClusterGroup | null) {
    ns.markers = instance;
}

export function getGlobalMarkers() {
    return ns.markers;
}

window.atmSearch = window.atmSearch || {};
Object.assign(window.atmSearch, {
    getGlobalMap,
    getGlobalMarkers,
});
