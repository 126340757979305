export const SET_LATITUDE_LONGITUDE = 'SET_LATITUDE_LONGITUDE';
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION';

export const HIDE_MOBILE_SEARCH_CONTROLS = 'HIDE_MOBILE_SEARCH_CONTROLS';
export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';
export const UPDATE_ACTIVE_CHIP_FILTER = 'UPDATE_ACTIVE_CHIP_FILTER';

export const SET_FETCH_STATUS = 'SET_FETCH_STATUS';
export const SET_FETCH_RESULTS = 'SET_FETCH_RESULTS';
export const SET_DETAIL_ATM = 'SET_DETAIL_ATM';
export const SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS';
export const SET_IS_CUSTOM_SEARCH = 'SET_IS_CUSTOM_SEARCH';
export const SET_FETCH_STRATEGY = 'SET_FETCH_STRATEGY';

export const TOGGLE_SHOW_SPLASH_SCREEN = 'TOGGLE_SHOW_SPLASH_SCREEN';
export const SPLASH_SCREEN_MESSAGE = 'SPLASH_SCREEN_MESSAGE';

export const SET_FEEDBACK = 'SET_FEEDBACK';

export const SET_MAP_MODE = 'SET_MAP_MODE';

export const SET_PANEL_CONTENT = 'SET_PANEL_CONTENT';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const SET_MOBILE_RETURN = 'SET_MOBILE_RETURN';
export const CLEAR_CURRENT_ATMS = 'CLEAR_CURRENT_ATMS';
