import { AtmElement } from '../types/models';
import { atmUtils } from './atm-utils';

export const sortAtms = (atms: AtmElement[]) => {
    // 1. sort by distance
    atms.sort((a: AtmElement, b: AtmElement) => {
        const aDistance = Number(a.BasicData.Geo.Distance);
        const bDistance = Number(b.BasicData.Geo.Distance);
        return aDistance - bDistance;
    });

    // 2. Find and lift cashback partner
    const cashbackPartner = atms.find((atm) => atmUtils.isCashbackPartner(atm));
    if (cashbackPartner) {
        cashbackPartner.isRecommended = true;
        atms.splice(atms.indexOf(cashbackPartner), 1);
        atms.unshift(cashbackPartner);
    }

    // 3. Find and lift ING ATM
    const ingAtm = atms.find(
        (atm) => atmUtils.isIng(atm) && atmUtils.isFullyOperational(atm)
    );
    if (ingAtm) {
        ingAtm.isRecommended = true;
        atms.splice(atms.indexOf(ingAtm), 1);
        atms.unshift(ingAtm);
    }

    return atms;
};
