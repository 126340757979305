import { YELLOW_MAP_MAX_RESULTS } from '../shared/constants/configs';
import { YELLOW_MAP_BASE_URL } from '../shared/constants/default';
import { LatLngRect } from '../types/models';

export class Query {
    private query: string = '';
    private locationQueryPermit: number = 1;
    private rootQueryPermit: number = 1;
    private limit: number = YELLOW_MAP_MAX_RESULTS;

    private static appendTo(query: string, appendix: string) {
        if (query.endsWith('?') || query.endsWith('&')) query += appendix;
        else if (query.includes('?')) query += `&${appendix}`;
        else query += `?${appendix}`;
        return query;
    }

    setLimit(limit: number) {
        this.limit = limit;
        return this;
    }

    byBranchesAndPosition() {
        if (this.rootQueryPermit) {
            this.query = 'ByBranchesAndPosition';
            this.rootQueryPermit &= 0;
        }
        return this;
    }

    byBranchesAndAddress() {
        if (this.rootQueryPermit) {
            this.query = 'ByBranchesAndAddress?';
            this.rootQueryPermit &= 0;
        }
        return this;
    }

    byBranchesAndRectangle() {
        if (this.rootQueryPermit) {
            this.query = 'ByBranchesAndRectangle?';
            this.rootQueryPermit &= 0;
        }
        return this;
    }

    usingBranches(branch: string) {
        if (branch.endsWith('|'))
            branch = branch.substring(0, branch.length - 1);
        this.query = Query.appendTo(this.query, `Branches=${branch}`);
        return this;
    }

    usingLatLong(lat: number, long: number) {
        if (this.locationQueryPermit) {
            this.query = Query.appendTo(this.query, `LocY=${lat}&LocX=${long}`);
            this.locationQueryPermit &= 0;
        }
        return this;
    }

    usingMaxRadius(meters: number) {
        this.query = Query.appendTo(
            this.query,
            `MaxRadius=${Math.floor(meters)}`
        );
        return this;
    }

    usingRectangle({ northWest, southEast }: LatLngRect) {
        if (this.locationQueryPermit) {
            this.query = Query.appendTo(
                this.query,
                `Luy=${northWest.lat}&Lux=${northWest.lng}`
            );
            this.query = Query.appendTo(
                this.query,
                `Rly=${southEast.lat}&Rlx=${southEast.lng}`
            );
            this.locationQueryPermit &= 0;
        }
        return this;
    }

    usingCity(city: string) {
        if (this.locationQueryPermit) {
            this.query = Query.appendTo(this.query, `city=${city}`);
            this.locationQueryPermit &= 0;
        }
        return this;
    }

    build() {
        this.query = Query.appendTo(this.query, `Top=${this.limit}`);
        return `${YELLOW_MAP_BASE_URL}/${this.query}`;
    }
}
