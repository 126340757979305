import styled from 'styled-components';
import { ingBlack80 } from '../../shared/constants/theme';
import { StyledBranding } from './Branding.styled';

export const StyledContainer = styled.div`
    position: relative;
    flex: 1;
    .react-autosuggest__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    .react-autosuggest__suggestions-container {
        &:not(:empty) {
            border: 1px solid ${(props) => props.theme.ingBlack15};
            border-top: 0;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    li {
        padding: 10px 20px;
        position: relative;
        background: white;

        color: ${ingBlack80};
        cursor: pointer;
        .notouch & {
            &:hover {
                background: ${(props) => props.theme.itemHoverBackgroundColor};
            }
        }
        &.react-autosuggest__suggestion--highlighted {
            background: ${(props) => props.theme.itemHoverBackgroundColor};
        }
        &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    .react-autosuggest__container--open input {
        border-bottom-left-radius: 0px;
    }

    .react-autosuggest__container {
        ${StyledBranding} {
            display: none;
        }
        &--open ${StyledBranding} {
            display: block;
        }
    }
`;
