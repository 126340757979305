import { createStore } from 'redux';
import rootReducer from './index';

const devtoolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

const store = createStore(
    rootReducer,
    devtoolsExtension?.({
        trace: true,
    })
);

if (process.env.NODE_ENV === 'development') {
    (window as any).store = store;
}

export default store;
