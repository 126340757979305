import { IStateFilters } from '../components/Filters/types';
import { getBounds } from '../pages/Atm/MapFunctions';
import {
    AtmType,
    ATM_LIST_FETCHING_STRATEGY,
    ATM_SEARCH_STRING,
    DEPOSIT_ATM_WORKING_CONDITION,
    FILTER_CHIP_FUNCTIONALITY,
} from '../shared/constants/enum';
import log from '../shared/logger/Logger';
import {
    fetchAtmsByCity,
    fetchAtmsByGeo,
    fetchAtmsByRect,
} from '../store/actions/atms';
import store from '../store/reducers/redux';
import { AtmElement } from '../types/models';

export const getAtmIdentifier = (atm: AtmElement) => {
    return `${atm.BasicData.Address.CompanyName}.${atm.BasicData.Identifiers.YMID}`;
};

export const formatAddress = (address: any) => {
    if (!address) return '';
    return [
        [address.street, address.houseNo].filter(Boolean).join(' ').trim(),
        [address.zip, address.city].filter(Boolean).join(' ').trim(),
    ]
        .filter(Boolean)
        .join(', ');
};

/**
 * scall takes a function that might is not defined and checks if the function is available
 * for a call. If so the function gets called with the corresponding params. Otherwise an
 * error will be logged!
 * @param func the maybe undefined function to be called
 * @param args the arguments of the function to be called
 */
export const scall = (func?: any, ...args: any) => {
    return func
        ? func(...args)
        : log(`function ${func} not called because not defined!`);
};

/**
 * normalizeDistance gets a distance and checks if the distance is greater than 1 km (1000 meter). If
 * so the distance gets formatted into X.XX km format (2 point floating precision).
 *
 * @param distance distance as string (given by the yellow map api)
 */
export const normalizeDistance = (distance: string) => {
    const d = parseInt(distance);
    return d >= 1000 ? `${(d / 1000).toFixed(2)} km` : `${d} m`;
};

/**
 * As the yellow map api tends to return strings with leading or trailing commas, one
 * can use this function to replace them.
 * @param text a text retrieved from the yellow map api
 */
export const normalizeText = (text: string) =>
    text && text.trim().replace(',', '');

const smartphoneUnit = 4;

const tabletSUnit = 4.5;

const tabletLUnit = 4.5;

const desktopUnit = 5.5;

export enum Device {
    Smartphone,
    TabletS,
    TabletL,
    Desktop,
}

/**
 * utp converts units from the ING styleguide to pixels according to the just mentioned
 * styleguide. This makes it possible to use units in styled components.
 * @param device target device
 * @param units amount of units to use
 * @return pixels
 */
export function utp(device: Device, units: number) {
    switch (device) {
        case Device.Smartphone:
            return units * smartphoneUnit + 'px';
        case Device.TabletS:
            return units * tabletSUnit + 'px';
        case Device.TabletL:
            return units * tabletLUnit + 'px';
        case Device.Desktop:
            return units * desktopUnit + 'px';
        default:
            return 0 + 'px';
    }
}

export const chooseAtmFetch = async (latitude: number, longitude: number) => {
    const state = store.getState();
    const { lastAccessedFetchingStrategy } = state.atms;
    if (lastAccessedFetchingStrategy) {
        const {
            fetchType,
            value: { city, lat, long },
        } = lastAccessedFetchingStrategy;

        if (fetchType === ATM_LIST_FETCHING_STRATEGY.RECT) {
            store.dispatch(await fetchAtmsByRect({ rect: getBounds()! }));
        } else if (fetchType === ATM_LIST_FETCHING_STRATEGY.CITY && city) {
            store.dispatch(await fetchAtmsByCity({ city }));
        } else if (
            fetchType === ATM_LIST_FETCHING_STRATEGY.GEO &&
            long &&
            lat
        ) {
            store.dispatch(
                await fetchAtmsByGeo({
                    lat: latitude || lat,
                    long: longitude || long,
                    page: 1,
                    silent: false,
                    keepCurrentAddress: true,
                })
            );
        }
    }
};

export const fetchAtms = async (page = 1, silent = false) => {
    const state = store.getState();
    const { lastAccessedFetchingStrategy } = state.atms;

    if (lastAccessedFetchingStrategy) {
        const {
            fetchType,
            value: { city, lat, long, rect },
        } = lastAccessedFetchingStrategy;

        if (fetchType === ATM_LIST_FETCHING_STRATEGY.RECT && rect) {
            store.dispatch(await fetchAtmsByRect({ rect, page, silent }));
        } else if (fetchType === ATM_LIST_FETCHING_STRATEGY.CITY && city) {
            store.dispatch(await fetchAtmsByCity({ city, page, silent }));
        } else if (
            fetchType === ATM_LIST_FETCHING_STRATEGY.GEO &&
            long &&
            lat
        ) {
            store.dispatch(await fetchAtmsByGeo({ lat, long, page, silent }));
        }
    }
};

export const fetchMoreAtms = async () => {
    const state = store.getState();
    const { currentPage } = state.atms;
    fetchAtms(currentPage + 1, false);
};

export const getAtmType = (atm: AtmElement) => {
    for (const element of atm.BasicData.BranchListElements) {
        if (element.BranchCode.includes(ATM_SEARCH_STRING.CASH_PARTNERS)) {
            return AtmType.CASHBACK;
        }
        if (element.BranchCode.includes(ATM_SEARCH_STRING.CASHBACK_ATMS)) {
            return AtmType.PARTNER;
        }
        if (element.BranchCode.includes(ATM_SEARCH_STRING.VISA)) {
            return AtmType.VISA;
        }
        if (element.BranchCode.includes(ATM_SEARCH_STRING.VISA_CASHBACK)) {
            return AtmType.VISA_CASHBACK;
        }
        if (
            element.BranchCode === DEPOSIT_ATM_WORKING_CONDITION.AVAILABLE ||
            element.BranchCode === DEPOSIT_ATM_WORKING_CONDITION.UNKNOWN ||
            element.BranchCode === DEPOSIT_ATM_WORKING_CONDITION.NOT_WORKING
        ) {
            return AtmType.DEPOSIT;
        }
        if (element.BranchCode === ATM_SEARCH_STRING.ING) {
            return AtmType.ING;
        }
    }
    return AtmType.VISA;
};
/*
 * this will form applicable filter from selection of combination of filter chips
 * @param filters: all three filterchip with their selection state
 * @return filter string
 */
export function calculateFilters({
    atms,
    ingAtms,
    depositAtms,
    ingCashWithCode,
    cashBackPurchase,
}: IStateFilters) {
    //no filter
    if (
        !atms &&
        !ingAtms &&
        !depositAtms &&
        !ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.NO_FILTER;
    //one filter
    else if (
        atms &&
        !ingAtms &&
        !depositAtms &&
        !ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS;
    else if (
        !atms &&
        ingAtms &&
        !depositAtms &&
        !ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS;
    else if (
        !atms &&
        !ingAtms &&
        depositAtms &&
        !ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.WITH_DEPOSIT;
    else if (
        !atms &&
        !ingAtms &&
        !depositAtms &&
        ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_CASH_CODE;
    else if (
        !atms &&
        !ingAtms &&
        !depositAtms &&
        !ingCashWithCode &&
        cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.CASHBACK_PURCHASE;
    //two filters
    else if (
        atms &&
        ingAtms &&
        !depositAtms &&
        !ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS;
    else if (
        atms &&
        !ingAtms &&
        depositAtms &&
        !ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_WITH_DEPOSIT;
    else if (
        atms &&
        !ingAtms &&
        !depositAtms &&
        cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_CASHBACK_PURCHASE;
    else if (
        atms &&
        !ingAtms &&
        !depositAtms &&
        !cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_CASH_CODE;
    else if (
        !atms &&
        ingAtms &&
        depositAtms &&
        !cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS_x_WITH_DEPOSIT;
    else if (
        !atms &&
        ingAtms &&
        !depositAtms &&
        cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS_x_CASHBACK_PURCHASE;
    else if (
        !atms &&
        ingAtms &&
        !depositAtms &&
        !cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS_x_ING_CASH_CODE;
    else if (
        !atms &&
        !ingAtms &&
        depositAtms &&
        cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.WITH_DEPOSIT_x_CASHBACK_PURCHASE;
    else if (
        !atms &&
        !ingAtms &&
        depositAtms &&
        !cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.WITH_DEPOSIT_x_ING_CASH_CODE;
    else if (
        !atms &&
        !ingAtms &&
        !depositAtms &&
        cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.CASHBACK_PURCHASE_x_ING_CASH_CODE;
    //three filterss
    else if (
        atms &&
        ingAtms &&
        depositAtms &&
        !cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS_x_WITH_DEPOSIT;
    else if (
        atms &&
        ingAtms &&
        !depositAtms &&
        cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS_x_CASHBACK_PURCHASE;
    else if (
        atms &&
        ingAtms &&
        !depositAtms &&
        !cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS_x_ING_CASH_CODE;
    else if (
        atms &&
        !ingAtms &&
        depositAtms &&
        cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE;
    else if (
        atms &&
        !ingAtms &&
        depositAtms &&
        !cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_WITH_DEPOSIT_x_ING_CASH_CODE;
    else if (
        !atms &&
        ingAtms &&
        depositAtms &&
        cashBackPurchase &&
        !ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE;
    else if (
        !atms &&
        ingAtms &&
        depositAtms &&
        !cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS_x_WITH_DEPOSIT_x_ING_CASH_CODE;
    else if (
        !atms &&
        ingAtms &&
        !depositAtms &&
        cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS_x_CASHBACK_PURCHASE_x_ING_CASH_CODE;
    else if (
        !atms &&
        !ingAtms &&
        depositAtms &&
        cashBackPurchase &&
        ingCashWithCode
    )
        return FILTER_CHIP_FUNCTIONALITY.WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE;
    //four filter
    else if (
        atms &&
        ingAtms &&
        depositAtms &&
        ingCashWithCode &&
        !cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS_x_CASHBACK_PURCHASE_x_ING_CASH_CODE;
    else if (
        atms &&
        ingAtms &&
        depositAtms &&
        !ingCashWithCode &&
        cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE;
    else if (
        atms &&
        ingAtms &&
        !depositAtms &&
        ingCashWithCode &&
        cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS_x_CASHBACK_PURCHASE_x_ING_CASH_CODE;
    else if (
        atms &&
        !ingAtms &&
        depositAtms &&
        ingCashWithCode &&
        cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE;
    else if (
        !atms &&
        ingAtms &&
        depositAtms &&
        ingCashWithCode &&
        cashBackPurchase
    )
        return FILTER_CHIP_FUNCTIONALITY.ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE;
    //five filter
    else
        return FILTER_CHIP_FUNCTIONALITY.ATMS_x_ING_ATMS_x_WITH_DEPOSIT_x_CASHBACK_PURCHASE_x_ING_CASH_CODE;
}
