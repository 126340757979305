export const geoSettings = {
    enableHighAccuracy: true,
    maximumAge: 6000,
    timeout: 15000,
};

export const SPLASH_SCREEN_DURATION = 1000;

export const GET_FEEDBACK_OPTIONS_PATH = 'options';
export const POST_FEEDBACK_PATH = 'feedback';

export const YELLOW_MAP_MAX_RESULTS = 999;
export const YELLOW_MAP_AUTOSUGGEST_MIN_CHARS = 1;
export const YELLOW_MAP_AUTOSUGGEST_MAX_RESULTS = 10;
export const YELLOW_MAP_INITIAL_ZOOM = 16;
export const YELLOW_MAP_MIN_ZOOM = 9;
export const YELLOW_MAP_CLUSTERING_ZOOM = 20;
export const LIST_PAGE_SIZE = 15;

const prod = {
    url: {
        FEEDBACK_API_URL: `${process.env.PUBLIC_URL}/api/`,
    },
};

const dev = {
    url: {
        FEEDBACK_API_URL: 'http://localhost:8080/',
    },
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
