import {
    baseFontColorInv,
    ingBlack15,
    ingBlack34,
    ingBlack80,
    ingIndigo,
} from '../../shared/constants/theme';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    min-height: 80px;
    .chips {
        height: 28px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 4px 12px;
        color: ${ingBlack80};
        background: #fafafa;
        border: 1px solid#f0f0f0;
        // background: rgba(82, 81, 153, 0.12);
        // border: 1px solid #525199;
        svg path {
            fill: ${ingIndigo};
        }
        &:hover {
            background: ${ingBlack15};
            border-color: ${ingBlack34};
        }
        &:focus {
            border: 1px solid #569bd1;
            box-shadow: 0px 0px 4px #569bd1;
        }
        &:active {
            color: ${ingIndigo};
        }
        &:focus-visible {
            outline: none;
        }
    }
    .active-chip {
        border-color: ${ingIndigo};
        color: ${ingIndigo};
        background: #e7e5f0;
        &:hover {
            background: ${ingIndigo};
            border: 1px solid ${ingIndigo};
            color: ${baseFontColorInv};
            svg path {
                fill: white;
            }
        }
        &:focus {
            border: 1px solid #569bd1;
            box-shadow: 0px 0px 4px #569bd1;
        }
        &:active {
            background: #9898c3;
            border: 1px solid ${ingIndigo};
            color: ${baseFontColorInv};
        }
    }
`;
