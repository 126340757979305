// This file is for default values
import { FILTER, LANGUAGES } from './enum';

export const DEFAULT_LANGUAGE = LANGUAGES.GERMAN;

export const ING_RIGHT_ARROW_UNICODE = '\u203A';

export const YELLOW_MAP_API_ROOT = process.env.REACT_APP_YELLOW_MAP_API_ROOT;
export const YELLOW_MAP_BASE_URL = `${YELLOW_MAP_API_ROOT}/v2/addresssearch`;
export const YELLOW_MAP_RECOMMENDATION_RADIUS = 15000;

export const DEFAULT_QUERY_ADDRESS = 'Frankfurt, ING';
export const DEFAULT_PLACEHOLDER_VALUE = 'Postleitzahl / Ort';

export const SMARTPHONE_UPPER_BOUND = 767;
export const TABLET_S_LOWER_BOUND = 768;
export const TABLET_S_UPPER_BOUND = 959;
export const TABLET_L_LOWER_BOUND = 960;
export const TABLET_L_UPPER_BOUND = 1200;
export const DESKTOP_LOWER_BOUND = 1201;
export const DESKTOP_UPPER_BOUND = 1440;
export const ING_FRANKFURT_LATITUDE = 50.114263;
export const ING_FRANKFURT_LONGITUDE = 8.648883;
export const ROUTE_TO_YELLOW_MAP = 'https://www.route.de/?';

export const DEFAULT_FILTER = `${FILTER.ING_ATMS}|${FILTER.ING_RECYCLER_ATMS}|${FILTER.VISA_ATMS}|${FILTER.CASHBACK_ATMS}|${FILTER.CASH_PARTNERS}|${FILTER.VISA_CASHBACK}`;
export const DEFAULT_FILTER_DEPOSIT = `${FILTER.ING_RECYCLER_ATMS}|${FILTER.CASH_PARTNERS}`;
