import {
    SET_LATITUDE_LONGITUDE,
    SET_USER_PERMISSION,
} from '../actions/actionTypes/index';

interface LocationState {
    userPermissionError?: any;
    userLocation?: GeolocationPosition;
}

export const initialStateLocation: LocationState = {
    userLocation: undefined,
    userPermissionError: {},
};

export default function location(state = initialStateLocation, action: any) {
    switch (action.type) {
        case SET_LATITUDE_LONGITUDE: {
            const newState = { ...state };
            newState.userLocation = action.payload;
            return newState;
        }
        case SET_USER_PERMISSION: {
            const newState = { ...state };
            newState.userPermissionError = action.payload;
            return newState;
        }
        default:
            return state;
    }
}
