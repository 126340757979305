import React from 'react';
import { StyledBranding } from './Branding.styled';

export function Branding() {
    return (
        <StyledBranding>
            <a
                href="https://www.smartmaps.net"
                target="_blank"
                rel="noreferrer"
            >
                powered by{' '}
                <svg
                    viewBox="0 0 467.46 107.9"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m74.58 9.49c-3.07-4.99-18.02-4.24-32.55-0.58 2.57 1.41 4.9 3.4 6.81 5.81 8.06 10.05 6.48 24.83-3.65 32.89-7.22 5.81-16.86 6.56-24.66 2.91 9.55 15.03 23.67 29.98 27.65 29.23 7.22-1.42 32.46-60.46 26.4-70.26"
                        fill="#18355B"
                    ></path>
                    <path
                        d="m43.77 18.37c-6.06-7.22-16.86-8.22-24.08-2.16s-8.22 16.77-2.16 24.08c6.06 7.22 16.86 8.22 24.08 2.16s8.14-16.85 2.16-24.08zm-3.24 14.62c-3.24 2.66-8.06 2.24-10.71-1-2.66-3.24-2.24-8.06 1-10.71 3.24-2.66 8.06-2.24 10.71 1 2.65 3.15 2.24 7.97-1 10.71z"
                        fill="#F6B80C"
                    ></path>
                    <path
                        d="m128.25 53.41c0 2.65-0.49 4.97-1.48 6.97s-2.32 3.67-4.01 5c-1.69 1.34-3.66 2.34-5.92 3.02-2.26 0.67-4.67 1.01-7.24 1.01-1.74 0-3.35-0.14-4.85-0.43-1.49-0.29-2.81-0.63-3.95-1.03s-2.1-0.82-2.86-1.26c-0.77-0.44-1.32-0.83-1.65-1.17-0.34-0.34-0.58-0.82-0.72-1.46s-0.21-1.55-0.21-2.74c0-0.8 0.03-1.48 0.08-2.02s0.14-0.99 0.25-1.32c0.12-0.34 0.27-0.58 0.47-0.72 0.19-0.14 0.42-0.21 0.68-0.21 0.36 0 0.88 0.21 1.54 0.64s1.51 0.9 2.55 1.42 2.28 0.99 3.72 1.42 3.11 0.64 5 0.64c1.25 0 2.36-0.15 3.35-0.45s1.82-0.72 2.51-1.27c0.69-0.54 1.21-1.22 1.58-2.02 0.36-0.8 0.54-1.7 0.54-2.69 0-1.14-0.31-2.12-0.93-2.94s-1.43-1.54-2.43-2.18-2.14-1.23-3.41-1.79-2.58-1.15-3.93-1.77-2.66-1.33-3.93-2.12-2.41-1.74-3.41-2.84-1.81-2.41-2.43-3.91-0.93-3.31-0.93-5.41c0-2.41 0.45-4.53 1.34-6.36 0.9-1.83 2.1-3.35 3.62-4.55 1.52-1.21 3.31-2.11 5.37-2.71s4.25-0.9 6.56-0.9c1.19 0 2.39 0.09 3.58 0.27s2.31 0.43 3.35 0.74 1.96 0.66 2.76 1.05 1.34 0.71 1.6 0.97 0.43 0.48 0.53 0.66c0.09 0.18 0.17 0.42 0.23 0.72s0.11 0.68 0.14 1.13 0.04 1.02 0.04 1.69c0 0.75-0.02 1.39-0.06 1.91s-0.1 0.95-0.2 1.28c-0.09 0.34-0.22 0.58-0.39 0.74s-0.4 0.23-0.68 0.23c-0.29 0-0.74-0.18-1.36-0.54s-1.39-0.76-2.3-1.19-1.96-0.82-3.15-1.17-2.51-0.53-3.93-0.53c-1.12 0-2.09 0.14-2.92 0.41s-1.53 0.65-2.08 1.13c-0.56 0.48-0.97 1.06-1.25 1.73-0.27 0.68-0.41 1.39-0.41 2.14 0 1.12 0.3 2.08 0.91 2.9s1.43 1.54 2.45 2.18 2.19 1.23 3.48 1.79c1.3 0.56 2.62 1.15 3.97 1.77s2.67 1.33 3.97 2.12 2.45 1.74 3.46 2.84 1.83 2.4 2.45 3.89c0.63 1.51 0.94 3.26 0.94 5.29z"
                        fill="#F6B80C"
                    ></path>
                    <path
                        d="m189.13 67.19c0 0.26-0.08 0.49-0.23 0.68-0.16 0.19-0.42 0.36-0.78 0.49s-0.86 0.23-1.48 0.29-1.41 0.1-2.37 0.1c-0.99 0-1.79-0.03-2.41-0.1s-1.12-0.16-1.5-0.29-0.64-0.29-0.78-0.49c-0.14-0.19-0.21-0.42-0.21-0.68v-20.59c0-1.17-0.11-2.23-0.33-3.19s-0.56-1.78-1.01-2.47-1.02-1.22-1.71-1.6-1.51-0.56-2.47-0.56c-1.17 0-2.35 0.45-3.54 1.36s-2.49 2.23-3.89 3.97v23.08c0 0.26-0.08 0.49-0.23 0.68-0.16 0.19-0.42 0.36-0.8 0.49s-0.88 0.23-1.5 0.29-1.4 0.1-2.34 0.1c-0.96 0-1.75-0.03-2.37-0.1s-1.12-0.16-1.5-0.29-0.64-0.29-0.8-0.49c-0.16-0.19-0.23-0.42-0.23-0.68v-20.59c0-1.17-0.11-2.23-0.33-3.19s-0.55-1.78-0.99-2.47-1.01-1.22-1.71-1.6-1.52-0.56-2.45-0.56c-1.19 0-2.39 0.45-3.58 1.36s-2.48 2.23-3.85 3.97v23.08c0 0.26-0.08 0.49-0.23 0.68-0.16 0.19-0.42 0.36-0.8 0.49s-0.88 0.23-1.5 0.29-1.41 0.1-2.37 0.1-1.75-0.03-2.37-0.1-1.12-0.16-1.5-0.29-0.64-0.29-0.8-0.49c-0.16-0.19-0.23-0.42-0.23-0.68v-34.72c0-0.26 0.06-0.49 0.2-0.68 0.13-0.19 0.36-0.36 0.7-0.49s0.77-0.23 1.3-0.29 1.2-0.1 2-0.1c0.83 0 1.52 0.03 2.06 0.1s0.97 0.16 1.27 0.29 0.51 0.29 0.64 0.49c0.13 0.19 0.19 0.42 0.19 0.68v4.01c1.92-2.08 3.83-3.63 5.74-4.67s3.9-1.56 5.98-1.56c1.43 0 2.71 0.15 3.85 0.45s2.16 0.73 3.06 1.28c0.9 0.56 1.67 1.23 2.32 2 0.65 0.78 1.19 1.65 1.63 2.61 1.04-1.12 2.06-2.08 3.06-2.88s1.99-1.46 2.96-1.97 1.95-0.88 2.92-1.13 1.97-0.37 2.98-0.37c2.31 0 4.26 0.39 5.84 1.17s2.86 1.84 3.83 3.17c0.97 1.34 1.67 2.9 2.08 4.69 0.42 1.79 0.62 3.69 0.62 5.68v22.24z"
                        fill="#F6B80C"
                    ></path>
                    <path
                        d="m226.73 67.31c0 0.36-0.13 0.65-0.39 0.86s-0.67 0.36-1.23 0.45-1.38 0.14-2.47 0.14c-1.17 0-2.02-0.05-2.55-0.14s-0.91-0.24-1.13-0.45-0.33-0.49-0.33-0.86v-2.76c-1.43 1.53-3.06 2.73-4.89 3.58-1.83 0.86-3.86 1.28-6.09 1.28-1.84 0-3.54-0.24-5.08-0.72s-2.88-1.19-4.01-2.14-2-2.12-2.63-3.52c-0.62-1.4-0.93-3.04-0.93-4.9 0-2.02 0.4-3.78 1.19-5.27s1.97-2.73 3.54-3.7 3.52-1.69 5.86-2.16 5.05-0.7 8.14-0.7h3.39v-2.1c0-1.09-0.11-2.04-0.33-2.86s-0.58-1.5-1.09-2.04c-0.51-0.55-1.18-0.95-2.02-1.21s-1.89-0.39-3.13-0.39c-1.63 0-3.09 0.18-4.38 0.54-1.28 0.36-2.42 0.77-3.41 1.21s-1.81 0.84-2.47 1.21c-0.66 0.36-1.2 0.55-1.62 0.55-0.29 0-0.54-0.09-0.76-0.27s-0.4-0.44-0.54-0.78-0.25-0.75-0.33-1.25c-0.08-0.49-0.12-1.04-0.12-1.63 0-0.8 0.06-1.44 0.19-1.91s0.38-0.89 0.74-1.27 1-0.79 1.91-1.25c0.91-0.45 1.97-0.88 3.19-1.26 1.22-0.39 2.55-0.71 3.99-0.95 1.44-0.25 2.93-0.37 4.46-0.37 2.73 0 5.05 0.27 6.99 0.8 1.93 0.53 3.52 1.35 4.77 2.45s2.15 2.52 2.72 4.26 0.86 3.8 0.86 6.19v23.34zm-9.61-14.87h-3.74c-1.58 0-2.93 0.12-4.05 0.35s-2.02 0.58-2.73 1.05c-0.7 0.47-1.21 1.03-1.54 1.69-0.32 0.66-0.49 1.42-0.49 2.28 0 1.45 0.46 2.6 1.38 3.43s2.2 1.25 3.83 1.25c1.38 0 2.64-0.35 3.8-1.05 1.15-0.7 2.33-1.73 3.52-3.08v-5.92z"
                        fill="#F6B80C"
                    ></path>
                    <path
                        d="m257.22 35.7c0 0.93-0.03 1.7-0.08 2.3s-0.13 1.06-0.23 1.4-0.24 0.57-0.41 0.7-0.38 0.19-0.64 0.19c-0.21 0-0.44-0.04-0.7-0.14-0.26-0.09-0.55-0.19-0.88-0.29-0.32-0.1-0.68-0.2-1.07-0.29s-0.82-0.14-1.28-0.14c-0.54 0-1.09 0.11-1.63 0.33s-1.11 0.56-1.69 1.03-1.19 1.09-1.83 1.87-1.32 1.74-2.04 2.88v21.64c0 0.26-0.08 0.49-0.23 0.68-0.16 0.19-0.42 0.36-0.8 0.49s-0.88 0.23-1.5 0.29-1.41 0.1-2.37 0.1-1.75-0.03-2.37-0.1-1.12-0.16-1.5-0.29-0.64-0.29-0.8-0.49c-0.16-0.19-0.23-0.42-0.23-0.68v-34.71c0-0.26 0.06-0.49 0.2-0.68 0.13-0.19 0.36-0.36 0.7-0.49s0.77-0.23 1.3-0.29 1.2-0.1 2-0.1c0.83 0 1.52 0.03 2.06 0.1s0.97 0.16 1.27 0.29 0.51 0.29 0.64 0.49c0.13 0.19 0.19 0.42 0.19 0.68v4.32c0.91-1.3 1.76-2.37 2.57-3.21 0.8-0.84 1.57-1.51 2.3-2s1.45-0.84 2.18-1.03 1.45-0.29 2.18-0.29c0.34 0 0.7 0.02 1.09 0.06s0.79 0.1 1.21 0.19c0.41 0.09 0.78 0.19 1.09 0.31s0.54 0.24 0.68 0.37 0.25 0.27 0.31 0.43 0.12 0.37 0.17 0.64 0.09 0.68 0.12 1.23c0 0.53 0.02 1.27 0.02 2.21z"
                        fill="#F6B80C"
                    ></path>
                    <path
                        d="m285.8 63.76c0 1.14-0.07 2.02-0.21 2.63s-0.33 1.04-0.56 1.28c-0.23 0.25-0.58 0.47-1.03 0.66s-0.99 0.36-1.6 0.51c-0.61 0.14-1.27 0.25-1.98 0.33s-1.43 0.12-2.16 0.12c-1.95 0-3.63-0.25-5.06-0.74s-2.61-1.25-3.54-2.28c-0.93-1.02-1.62-2.32-2.06-3.87-0.44-1.56-0.66-3.4-0.66-5.53v-17.79h-4.17c-0.49 0-0.87-0.3-1.13-0.91s-0.39-1.63-0.39-3.06c0-0.75 0.03-1.39 0.1-1.91 0.06-0.52 0.16-0.93 0.29-1.23s0.29-0.51 0.49-0.64 0.42-0.19 0.68-0.19h4.13v-7.74c0-0.26 0.07-0.49 0.21-0.7s0.4-0.38 0.78-0.53c0.38-0.14 0.88-0.25 1.52-0.31s1.42-0.1 2.36-0.1c0.96 0 1.76 0.03 2.39 0.1 0.64 0.06 1.13 0.17 1.5 0.31 0.36 0.14 0.62 0.32 0.78 0.53s0.23 0.44 0.23 0.7v7.75h7.55c0.26 0 0.49 0.07 0.68 0.19 0.19 0.13 0.36 0.34 0.49 0.64s0.23 0.71 0.29 1.23 0.1 1.15 0.1 1.91c0 1.43-0.13 2.45-0.39 3.06s-0.64 0.91-1.13 0.91h-7.59v16.31c0 1.89 0.3 3.32 0.9 4.26 0.6 0.95 1.66 1.42 3.19 1.42 0.52 0 0.99-0.04 1.4-0.14 0.42-0.09 0.78-0.19 1.11-0.31 0.32-0.12 0.6-0.22 0.82-0.31s0.42-0.14 0.6-0.14c0.16 0 0.3 0.05 0.45 0.14 0.14 0.09 0.25 0.27 0.33 0.54s0.15 0.64 0.21 1.11c0.04 0.47 0.08 1.07 0.08 1.79z"
                        fill="#F6B80C"
                    ></path>
                    <path
                        d="m350.57 67.11c0 0.26-0.07 0.49-0.21 0.7s-0.4 0.38-0.78 0.51-0.88 0.23-1.5 0.31-1.41 0.12-2.37 0.12c-0.93 0-1.71-0.04-2.34-0.12-0.62-0.08-1.12-0.18-1.48-0.31s-0.62-0.3-0.78-0.51-0.23-0.44-0.23-0.7v-40.99h-0.08l-14.6 40.95c-0.1 0.34-0.27 0.62-0.51 0.84-0.23 0.22-0.55 0.4-0.95 0.53s-0.91 0.21-1.54 0.25-1.38 0.06-2.26 0.06-1.64-0.03-2.26-0.1-1.14-0.17-1.54-0.31-0.72-0.32-0.95-0.53-0.39-0.45-0.47-0.74l-14.09-40.95h-0.08v40.99c0 0.26-0.07 0.49-0.21 0.7s-0.41 0.38-0.8 0.51-0.89 0.23-1.5 0.31-1.39 0.12-2.36 0.12c-0.93 0-1.71-0.04-2.34-0.12-0.62-0.08-1.12-0.18-1.5-0.31s-0.64-0.3-0.78-0.51-0.21-0.44-0.21-0.7v-44.88c0-1.32 0.35-2.34 1.05-3.04s1.63-1.05 2.8-1.05h6.7c1.19 0 2.22 0.1 3.08 0.29s1.6 0.52 2.22 0.97 1.14 1.05 1.56 1.79 0.78 1.65 1.09 2.74l10.9 30.01h0.16l11.29-29.94c0.34-1.09 0.71-2.01 1.11-2.76s0.87-1.36 1.4-1.83 1.16-0.8 1.89-0.99 1.57-0.29 2.53-0.29h6.89c0.7 0 1.3 0.09 1.81 0.27s0.92 0.45 1.25 0.8c0.32 0.35 0.57 0.78 0.74 1.28 0.17 0.51 0.25 1.08 0.25 1.73v44.9z"
                        fill="#18355B"
                    ></path>
                    <path
                        d="m388.96 67.31c0 0.36-0.13 0.65-0.39 0.86s-0.67 0.36-1.23 0.45-1.38 0.14-2.47 0.14c-1.17 0-2.02-0.05-2.55-0.14s-0.91-0.24-1.13-0.45-0.33-0.49-0.33-0.86v-2.76c-1.43 1.53-3.06 2.73-4.89 3.58-1.83 0.86-3.86 1.28-6.09 1.28-1.84 0-3.54-0.24-5.08-0.72s-2.88-1.19-4.01-2.14-2-2.12-2.63-3.52c-0.62-1.4-0.93-3.04-0.93-4.9 0-2.02 0.4-3.78 1.19-5.27s1.97-2.73 3.54-3.7 3.52-1.69 5.86-2.16 5.05-0.7 8.14-0.7h3.39v-2.1c0-1.09-0.11-2.04-0.33-2.86s-0.58-1.5-1.09-2.04c-0.51-0.55-1.18-0.95-2.02-1.21s-1.89-0.39-3.13-0.39c-1.63 0-3.09 0.18-4.38 0.54-1.28 0.36-2.42 0.77-3.41 1.21s-1.81 0.84-2.47 1.21c-0.66 0.36-1.2 0.55-1.62 0.55-0.29 0-0.54-0.09-0.76-0.27s-0.4-0.44-0.54-0.78-0.25-0.75-0.33-1.25c-0.08-0.49-0.12-1.04-0.12-1.63 0-0.8 0.06-1.44 0.19-1.91s0.38-0.89 0.74-1.27 1-0.79 1.91-1.25c0.91-0.45 1.97-0.88 3.19-1.26 1.22-0.39 2.55-0.71 3.99-0.95 1.44-0.25 2.93-0.37 4.46-0.37 2.73 0 5.05 0.27 6.99 0.8 1.93 0.53 3.52 1.35 4.77 2.45s2.15 2.52 2.72 4.26 0.86 3.8 0.86 6.19v23.34zm-9.62-14.87h-3.74c-1.58 0-2.93 0.12-4.05 0.35s-2.02 0.58-2.73 1.05c-0.7 0.47-1.21 1.03-1.54 1.69-0.32 0.66-0.49 1.42-0.49 2.28 0 1.45 0.46 2.6 1.38 3.43s2.2 1.25 3.83 1.25c1.38 0 2.64-0.35 3.8-1.05 1.15-0.7 2.33-1.73 3.52-3.08v-5.92z"
                        fill="#18355B"
                    ></path>
                    <path
                        d="m432.09 49.32c0 3.09-0.33 5.87-0.99 8.35s-1.63 4.59-2.9 6.33-2.86 3.08-4.75 4.01-4.06 1.4-6.5 1.4c-1.01 0-1.94-0.1-2.78-0.29s-1.66-0.49-2.45-0.9c-0.79-0.4-1.58-0.9-2.36-1.5s-1.58-1.3-2.41-2.1v16.35c0 0.26-0.08 0.49-0.23 0.7-0.16 0.21-0.42 0.38-0.8 0.53-0.38 0.14-0.88 0.25-1.5 0.33s-1.41 0.12-2.37 0.12-1.75-0.04-2.37-0.12-1.12-0.19-1.5-0.33-0.64-0.32-0.8-0.53-0.23-0.44-0.23-0.7v-48.5c0-0.26 0.06-0.49 0.2-0.68 0.13-0.19 0.36-0.36 0.68-0.49s0.75-0.23 1.28-0.29 1.2-0.1 2-0.1c0.78 0 1.43 0.03 1.97 0.1 0.53 0.07 0.96 0.16 1.28 0.29s0.55 0.29 0.68 0.49c0.13 0.19 0.19 0.42 0.19 0.68v4.09c1.01-1.04 2-1.95 2.98-2.74 0.97-0.79 1.97-1.45 2.98-1.98s2.06-0.93 3.13-1.19c1.08-0.26 2.22-0.39 3.44-0.39 2.54 0 4.71 0.5 6.5 1.5s3.25 2.37 4.38 4.11 1.95 3.76 2.47 6.07c0.52 2.3 0.78 4.76 0.78 7.38zm-10.2 0.7c0-1.48-0.11-2.9-0.33-4.26s-0.6-2.58-1.13-3.64-1.23-1.91-2.1-2.55-1.95-0.95-3.25-0.95c-0.65 0-1.29 0.1-1.93 0.29s-1.28 0.51-1.95 0.95c-0.66 0.44-1.34 1.01-2.04 1.71s-1.44 1.57-2.22 2.61v11.44c1.38 1.74 2.69 3.08 3.93 4.03 1.25 0.95 2.54 1.42 3.89 1.42 1.27 0 2.36-0.32 3.25-0.97 0.9-0.65 1.63-1.5 2.2-2.55s0.99-2.24 1.26-3.56c0.28-1.32 0.42-2.64 0.42-3.97z"
                        fill="#18355B"
                    ></path>
                    <path
                        d="m462.38 57.38c0 1.97-0.37 3.71-1.11 5.22s-1.78 2.76-3.13 3.78c-1.35 1.01-2.95 1.77-4.79 2.28s-3.84 0.76-5.99 0.76c-1.3 0-2.54-0.1-3.72-0.29s-2.24-0.44-3.17-0.74-1.71-0.6-2.34-0.91c-0.62-0.31-1.08-0.6-1.36-0.88-0.29-0.27-0.51-0.7-0.66-1.28-0.16-0.58-0.23-1.45-0.23-2.59 0-0.75 0.03-1.36 0.08-1.81s0.13-0.82 0.23-1.09 0.23-0.45 0.39-0.55c0.16-0.09 0.35-0.14 0.58-0.14 0.28 0 0.71 0.16 1.26 0.49 0.56 0.32 1.25 0.68 2.08 1.07s1.8 0.75 2.9 1.09 2.36 0.51 3.76 0.51c0.88 0 1.67-0.09 2.36-0.27s1.28-0.44 1.79-0.78 0.89-0.77 1.15-1.28c0.26-0.52 0.39-1.12 0.39-1.79 0-0.78-0.24-1.45-0.72-2-0.48-0.56-1.12-1.04-1.91-1.46-0.79-0.41-1.69-0.8-2.69-1.17-1-0.36-2.02-0.77-3.08-1.21-1.05-0.44-2.08-0.95-3.07-1.52-1-0.57-1.89-1.27-2.69-2.1-0.79-0.83-1.43-1.83-1.91-3s-0.72-2.57-0.72-4.2c0-1.66 0.32-3.19 0.97-4.57 0.65-1.39 1.58-2.58 2.8-3.56 1.22-0.99 2.71-1.75 4.46-2.3 1.75-0.54 3.72-0.82 5.9-0.82 1.09 0 2.15 0.08 3.17 0.23 1.02 0.16 1.95 0.35 2.78 0.58s1.53 0.49 2.1 0.76 0.98 0.51 1.23 0.7 0.42 0.39 0.53 0.58c0.1 0.19 0.18 0.43 0.23 0.7s0.1 0.61 0.14 1.01 0.06 0.9 0.06 1.5c0 0.7-0.02 1.27-0.06 1.71s-0.1 0.79-0.19 1.05-0.21 0.43-0.37 0.53c-0.16 0.09-0.34 0.14-0.54 0.14-0.23 0-0.6-0.14-1.09-0.41s-1.11-0.56-1.85-0.88c-0.74-0.31-1.6-0.6-2.57-0.88-0.97-0.27-2.08-0.41-3.33-0.41-0.88 0-1.65 0.09-2.3 0.27s-1.18 0.44-1.6 0.78-0.73 0.73-0.93 1.19c-0.21 0.45-0.31 0.94-0.31 1.46 0 0.8 0.25 1.48 0.74 2.02 0.49 0.55 1.14 1.03 1.95 1.44 0.8 0.42 1.72 0.8 2.74 1.17 1.02 0.36 2.06 0.76 3.11 1.19s2.09 0.93 3.11 1.5 1.94 1.27 2.74 2.1 1.45 1.82 1.95 2.98c0.5 1.15 0.75 2.52 0.75 4.1z"
                        fill="#18355B"
                    ></path>
                    <path
                        d="m33.58 73.73c-0.75-0.8-1.51-1.63-2.27-2.47-7.99 0.5-14.31 1.21-17.11 1.94-1.77 0.47-3.51 1.05-5.06 1.77l31.58 5.44c-1.93-1.5-4.27-3.64-7.14-6.68z"
                        fill="#f6b80c"
                    ></path>
                    <path
                        d="m12.68 94.12c9.57 9.03 17.05 10.91 26.19 7.13 7.58-3.13 18.84-8.25 27.96-12.75l-32.74-5.64z"
                        fill="#f6b80c"
                    ></path>
                    <path
                        d="m51.64 82.3 3.35 0.58 22.01-11.59c-5.24-0.42-11-0.65-16.86-0.73-3.89 7.12-6.64 10.29-8.5 11.74z"
                        fill="#f6b80c"
                    ></path>
                    <path
                        d="m72.13 85.83c2.77-1.43 5.14-2.73 6.87-3.77 6.3-3.82 13.06-8.1 4.14-10l-22.43 11.8z"
                        fill="#f6b80c"
                    ></path>
                    <path
                        d="m4.89 77.82c-2.1 2.25-2.42 5.19 0.81 8.92 1.51 1.74 2.95 3.32 4.35 4.76l18.32-9.64z"
                        fill="#f6b80c"
                    ></path>
                </svg>
            </a>
        </StyledBranding>
    );
}
