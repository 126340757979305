import React from 'react';

import {
    ING_FRANKFURT_LATITUDE,
    ING_FRANKFURT_LONGITUDE,
    YELLOW_MAP_API_ROOT,
} from '../../../shared/constants/default';

import { getBounds } from '../MapFunctions';

import { useDispatch } from 'react-redux';
import { fetchAtmsByRect } from '../../../store/actions/atms';

import {
    YELLOW_MAP_INITIAL_ZOOM,
    YELLOW_MAP_MIN_ZOOM,
} from '../../../shared/constants/configs';

import {
    getGlobalMap,
    getGlobalMarkers,
    setGlobalMap,
    setGlobalMarkers,
} from '../mapInstance';
import { MarkerClusterGroup } from '../../../types/models';

function loadYellowmap() {
    const apiKey = process.env.REACT_APP_API_KEY;
    const securityId = process.env.REACT_APP_SECURITY_ID;
    const partnerId = process.env.REACT_APP_PARTNER;

    const ymScript = document.createElement('script');
    const ymUrlParams = [
        'libraries=enterprise',
        `apiKey=${apiKey}`,
        `securityID=${encodeURIComponent(securityId)}`,
        `Partner=${partnerId}`,
    ].join('&');

    const ymUrl = `${YELLOW_MAP_API_ROOT}/api/loader?${ymUrlParams}`;

    ymScript.setAttribute('src', ymUrl);
    document.body.appendChild(ymScript);
    return ymScript;
}

export function useYellowMap(element: HTMLDivElement | null) {
    const dispatch = useDispatch();
    const [ymReady, setYmReady] = React.useState(false);

    const isMounted = React.useRef<boolean>(false);

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    React.useEffect(() => {
        if (!element) {
            return;
        }

        async function populateBounds() {
            const rect = getBounds();
            if (rect) {
                const action = await fetchAtmsByRect({ rect, silent: true });
                dispatch(action);
            }
        }

        function handleZoomEnd() {
            // when performing a new search, we want to reset the zoom
            // however, we do not want to perform the search twice
            // to avoid that, we use a global ignoreZoomChange flag
            // that is being set and unset by SearchBox before and after searching
            if (!window.ignoreZoomChange) {
                populateBounds();
            }
        }
        function handleDragEnd() {
            populateBounds();
        }

        function createMap() {
            if (!isMounted.current) {
                return;
            }

            const map = window.ym.map(element, {
                center: window.ym.latLng(
                    ING_FRANKFURT_LATITUDE,
                    ING_FRANKFURT_LONGITUDE
                ),
                zoom: YELLOW_MAP_INITIAL_ZOOM,
                minZoom: YELLOW_MAP_MIN_ZOOM,
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                gestureHandling:
                    process.env.NODE_ENV === 'production'
                        ? 'cooperative'
                        : undefined,
            });
            Object.assign(window, { map });

            setGlobalMap(map);

            map.original.addEventListener('dragend', handleDragEnd);
            map.original.addEventListener('zoomend', handleZoomEnd);

            const markers = window.ym.markerClusterGroup({
                showCoverageOnHover: false,
                maxClusterRadius: 50,
                iconCreateFunction: function (cluster: MarkerClusterGroup) {
                    const children = cluster.getAllChildMarkers();

                    const numResults = Intl.NumberFormat('de-DE', {
                        maximumSignificantDigits: 3,
                    }).format(children.length);

                    return window.ym.divIcon({
                        className: 'ing-cluster',
                        html: `<b>${numResults}</b>`,
                        iconSize: window.ym.point(56, 56),
                    });
                },
            });

            map.addLayer(markers);
            setGlobalMarkers(markers);

            setYmReady(true);
        }

        function destroyMap() {
            const map = getGlobalMap();
            const markers = getGlobalMarkers();
            if (!map) return;

            setGlobalMap(null);
            setGlobalMarkers(null);
            if (map) {
                map.removeLayer(markers);
                map.original.removeEventListener('dragend', handleDragEnd);
                map.original.removeEventListener('zoomend', handleZoomEnd);
                map.remove();
                markers?.remove();
            }
        }

        const ymScript = loadYellowmap();
        ymScript.onload = () => window.ym.ready(createMap);
        return () => {
            destroyMap();
            Object.assign(window, { map: null });
            Object.assign(window, { map: null });
        };
    }, [dispatch, element]);

    return {
        ymReady,
    };
}
