import React from 'react';
import cx from 'classnames';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import BackgroundImg from '../../shared/img/map_bg.jpg';
import NoMapAtmSearch from '../Atm/NoMapAtmSearch';
import MapAtmSearch from '../Atm/Index';
import { IStore } from '../../store/reducers/store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import LoadingMessage from '../../components/SplashScreen/LoadingMessage';

import { setMapMode } from '../../store/actions/common';
import { tabletSLowerBound } from '../../shared/constants/theme';
import { Image } from '../../components/Image';
import { MapMode } from '../../types/models';
import { Disclaimer } from '../../components/Disclaimer/Disclaimer';

const MainDiv = styled.div`
    height: 100%;
    background-size: cover;

    a:not([type='button']) {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        word-break: break-word;
        color: ${(props) => props.theme.ingLinkColor};
        text-decoration: underline;
        -webkit-text-underline-position: under;
        -ms-text-underline-position: below;
        text-underline-position: under;
        text-decoration-thickness: 1px;
        outline: 0;
        background-repeat: no-repeat;
        background-clip: padding-box;
        max-width: 100%;
        cursor: pointer;
        text-overflow: ellipsis;
        transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
            background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        .notouch & {
            &:hover {
                color: ${(props) => props.theme.ingIndigo};
                background-color: ${(props) => props.theme.ingLinkHoverBgColor};
            }
        }
    }
`;

const BackgroundImage = styled(Image)`
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
`;

const StyledPopup = styled(Popup)`
    &-content {
        border-radius: 6px;
        border: none !important;
        width: 85% !important;
        padding: 0 !important;

        @media screen and (max-width: 320px) {
            width: 95% !important;
        }
        @media screen and (min-width: ${tabletSLowerBound}) {
            width: 60% !important;
            max-width: 550px;
        }

        h2,
        p {
            margin: 0;
        }
    }

    &-overlay {
        z-index: ${1 << 20} !important;
    }
`;

const MapContainer = styled.div`
    height: 100%;
    &.showSplashScreen {
        display: none;
    }
`;

type IndexProps = {
    mode?: MapMode;
    showSplashScreen?: boolean;
    setMapMode?: (mode: number) => void;
};

const Index = ({ showSplashScreen, setMapMode, mode }: IndexProps) => {
    const changeToSplashScreen = React.useCallback(() => {
        setMapMode?.(MapMode.Disclaimer);
    }, [setMapMode]);

    const handleDisclaimer = React.useCallback(
        (mode: MapMode) => {
            setMapMode?.(mode);
        },
        [setMapMode]
    );

    return (
        <MainDiv>
            {mode === MapMode.Disclaimer && (
                <>
                    <BackgroundImage
                        src={BackgroundImg}
                        fade
                        duration={'0.5s'}
                    />
                    <StyledPopup open closeOnDocumentClick={false}>
                        <Disclaimer onProceed={handleDisclaimer} />
                    </StyledPopup>
                </>
            )}
            {mode === MapMode.Maps && (
                <>
                    {showSplashScreen && <LoadingMessage />}
                    <MapContainer className={cx({ showSplashScreen })}>
                        <MapAtmSearch data-testid="map-search" />
                    </MapContainer>
                </>
            )}
            {mode === MapMode.NoMaps && (
                <NoMapAtmSearch
                    data-testid="non-map-search"
                    onClose={changeToSplashScreen}
                />
            )}
        </MainDiv>
    );
};

const mapStateToProps = (store: IStore) => {
    return {
        showSplashScreen: store.splashScreen.show,
        mode: store.common.mapMode,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    setMapMode: (mode: number) => dispatch(setMapMode(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
