import React from 'react';
import { renderUserLocationMarker } from '../../api/ym_listing';
import { calculateFilters, chooseAtmFetch } from '../../helpers/utilities';
import { BaseFont } from '../../shared/constants/theme';
import { clearDetailViewAtm } from '../../store/actions/atms';
import {
    setActiveFilter,
    updateActiveChipFilter,
} from '../../store/actions/filter';
import { IStore } from '../../store/reducers/store';
import { useTranslation } from 'react-i18next';
import Chip from './Chip';
import { atmType, IFilters, IStateFilters } from './types';
import { Container } from './Filters.styled';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

function Filters({
    chipState,
    userLocation,
    setActiveFilter,
    updateActiveChipFilter,
    clearDetailViewAtm,
}: IFilters) {
    const { t } = useTranslation();
    const filtersName: any = t('filters', {
        returnObjects: true,
    });

    React.useEffect(() => {
        clearDetailViewAtm();
        chipState && setActiveFilter?.(calculateFilters(chipState));

        if (userLocation) {
            renderUserLocationMarker(
                userLocation.latitude,
                userLocation.longitude
            );
            chooseAtmFetch(userLocation.latitude, userLocation.longitude);
        }
    }, [chipState, clearDetailViewAtm, setActiveFilter, userLocation]);

    const handleChipClick = (e: React.SyntheticEvent, chip: string) => {
        if (updateActiveChipFilter && chipState) {
            updateActiveChipFilter({
                ...chipState,
                [chip]: !chipState[chip as keyof IStateFilters],
            });
        }
    };

    return (
        <BaseFont as="div" size={100} role="label">
            <Container>
                {Object.keys(atmType).map((key) => {
                    return (
                        <Chip
                            key={key}
                            title={filtersName[key]}
                            atmType={key}
                            atmTypeValue={
                                chipState?.[key as keyof IStateFilters]
                            }
                            handleClick={handleChipClick}
                        />
                    );
                })}
            </Container>
        </BaseFont>
    );
}

const mapStoreToProps = (state: IStore) => {
    return {
        chipState: state.filters.chipState,
        userLocation: state.location.userLocation?.coords,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateActiveChipFilter: (chipState: IStateFilters) =>
        dispatch(updateActiveChipFilter(chipState)),
    setActiveFilter: (searchStr: string) =>
        dispatch(setActiveFilter(searchStr)),
    clearDetailViewAtm: () => dispatch(clearDetailViewAtm()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Filters);
