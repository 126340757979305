import React from 'react';
import cx from 'classnames';
import * as SC from './Spinner.styled';

type SpinnerProps = {
    centered?: boolean;
    overlay?: boolean;
    fixed?: boolean;
    id?: string;
    className?: string;
    visibility?: 'always' | 'only-mobile' | 'not-mobile';
    ['data-test-id']?: string;
};

export default function Spinner({
    centered,
    overlay,
    fixed,
    visibility = 'always',
    ...props
}: SpinnerProps) {
    return (
        <SC.Container
            {...props}
            className={cx(
                'Spinner',
                { overlay },
                { centered },
                { fixed },
                `visibility-${visibility}`,
                props.className
            )}
        >
            <SC.SvgWrapper>
                <SC.Svg data-testid="svg-loader-icon">
                    <SC.Circle
                        cx="24"
                        cy="24"
                        r="18"
                        data-testid="svg-loader-icon-circle"
                    ></SC.Circle>
                </SC.Svg>
            </SC.SvgWrapper>
        </SC.Container>
    );
}
