import styled from 'styled-components';

export const StyledBranding = styled.div`
    height: 30px;
    padding: 0;
    margin: 0;

    display: flex;

    a {
        display: flex;
        align-items: center;
        font-size: 11px;
        font-weight: 700;
        text-decoration: none !important;

        flex: 1;
        height: 100%;
        justify-content: end;
        padding: 0 0.5em;

        &,
        &:hover {
            color: var(--smartmaps-text-color, #707070) !important;
            background-color: white !important;
        }
    }
    svg {
        width: 80px;
        margin-left: 0.5em;
    }
`;
