import React from 'react';
import { AtmElement, MarkerRefs } from '../../../types/models';

import { updateMarkerIcon } from '../MapFunctions';

import { getAtmIdentifier } from '../../../helpers/utilities';

import { usePrevious } from '../../../shared/hooks';
import { MarkerIconVariant } from '../../../helpers/icon-utils';

/**
 * Highlights the map marker for the given ATM using the given variant.
 *
 * @param options
 */
export function useMarkerVariant(options: {
    ymReady: boolean;
    atm?: AtmElement;
    markersById: MarkerRefs;
    variant: MarkerIconVariant;
}) {
    const { atm, ymReady, markersById, variant } = options;

    const previousAtm = usePrevious<AtmElement>(atm);
    const previousVariant = usePrevious<string>(variant);

    React.useEffect(() => {
        if (!ymReady) {
            return;
        }

        const previousId = previousAtm && getAtmIdentifier(previousAtm);
        const currentId = atm && getAtmIdentifier(atm);

        if (previousId === currentId && previousVariant === variant) {
            return;
        }

        if (previousId) {
            updateMarkerIcon({
                marker: markersById.current[previousId],
                atm: previousAtm,
                variant: undefined,
            });
        }

        if (currentId) {
            updateMarkerIcon({
                marker: markersById.current[currentId],
                atm: atm,
                variant,
            });
        }
    }, [markersById, atm, previousAtm, ymReady, variant, previousVariant]);
}
