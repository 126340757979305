import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store.d';
import { useTranslation } from 'react-i18next';
import { BaseBox, BaseFont } from '../../shared/constants/theme';
import * as SC from './Styles';
import { postFeedback } from '../../api/fetchCalls';
import i18next from 'i18next';
import {
    IForm,
    IFormCheckboxOptions,
    IFormRadioOptions,
    IPostRequestPayloadFeedBack,
} from './FeedbackForm.d';
import Button from '../Button';
import styled from 'styled-components';
import {
    ATM_SEARCH_STRING,
    DEPOSIT_ATM_WORKING_CONDITION,
} from '../../shared/constants/enum';
import { atmType } from '../Filters/types';

const SubmitButton = styled(Button)`
    &:not([disabled]) {
        color: white;
    }
`;
const FeedbackForm = (props: IForm) => {
    const { t } = useTranslation();
    const {
        feedbackRes,
        goToSuccessPage,
        atm: { BasicData },
    } = props;

    const [activeRadioItemKey, setActiveRadioItemKey] = useState('');
    const [checkboxesChecked, setCheckboxesChecked] = useState(0);

    const radioButtonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setActiveRadioItemKey(event.target.id);

        setCheckboxesChecked(0);
    };

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setCheckboxesChecked((prev) => prev + 1);
        } else {
            setCheckboxesChecked((prev) => prev - 1);
        }
    };

    const isDisabled = () => {
        if (atmType.ingAtms) {
            if (activeRadioItemKey === 'urn:ing:atm:option:2') {
                return false;
            }
            if (
                activeRadioItemKey === 'urn:ing:atm:option:4' &&
                checkboxesChecked !== 0
            ) {
                return false;
            }
        }
        if (atmType.atms) {
            if (activeRadioItemKey === 'urn:ing:atm:option:10') {
                return false;
            }
            if (activeRadioItemKey === 'urn:ing:atm:option:11') {
                return false;
            }
            if (activeRadioItemKey === 'urn:ing:atm:option:13') {
                return false;
            }
        }
        return true;
    };

    const submitForm = () => {
        const feedback: IPostRequestPayloadFeedBack = {
            id: '',
            additional_options: [],
        };
        const radioElement = document.querySelector(
            'input[name="radioFeedbacksList"]:checked'
        ) as HTMLInputElement;
        const checkBoxElements: NodeListOf<HTMLElement> =
            document.querySelectorAll(
                'input[name="checkFeedbacksList"]:checked'
            );
        let feedIndex = -1;
        if (radioElement) {
            const checkboxes: string[] = [];
            feedIndex = feedbackRes.data.findIndex(
                (obj: IFormRadioOptions) => obj.id === radioElement.value
            );
            if (
                feedbackRes.data[feedIndex].additional_options &&
                feedbackRes.data[feedIndex].additional_options.length &&
                checkBoxElements.length
            ) {
                checkBoxElements.forEach((keyCheck: HTMLElement) => {
                    checkboxes.push(keyCheck.id);
                });
            }
            feedback.id = radioElement.value;
            feedback.additional_options = checkboxes;
        }

        //---------create post object-----------

        goToSuccessPage();
        postFeedback(i18next.language, { BasicData, feedback });
    };

    const checkDepositAtm = (currentAtmAdditionalType: any) => {
        let flag = false;
        for (let key of BasicData.BranchListElements) {
            if (
                key.BranchCode !== DEPOSIT_ATM_WORKING_CONDITION.UNAVAILABLE &&
                key.BranchCode.includes(ATM_SEARCH_STRING.DEPOSIT)
            ) {
                flag = true;
                break;
            }
        }

        return (
            currentAtmAdditionalType !== 'Einzahlfunktion' ||
            (currentAtmAdditionalType === 'Einzahlfunktion' && flag)
        );
    };
    return (
        <>
            <SC.OptionsContainer data-testid="radio-button-container">
                {feedbackRes.data.map((key: IFormRadioOptions) => {
                    return (
                        <BaseFont key={key.id} as="div" size={300}>
                            <BaseBox bottom={3}>
                                <SC.RadioContainer>
                                    <label className="containerRadio">
                                        <p>{key.value}</p>
                                        <input
                                            type="radio"
                                            id={key.id}
                                            name="radioFeedbacksList"
                                            value={key.id}
                                            onChange={radioButtonHandler}
                                            data-testid={`radio-item-${key.id}`}
                                        />
                                        <span className="check" />
                                    </label>
                                </SC.RadioContainer>
                                {key.additional_options &&
                                key.additional_options.length &&
                                activeRadioItemKey === key.id ? (
                                    <>
                                        {key.sub_headline && (
                                            <SC.SubTitle>
                                                <BaseBox bottom={3} top={3}>
                                                    <SC.FilterTitle>
                                                        {key.sub_headline}
                                                    </SC.FilterTitle>
                                                </BaseBox>
                                            </SC.SubTitle>
                                        )}
                                        {key.additional_options.map(
                                            (key: IFormCheckboxOptions) => {
                                                return (
                                                    checkDepositAtm(
                                                        key.value
                                                    ) && (
                                                        <SC.CheckboxContainer
                                                            key={key.id}
                                                            data-testid="checkbox-container"
                                                        >
                                                            <label className="containerCheckbox">
                                                                <p>
                                                                    {key.value}
                                                                </p>
                                                                <input
                                                                    onChange={
                                                                        handleCheck
                                                                    }
                                                                    type="checkbox"
                                                                    id={key.id}
                                                                    name="checkFeedbacksList"
                                                                    value={
                                                                        key.id
                                                                    }
                                                                />
                                                                <span className="checkmark" />
                                                            </label>
                                                        </SC.CheckboxContainer>
                                                    )
                                                );
                                            }
                                        )}
                                    </>
                                ) : null}
                            </BaseBox>
                        </BaseFont>
                    );
                })}
            </SC.OptionsContainer>
            <BaseBox top={3} bottom={3}>
                <SubmitButton
                    onClick={submitForm}
                    disabled={isDisabled()}
                    data-testid="feedback-form-submit"
                >
                    {t('submitFeedback')}
                </SubmitButton>
            </BaseBox>
        </>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        atm: state.detailedAtm,
    };
};

export default connect(mapStateToProps, null)(FeedbackForm);
