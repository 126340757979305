import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IStore } from '../../store/reducers/store';
import { connect } from 'react-redux';
import { AtmElement, MapMode } from '../../types/models';
import SearchResultElement from './SearchResultElement';
import ErrorScreen from './ErrorScreen';
import { getAtmIdentifier } from '../../helpers/utilities';
import Button from '../Button';
import { getIconUrlFromAtm } from '../../helpers/icon-utils';
import Spinner from '../Spinner/Spinner';

export const SearchResultListElem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    animation: ${(props) =>
        props.theme.enableSearchBarFade ? 'scrollbar 2s' : 'none'};
    width: 100%;
    background: white;
    & > a {
        display: block;
        margin-top: 0.4em;
        margin-bottom: 0.4em;
    }
    @media only screen and (max-width: ${(props) =>
            props.theme.smartphoneUpperBound}) {
        display: block;
        margin: auto;
        border: none;
    }

    @keyframes scrollbar {
        from {
            overflow-y: hidden;
        }
        to {
            overflow-y: scroll;
        }
    }
`;

const ResultListInner = styled.div`
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    margin: 0 -22px;
    @media only screen and (max-width: ${(props) =>
            props.theme.smartphoneUpperBound}) {
        margin: 0 -18px;
    }
`;

const ButtonFooter = styled.footer`
    margin-top: 1em;
    text-align: center;
    margin-bottom: 20px;
`;

type SearchResultListProps = {
    currentAtms?: AtmElement[];
    currentAddress?: string;
    mapMode?: MapMode;
    showLoadMore?: boolean;
    isLoading?: boolean;
    isFetchingAtm?: boolean;
    onMoreClick?: () => void;
};

const SearchResultList = ({
    currentAtms,
    currentAddress,
    showLoadMore,
    isFetchingAtm,
    mapMode,
    onMoreClick,
}: SearchResultListProps) => {
    const { t } = useTranslation();

    if (!currentAtms && mapMode === MapMode.Maps && !isFetchingAtm) {
        return (
            <ResultListInner>
                <Spinner centered />
            </ResultListInner>
        );
    }
    if (currentAtms && !currentAtms.length) {
        return (
            <ResultListInner>
                <ErrorScreen currentAddress={currentAddress} />
            </ResultListInner>
        );
    }

    if (!currentAtms) {
        return null;
    }

    return (
        <ResultListInner>
            {currentAtms.map((atm: AtmElement, index) => (
                <SearchResultElement
                    key={getAtmIdentifier(atm)}
                    name={atm.BasicData.Address.CompanyName}
                    companyNameAddon1={atm.BasicData.Address.CompanyNameAddon1}
                    city={atm.BasicData.Address.City}
                    zip={atm.BasicData.Address.Zip}
                    street={atm.BasicData.Address.Street}
                    distance={atm.BasicData.Geo.Distance}
                    branchListElements={atm.BasicData.BranchListElements}
                    memoItems={atm.BasicData.MemoItems}
                    identifiers={atm.BasicData.Identifiers}
                    geo={atm.BasicData.Geo}
                    iconUrl={getIconUrlFromAtm(atm)}
                    isRecommendation={atm.isRecommended}
                />
            ))}
            {showLoadMore && (
                <ButtonFooter>
                    <Button onClick={onMoreClick}>{t('loadMoreAtms')}</Button>
                </ButtonFooter>
            )}
        </ResultListInner>
    );
};

const mapStateToProps = (store: IStore) => {
    // const numResults = store.atms.currentAtms?.length || 0;
    // const currentAtms = Object.values(store.atms.currentAtms || {});
    return {
        // currentAtms,
        isFetchingAtm: store.atms.isFetchingAtm,
        currentAddress: (store.atms.lastAccessedFetchingStrategy?.value as any)
            ?.city,
        mapMode: store.common.mapMode,
        //showLoadMore: store.atms.Paging?.MaxCount > numResults,
        // showLoadMore: true, // store.atms?.Paging?.MaxCount > currentAtms.size,
    };
};

export default connect(mapStateToProps, null)(SearchResultList);
