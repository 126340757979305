import { MobileView, PanelContent } from '../../shared/constants/enum';
import {
    SET_MAP_MODE,
    SET_MOBILE_VIEW,
    SET_MOBILE_RETURN,
    SET_PANEL_CONTENT,
} from './actionTypes';

export const setMapMode = (data: number) => ({
    type: SET_MAP_MODE,
    payload: data,
});

export const setMobileView = (type: MobileView) => ({
    type: SET_MOBILE_VIEW,
    payload: type,
});

export const setMobileReturn = (type: MobileView) => ({
    type: SET_MOBILE_RETURN,
    payload: type,
});

export const setPanelContent = (type: PanelContent) => ({
    type: SET_PANEL_CONTENT,
    payload: type,
});
