import { AutocompleteAddress } from '../types/models';
import { YELLOW_MAP_AUTOSUGGEST_MAX_RESULTS } from '../shared/constants/configs';
import { YELLOW_MAP_API_ROOT } from '../shared/constants/default';

// TODO: possibly we need token invalidation - we will receive info from YM about token lifespan
// see https://jira.etecture.de/browse/INGGA-191
let AuthToken = '';
let abortController: AbortController;
let abortSignal: AbortSignal;

const fetchToken = async () => {
    const SecurityID = window.encodeURIComponent(
        process.env.REACT_APP_SECURITY_ID!
    );
    const SystemPartner = window.encodeURIComponent(
        process.env.REACT_APP_PARTNER!
    );

    const url = `${YELLOW_MAP_API_ROOT}/v2/yellowmapsapi/authtoken/autocomplete?SystemPartner=${SystemPartner}&SecurityID=${SecurityID}&Channel=`;
    const response = await fetch(url, { signal: abortSignal });
    const token = await response.json();

    return window.encodeURIComponent(token);
};
export const fetchSuggestions = async (
    query: string,
    maxCount = YELLOW_MAP_AUTOSUGGEST_MAX_RESULTS
): Promise<AutocompleteAddress[]> => {
    // abort pending requests
    abortController?.abort();

    // always prepare new abort controller+signal
    abortController = new AbortController();
    abortSignal = abortController.signal;

    // ensure we do have a token
    AuthToken = AuthToken || (await fetchToken());

    if (!AuthToken || !query) {
        return [];
    }

    try {
        query = window.encodeURIComponent(query);

        const url = `//autocomplete.yellowmap.de/Autocomplete/Search?Locales=de&Query=${query}&Count=${maxCount}&AuthToken=${AuthToken}&Type=json`;
        const response = await fetch(url, { signal: abortSignal });
        const { Addresses } = await response.json();

        return Addresses;
    } catch (error) {
        if ((error as Error).name !== 'AbortError') {
            console.warn('[fetchSuggestions] failed', { error });
        }
    }

    return [];
};
