import {
    createYellowmapIcon,
    MarkerIconVariant,
} from '../../helpers/icon-utils';
import {
    ING_FRANKFURT_LATITUDE,
    ING_FRANKFURT_LONGITUDE,
} from '../../shared/constants/default';
import {
    AtmElement,
    LatLngRect,
    Map,
    MapMarker,
    MarkerClusterGroup,
} from '../../types/models';

import { atmUtils } from '../../helpers/atm-utils';
import { getGlobalMap } from './mapInstance';
import {
    MARKER_ZINDEX_DEFAULT,
    MARKER_ZINDEX_ING,
    MARKER_ZINDEX_SELECTED,
} from './MapVariables';

export const moveToLocation = (lat: number, long: number) => {
    const center = window.ym.latLng(lat, long);
    getGlobalMap()?.panTo(center);
};

export const animateToLocation = (
    lat: number,
    long: number,
    zoom = getGlobalMap()?.getZoom()
) => {
    const center = window.ym.latLng(lat, long);
    getGlobalMap()?.setView(center, zoom, { animate: true });
};

export const latitude = (coords: any) =>
    coords != null ? coords.latitude : ING_FRANKFURT_LATITUDE;

export const longitude = (coords: any) =>
    coords != null ? coords.longitude : ING_FRANKFURT_LONGITUDE;

export const renderMarker = (
    lat: number,
    long: number,
    target: Map | MarkerClusterGroup | null,
    //
    modules: any,
    icon: any,
    clickCallback?: any,
    options?: any
) => {
    if (!target) {
        return null;
    }

    const pos = window.ym.latLng(lat, long);
    const m = new modules.provider.Marker(pos, options) as MapMarker;
    m.setIcon(icon);
    m.on('click', function (e: any) {
        if (clickCallback) clickCallback(e.target.options.addressItemId);
    });
    m.addTo(target);
    return m;
};

export const calculateZoomLevel = (atms: any[]) => {
    if (atms && atms.length) {
        const maxDistance = Number(
            atms[atms.length - 1].BasicData.Geo.Distance
        );
        if (maxDistance === 0) return -1;
        if (maxDistance <= 300) return 16;
        if (maxDistance > 300 && maxDistance <= 600) return 15;
        if (maxDistance > 600 && maxDistance <= 1200) return 14;
    }
    return 13;
};

export const getBounds = (map = getGlobalMap()) => {
    if (!map) {
        return null;
    }
    const bounds = map.getBounds();
    const northWest = bounds.getNorthWest();
    const southEast = bounds.getSouthEast();
    return {
        northWest: {
            lat: northWest.lat as number,
            lng: northWest.lng as number,
        },
        southEast: {
            lat: southEast.lat as number,
            lng: southEast.lng as number,
        },
    } as LatLngRect;
};

export function createMarker(options: {
    atm: AtmElement;
    onClick: (addressItemId: string) => void;
    target: any;
}) {
    const { atm, onClick } = options;

    const icon = createYellowmapIcon({ atm });
    const marker = renderMarker(
        parseFloat(atm.BasicData.Geo.YCoord),
        parseFloat(atm.BasicData.Geo.XCoord),
        options.target,
        window.ym.modules,
        icon,
        onClick,
        {
            addressItemId: atm.BasicData.Identifiers.YMIDDecoded,
            title:
                atm.BasicData.Address.CompanyName +
                ', ' +
                atm.BasicData.Address.Street,
        }
    );

    marker?.setZIndexOffset(getMarkerZIndexOffset(atm));

    return marker;
}

export function getMarkerZIndexOffset(
    atm: AtmElement,
    selected: boolean = false
) {
    return selected
        ? MARKER_ZINDEX_SELECTED
        : atmUtils.isIng(atm)
        ? MARKER_ZINDEX_ING
        : MARKER_ZINDEX_DEFAULT;
}

export function updateMarkerIcon({
    marker,
    atm,
    variant,
}: {
    marker?: MapMarker | null;
    atm?: AtmElement;
    variant?: MarkerIconVariant;
}) {
    if (!marker || !atm) {
        return;
    }

    marker.setZIndexOffset(getMarkerZIndexOffset(atm, Boolean(variant)));
    marker.setIcon(createYellowmapIcon({ atm, variant }));
}
