import {
    SET_ACTIVE_FILTER,
    UPDATE_ACTIVE_CHIP_FILTER,
} from '../actions/actionTypes/index';
import { FILTER_CHIP_FUNCTIONALITY } from '../../shared/constants/enum';
import { IStateFilters } from '../../components/Filters/types';
interface FiltersState {
    chipState?: IStateFilters;
    activeFilter: string;
}

export const initialStateFilters: FiltersState = {
    chipState: {
        atms: false,
        ingAtms: false,
        depositAtms: false,
        ingCashWithCode: false,
        cashBackPurchase: false,
    },
    activeFilter: FILTER_CHIP_FUNCTIONALITY.NO_FILTER,
};
export default function filters(
    state: FiltersState = initialStateFilters,
    action: any
) {
    switch (action.type) {
        case SET_ACTIVE_FILTER: {
            const newState = { ...state };
            newState.activeFilter = action.payload;
            return newState;
        }
        case UPDATE_ACTIVE_CHIP_FILTER: {
            const newState = { ...state };
            newState.chipState = action.payload;
            return newState;
        }

        default:
            return state;
    }
}
