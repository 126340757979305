import { getUserLocationIcon } from '../helpers/icon-utils';
import { renderMarker } from '../pages/Atm/MapFunctions';
import { getGlobalMap } from '../pages/Atm/mapInstance';
import { LatLng } from '../types/models';

export const reverseGeocode = (lat: number, long: number) =>
    new Promise((resolve, reject) => {
        const geoCoder = window.ym.services.geoCoder;
        function unbind() {
            geoCoder.off('success', handleSuccess);
            geoCoder.off('error', handleError);
        }
        function handleSuccess(req: any, res: any) {
            unbind();
            resolve(res.body.features[0]?.properties);
        }
        function handleError(req: any, res: any, errorType: any) {
            console.error('reverse geocode failed', { req, res, errorType });
            unbind();
            reject(new Error(errorType));
        }

        geoCoder.on('success', handleSuccess);
        geoCoder.on('error', handleError);
        geoCoder.reverseGeocode({ lat: lat, lng: long });
    });

export const geocode = (
    address: string,
    callback: (lat: number, long: number) => void
): Promise<LatLng> => {
    return new Promise((resolve, reject) => {
        window.ym.ready(function (modules: any) {
            const geoCoder = window.ym.services.geoCoder;
            function unbind() {
                geoCoder.off('success', handleSuccess);
                geoCoder.off('error', handleError);
            }
            function handleSuccess(req: any, res: any) {
                if (res.body.bbox) {
                    const lat = res.body.bbox[1];
                    const lng = res.body.bbox[0];
                    resolve({ lat, lng });
                }
            }
            function handleError(req: any, res: any, errorType: any) {
                console.error('geocode failed', { req, res, errorType });
                unbind();
                reject(new Error(errorType));
            }

            geoCoder.on('success', handleSuccess);
            geoCoder.on('error', handleError);
            modules.geocodeString(address);
        });
    });
};

export const renderUserLocationMarker = (lat: number, long: number) => {
    if (window.ym && window.ym.ready) {
        window.ym.ready(function (modules: any) {
            renderMarker(
                lat,
                long,
                getGlobalMap(),
                modules,
                getUserLocationIcon()
            );
        });
    }
};
