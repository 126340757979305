import { AppSettings, AppMode } from './types/models';

const defaults: AppSettings = {
    isEmbedded: false,
    elementId: 'root',
    appMode: AppMode.Web,
};

export const appSettings = {
    ...defaults,
    ...(window.atmSearch || {}),
};
