import React from 'react';
import { AtmElement, AtmHashMap } from '../../../types/models';

import { LIST_PAGE_SIZE } from '../../../shared/constants/configs';

import { usePrevious } from '../../../shared/hooks';

/**
 * Provides pagination for the ATM search results.
 *
 * Takes the full resultlist `atms` and returns a subset `currentAtms`.
 * Also provides a function `showMoreAtms` to expand the subset and a flag `hasMoreAtms`.
 *
 * The remote pagination API from yellowmaps was buggy or otherwise unusable for us.
 * So now, we instead load a large result set initially and paginate through it manually.
 *
 * @param param0
 * @returns
 */
export function useSlicedAtms({
    atms,
    pageSize = LIST_PAGE_SIZE,
}: {
    atms?: AtmHashMap;
    pageSize?: number;
}) {
    const prevAtms = usePrevious(atms);
    const [currentAtms, setCurrentAtms] = React.useState<
        AtmElement[] | undefined
    >();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [hasMoreAtms, setHasMoreAtms] = React.useState(false);
    const [totalCount, setTotalCount] = React.useState(0);

    const showMoreAtms = React.useCallback(() => {
        setCurrentPage((current) => current + 1);
    }, []);

    React.useEffect(() => {
        if (!atms) {
            setCurrentAtms(undefined);
            setHasMoreAtms(false);
            return;
        }
        const currentArray = Object.values(atms);
        const nextArray = currentArray.slice(0, currentPage * pageSize);
        setHasMoreAtms(nextArray.length < currentArray.length);
        setCurrentAtms(nextArray);
    }, [atms, currentPage, pageSize]);

    React.useEffect(() => {
        if (atms !== prevAtms) {
            setCurrentPage(1);
        }
    }, [atms, prevAtms]);

    React.useEffect(() => {
        setTotalCount(atms ? Object.keys(atms).length : 0);
    }, [atms]);

    return {
        currentAtms,
        hasMoreAtms,
        showMoreAtms,
        totalCount,
    };
}
