import styled from 'styled-components';
import {
    ingBlack15,
    ingBlack80,
    ingOrange,
    tabletSLowerBound,
} from '../../shared/constants/theme';

export const AskFeedbackContainer = styled.div`
    color: ${ingBlack80};
`;

export const FeedbackContainer = styled.div`
    color: ${ingBlack80};
    background: white;
    overflow: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    @media screen and (min-width: ${tabletSLowerBound}) {
        padding: 22px;
        box-sizing: border-box;
    }
    @media screen and (max-width: ${tabletSLowerBound}) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
    }
`;

export const OptionsContainer = styled.div`
    color: ${ingBlack80};
    display: flex;
    flex-direction: column;

    .feedbackQuestion {
        white-space: pre-wrap;
    }
`;

export const SubTitle = styled.div`
    display: flex;
`;

export const SuccessContainer = styled.div`
    @media screen and (max-width: ${tabletSLowerBound}) {
        padding: 18px;
    }
`;

export const RadioContainer = styled.div`
    .containerRadio {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: inherit !important;
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        p {
            margin: 0;
        }
    }

    /* Hide the browser's default radio button */
    .containerRadio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .check {
        position: absolute;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border-radius: 50%;
        border: 1px solid ${ingBlack15};
        @media screen and (max-width: ${tabletSLowerBound}) {
            top: 2px;
        }
        top: 4px;
    }

    /* On mouse-over, add a grey background color */
    .notouch & {
        .containerRadio:hover input ~ .check {
            background-color: #9e9e9e21;
        }
    }

    /* When the radio button is checked, add a blue background */
    .containerRadio input:checked ~ .check {
        background-color: white;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .check:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .containerRadio input:checked ~ .check:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .containerRadio .check:after {
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: ${ingOrange};
    }
`;

export const CheckboxContainer = styled.div`
    .containerCheckbox {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: inherit !important;
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        p {
            margin: 0;
        }
    }

    /* Hide the browser's default checkbox */
    .containerCheckbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border: 1px solid ${ingBlack15};
        border-radius: 3px;
        @media screen and (max-width: ${(props) =>
                props.theme.tabletSLowerBound}) {
            top: 2px;
        }
        top: 4px;
    }

    /* On mouse-over, add a grey background color */
    .notouch & {
        .containerCheckbox:hover input ~ .checkmark {
            background-color: #9e9e9e21;
        }
    }

    /* When the checkbox is checked, add a blue background */
    .containerCheckbox input:checked ~ .checkmark {
        background-color: white;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .containerCheckbox input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .containerCheckbox .checkmark:after {
        left: 6px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid ${ingOrange};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export const FilterTitle = styled.div`
    color: ${(props) => props.theme.ingBlack80};
    font-weight: 700;
`;

export const FilterContent = styled.div`
    padding: 18px;
    @media screen and (min-width: ${tabletSLowerBound}) {
        top: 0;
        padding: 0;
    }
`;
