import styled from 'styled-components';
import Button from '.';
import {
    desktopLowerBound,
    smartphoneUpperBound,
    tabletLUpperBound,
    tabletSLowerBound,
} from '../../shared/constants/theme';

export const SquareIconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0;
    margin: 0;
    padding: 0;
    img {
        max-height: none;
        max-width: none;
    }
    @media screen and (max-width: ${smartphoneUpperBound}) {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        img {
            height: 16px;
            width: 16px;
        }
    }
    @media screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletLUpperBound}) {
        min-width: 47px;
        max-width: 47px;
        min-height: 47px;
        max-height: 47px;
        img {
            height: 18px;
            width: 18px;
        }
    }
    @media screen and (min-width: ${desktopLowerBound}) {
        min-width: 56px;
        max-width: 56px;
        min-height: 56px;
        max-height: 56px;
        img {
            height: 22px;
            width: 22px;
        }
    }
`;
