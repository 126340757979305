import React from 'react';
import styled from 'styled-components';
import { createYellowmapIcon } from '../../helpers/icon-utils';
import { YELLOW_MAP_INITIAL_ZOOM } from '../../shared/constants/configs';
import {
    ING_FRANKFURT_LATITUDE,
    ING_FRANKFURT_LONGITUDE,
} from '../../shared/constants/default';
import { ingBlack15, smartphoneUpperBound } from '../../shared/constants/theme';
import { AtmElement } from '../../types/models';

let mapInstance: any;
let mapElement: HTMLDivElement = document.createElement('div');

export const PreviewMapContainer = styled.div`
    height: 320px;

    border: 1px solid ${ingBlack15};
    border-radius: 4px;
    overflow: hidden;

    .ym-map-container {
        width: 100%;
        height: 100%;
    }

    .gmnoprint:not([controlwidth]),
    .ym-control-brand,
    a {
        display: none !important;
    }

    @media only screen and (max-width: ${smartphoneUpperBound}) {
        height: calc(100vw * 0.4);
        @media (orientation: portrait) {
            height: calc(100vw * 0.6);
        }
        @media (orientation: landscape) {
            pointer-events: none;
            .gmnoprint[controlwidth] {
                display: none !important;
            }
        }
    }
`;

export const PreviewMap = ({ atm }: { atm: AtmElement }) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [map, setMap] = React.useState<any>(null);

    const { XCoord, YCoord } = atm?.BasicData?.Geo || {};

    React.useEffect(function reuseDomElement() {
        const element = containerRef.current as HTMLDivElement;
        element?.appendChild(mapElement);
        return () => {
            element?.removeChild(mapElement);
        };
    }, []);

    React.useEffect(function reuseMapInstance() {
        if (!mapInstance) {
            mapInstance = window.ym.map(mapElement, {
                center: window.ym.latLng(
                    ING_FRANKFURT_LATITUDE,
                    ING_FRANKFURT_LONGITUDE
                ),
                zoom: YELLOW_MAP_INITIAL_ZOOM,
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                disableDefaultUI: false,
            });
        }
        setMap(mapInstance);
    }, []);

    React.useEffect(
        function applyAtmData() {
            if (!map || !XCoord || !YCoord) {
                return;
            }

            const pos = window.ym.latLng(
                parseFloat(YCoord),
                parseFloat(XCoord)
            );

            const marker = new window.ym.modules.provider.Marker(pos);
            marker.setIcon(createYellowmapIcon({ atm }));
            marker.addTo(map);

            map.whenReady(() => map.panTo(pos));

            return () => {
                marker.removeFrom(map);
            };
        },
        [map, XCoord, YCoord, atm]
    );

    return <PreviewMapContainer ref={containerRef} className="PreviewMap" />;
};
