import React from 'react';
import cx from 'classnames';

import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Img = styled.img<{ duration: string }>`
    &.ingga--fade {
        &:not(.ingga--loaded) {
            opacity: 0;
        }
        &.ingga--loaded {
            animation: ${(props) => props.duration} ${fade} ease-out;
        }
    }
`;

type OnLoadHandler = React.HTMLProps<HTMLImageElement>['onLoad'];

export const Image = ({
    onLoad,
    fade,
    duration = '1s',
    className,
    ...props
}: {
    fade?: boolean;
    duration?: string;
    src: string;
    alt?: string;
    className?: string;
    onLoad?: OnLoadHandler;
}) => {
    const handleLoad = React.useCallback(
        (event) => {
            event.target.classList.add('ingga--loaded');
            onLoad?.(event as any);
        },
        [onLoad]
    );
    return (
        <Img
            duration={duration}
            className={cx(className, { 'ingga--fade': fade })}
            onLoad={handleLoad}
            {...(props as any)}
        />
    );
};
