import React from 'react';
import { AtmElement, AtmHashMap } from '../../../types/models';

import { moveToLocation } from '../MapFunctions';

import { useDispatch } from 'react-redux';
import { setDetailViewAtm } from '../../../store/actions/atms';
import {
    setMobileReturn,
    setMobileView,
    setPanelContent,
} from '../../../store/actions/common';

import { MobileView, PanelContent } from '../../../shared/constants/enum';

/**
 * Provides a handler function for marker clicks.
 * The function will handle the click at certain coordinates,
 * and dispatch a bunch of redux actions to apply our display and business logic.
 *
 * @param param0
 * @returns
 */
export function useMarkerClick({ atms }: { atms?: AtmHashMap }) {
    const dispatch = useDispatch();

    const onMarkerClick = React.useCallback(
        function handleMarkerClick(addressItemId: string) {
            const atmsArray = Object.values(atms || {});
            if (!atmsArray.length) {
                return;
            }
            const atm = atmsArray.find((atm: AtmElement) => {
                return atm.BasicData.Identifiers.YMIDDecoded === addressItemId;
            });
            if (atm) {
                dispatch(setMobileView(MobileView.CONTENT));
                dispatch(setMobileReturn(MobileView.MAP));
                dispatch(setDetailViewAtm(atm));
                dispatch(setPanelContent(PanelContent.DETAILS));
                moveToLocation(
                    parseFloat(atm.BasicData.Geo.YCoord),
                    parseFloat(atm.BasicData.Geo.XCoord)
                );
            }
        },
        [dispatch, atms]
    );

    return { onMarkerClick };
}
