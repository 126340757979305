import pin from './../../shared/img/pin.svg';

export const MARKER_ZINDEX_DEFAULT = 10000;
export const MARKER_ZINDEX_ING = 10001;
export const MARKER_ZINDEX_SELECTED = 10002;
export const MARKER_ZINDEX_USER = 10003;

export const icon = {
    iconUrl: pin,
    iconRetinaUrl: pin,
    iconSize: [40, 49],
    iconAnchor: [15, 49],
    popupAnchor: [0, -48],
};
