import React from 'react';

export default function CrossHair() {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="#ff6200">
            <path
                style={{ fillRule: 'evenodd' }}
                d="M12,10a2,2,0,1,1-2,2A2,2,0,0,1,12,10Zm1-3.91V8H11V6.09A6,6,0,0,0,6.09,11H8v2H6.09A6,6,0,0,0,11,17.91V16h2v1.91A6,6,0,0,0,17.91,13H16V11h1.91A6,6,0,0,0,13,6.09ZM13,2V4.07A8,8,0,0,1,19.93,11H22v2H19.93A8,8,0,0,1,13,19.93V22H11V19.93A8,8,0,0,1,4.07,13H2V11H4.07A8,8,0,0,1,11,4.07V2Z"
            />
        </svg>
    );
}
