import {
    SET_MAP_MODE,
    SET_MOBILE_VIEW,
    SET_PANEL_CONTENT,
    SET_MOBILE_RETURN,
} from '../actions/actionTypes';
import { MobileView, PanelContent } from '../../shared/constants/enum';
import { AppMode, MapMode } from '../../types/models';
import { appSettings } from '../../settings';

interface ICommon {
    mapMode: number;
    mobileViewType: MobileView;
    mobileReturnType?: MobileView;
    panelContent: PanelContent;
}

const initialMapMode =
    appSettings.appMode === AppMode.Web
        ? /* start with disclaimer view when in web */
          MapMode.Disclaimer
        : /* but directly with Maps when in mobile app */
          MapMode.Maps;

const initialState = {
    /** The kind of main view to display (Disclaimer | Maps | NoMaps) */
    mapMode: initialMapMode,
    /** The kind of content to display in the sidebar panel */
    panelContent: PanelContent.SEARCH,
    /** The kind of view to display on mobile. Irrelevant on tablet or desktop */
    mobileViewType: MobileView.MAP,
    /** The kind of view to show when returning from result details on mobile. Irrelevant on tablet or desktop  */
    mobileReturnType: undefined,
};

export default function atms(state: ICommon = initialState, action: any) {
    switch (action.type) {
        case SET_MAP_MODE: {
            const newState = { ...state };
            newState.mapMode = action.payload;
            return newState;
        }

        case SET_MOBILE_VIEW: {
            const newState = { ...state };
            newState.mobileViewType = action.payload;
            return newState;
        }

        case SET_MOBILE_RETURN: {
            const newState = { ...state };
            newState.mobileReturnType = action.payload;
            return newState;
        }

        case SET_PANEL_CONTENT: {
            const newState = { ...state };
            newState.panelContent = action.payload;
            return newState;
        }

        default:
            return state;
    }
}
