import './globals';

import React from 'react';
import ReactDOM from 'react-dom';
import { appSettings } from './settings';
import App from './app/App';

const element = document.getElementById(appSettings.elementId);
ReactDOM.render(<App />, element);

if (element) {
    // need z-index on root element in embed situation
    element.style.position = 'relative';
    element.style.zIndex = '100';
}
