import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store';
import { useTranslation } from 'react-i18next';
import { BaseBox, BaseFont } from '../../shared/constants/theme';
import * as SC from './Styles';
import Form from './Form';
import { IFormLayout } from './FeedbackForm.d';
import { Dispatch } from 'redux';

import Spinner from '../Spinner/Spinner';
import { setMobileView, setPanelContent } from '../../store/actions/common';
import { MobileView, PanelContent } from '../../shared/constants/enum';
import { PanelHeader } from '../PanelHeader';
import Button from '../Button';
import { useMobileDetection } from '../../shared/hooks';

const FormLayout = (props: IFormLayout) => {
    const { showAtmDetails, showMap, showSearch, feedback } = props;

    const { isMobile } = useMobileDetection();
    const { t } = useTranslation();
    const [isSuccessPage, setIsSuccessPage] = React.useState(false);
    const goToSuccessPage = () => setIsSuccessPage(true);

    return (
        <SC.FeedbackContainer data-testid="feedback-form">
            <PanelHeader
                onBackClick={showAtmDetails}
                onCloseClick={showMap}
                closeButtonVisible={isMobile}
            />
            {feedback && feedback.data ? (
                isSuccessPage ? (
                    <SC.SuccessContainer>
                        {/* TODO deduplicate headlines */}
                        <BaseBox as="div" top={3} bottom={3}>
                            <BaseFont as="span" size={400}>
                                <SC.FilterTitle>
                                    {feedback.messages.success.headline}
                                </SC.FilterTitle>
                            </BaseFont>
                        </BaseBox>
                        <BaseFont as="span" size={300}>
                            {feedback.messages.success.body}
                        </BaseFont>
                        <BaseBox top={4} bottom={3}>
                            <Button onClick={showSearch}>
                                {t('backToSearch')}
                            </Button>
                        </BaseBox>
                    </SC.SuccessContainer>
                ) : (
                    <SC.FilterContent>
                        <BaseBox as="div" top={3} bottom={3}>
                            {/* TODO deduplicate headlines */}
                            <BaseFont as="span" size={400}>
                                <SC.FilterTitle data-testid="feedback-headline">
                                    {feedback.messages.intro.headline}
                                </SC.FilterTitle>
                            </BaseFont>
                        </BaseBox>
                        <BaseBox as="div" bottom={6}>
                            <BaseFont
                                as="span"
                                size={300}
                                className="feedbackQuestion"
                                data-testid="feedback-sub-headline"
                                dangerouslySetInnerHTML={{
                                    __html: feedback.messages.intro.body,
                                }}
                            />
                        </BaseBox>
                        <BaseBox as="div" bottom={3}>
                            <BaseFont as="span" size={300}>
                                <SC.FilterTitle>
                                    {
                                        feedback.messages.feedback_headline
                                            .headline
                                    }
                                </SC.FilterTitle>
                            </BaseFont>
                        </BaseBox>
                        <Form
                            feedbackRes={feedback}
                            goToSuccessPage={goToSuccessPage}
                        />
                    </SC.FilterContent>
                )
            ) : (
                <Spinner centered />
            )}
        </SC.FeedbackContainer>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        feedback: state.feedback.feedbackResponse,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showAtmDetails: () => {
        dispatch(setPanelContent(PanelContent.DETAILS));
    },
    showSearch: () => {
        dispatch(setPanelContent(PanelContent.SEARCH));
    },
    showMap: () => {
        dispatch(setPanelContent(PanelContent.SEARCH));
        dispatch(setMobileView(MobileView.MAP));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormLayout);
