import styled, { keyframes } from 'styled-components';

const LoadingIndicatorRotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingIndicatorDash = keyframes`
    0% {
        stroke-dasharray: 6, 107;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 87, 26;
        stroke-dashoffset: -13;
    }
    100% {
        stroke-dasharray: 6, 107;
        stroke-dashoffset: -113;
    }
`;

export const Container = styled.div`
    &.overlay {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.5);
            cursor: default;
            z-index: 99999999;
        }
        &.fixed:before {
            position: fixed;
        }
    }
    &.visibility-only-mobile {
        display: none;
        @media only screen and (max-width: ${(props) =>
                props.theme.smartphoneUpperBound}) {
            display: block;
        }
    }
    &.visibility-not-mobile {
        display: block;
        @media only screen and (max-width: ${(props) =>
                props.theme.smartphoneUpperBound}) {
            display: none;
        }
    }
`;

export const SvgWrapper = styled.div`
    .centered & {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999999;
    }
    .fixed & {
        position: fixed;
    }
`;

export const Svg = styled.svg`
    display: block;
    animation: 2s ${LoadingIndicatorRotate}linear infinite;
    height: 48px;
    width: 48px;
    margin: auto auto;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        animation-duration: 1500ms;
    }
`;

export const Circle = styled.circle`
    animation: 1.5s ${LoadingIndicatorDash} ease-in-out infinite;
    stroke-dasharray: 88, 25; /* This is a fallback for IE11 */
    stroke-width: 4;
    fill: none;
    stroke: #ff6200;
`;
