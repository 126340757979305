import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BaseBox, BaseFont } from '../../shared/constants/theme';
import { useIsDefectiveAtm } from '../../shared/hooks/useIsDefectiveAtm';
import { AtmElement, BranchElement } from '../../types/models';

const Container = styled(BaseBox)`
    color: ${(props) => props.theme.ingRed};
`;

export const DepositErrorMessage = React.memo(function DepositErrorMessage({
    atm,
    size = 100,
}: {
    atm: AtmElement | BranchElement[];
    size?: number;
}) {
    const { t } = useTranslation();
    const isDefective = useIsDefectiveAtm(atm);

    let errorMessage = '';
    if (isDefective.withdrawal) {
        errorMessage = t('defectiveWithdrawal');
    } else if (isDefective.deposit) {
        errorMessage = t('defectiveDeposit');
    }

    if (errorMessage) {
        return (
            <Container as="div" top={1}>
                <BaseFont size={size}>{errorMessage}</BaseFont>
            </Container>
        );
    }

    return null;
});
